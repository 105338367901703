<template>
  <div class='page-start'>
    <mdb-container class="pb-3" fluid>

      <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
          Success! Submitted QPR!
      </mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
          There was an error submitting QPR. Please try again! Error Code P2
      </mdb-alert>

      <mdb-card class="mb-3 d-flex">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">QPR Submission Form</h3>
        <mdb-card-body>
          <form class="was-validated">
            <mdb-row>
              <mdb-col>
                <mdb-input class="mb-3" v-model="title" label="Title" placeholder="Brief Title for Problem or Enhancement" invalid-feedback="Please Input QPR Title" valid-feedback="Looks Good!" maxlength="120" required/>
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-row>
              <mdb-col>
                <mdb-input class="mb-3" v-model="author" label="Author" placeholder="Your Name" maxlength="60" invalid-feedback="Please Input QPR Author" valid-feedback="Looks Good!" required/>
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-row>
              <mdb-col>
                <mdb-input class="mb-3" v-model="foundIn" label="Found In" placeholder="Product or Prototype referred to" invalid-feedback="Please Input Product Name" valid-feedback="Looks Good!" maxlength="60" required />
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-row>
              <mdb-col>
                <mdb-input class="mb-3" v-model="softwareVersion" label="Software Version" placeholder="Version of product or prototype referred to" invalid-feedback="Please Input Version" valid-feedback="Looks Good!" maxlength="60" required />
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-row>
              <mdb-col>
                <mdb-select v-model="foundDuringOptions" @getValue="setFoundDuring" label="Found During" invalid-feedback="Please Select Action" valid-feedback="Looks Good!" required/>
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-row>
              <mdb-col>
                <h5 class="h5" style="text-align: left;">Constraint On</h5>
                <mdb-form-inline>
                  <mdb-input type="radio" id="constraint-none" name="constraint-none" radioValue="None" v-model="constraintOn" label="None" checked/>
                  <mdb-input type="radio" id="constraint-test" name="constraint-test" radioValue="Test" v-model="constraintOn" label="Test" />
                  <mdb-input type="radio" id="constraint-release" name="constraint-release" radioValue="Release" v-model="constraintOn" label="Release" />
                </mdb-form-inline>
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-row>
              <mdb-col>
                <mdb-input class="mb-3" v-model="currentFunction" label="Current Functionality" placeholder="Provide brief description of the existing behavior" invalid-feedback="Please Input Current Behavior" valid-feedback="Looks Good!" required maxlength="300" />
                <p class="mt-n3" style="text-align: left; " :style="{'color': currentCharStyle}" >{{ 300 - currentFunction.length }} characters remaining</p>
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-row>
              <mdb-col>
                <mdb-input class="mb-3" v-model="expectedFunction" label="Expected Functionality" placeholder="Provide brief description of the desired behavior" invalid-feedback="Please Input Expected Behavior" valid-feedback="Looks Good!" required maxlength="300" />
                <p class="mt-n3" style="text-align: left;" :style="{'color': expectedCharStyle}">{{ 300 - expectedFunction.length }} characters remaining</p>
              </mdb-col>
            </mdb-row>
            <br>
            <mdb-btn class='QuadrusButton' v-on:click="submitQPR" type="submit" v-if="!submitProcessing">Submit</mdb-btn>
            <mdb-btn class='QuadrusButton' v-if="submitProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Processing...
            </mdb-btn>

            <br>
            <mdb-btn color="danger" v-on:click="clearQPRForm">Clear Form</mdb-btn>
          </form>
        </mdb-card-body>
      </mdb-card>
    </mdb-container>
  </div>
</template>

<script>
import { mdbInput, mdbCard, mdbCardBody, mdbContainer, mdbBtn, mdbSelect, mdbFormInline, mdbCol, mdbRow, mdbAlert } from 'mdbvue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
	name: 'QPRForm',
	components: {
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbContainer,
    mdbBtn,
    mdbSelect,
    mdbFormInline,
    mdbCol, 
    mdbRow,
    mdbAlert
	},
  data() {
    return {

      p1: false,
      p2: false,

      title: "",
      author: "",
      dateSubmitted: new Date(),
      foundIn: "",
      softwareVersion: "",
      foundDuring: "",
      constraintOn: "None",

      currentFunction: "",
      currentCharStyle: "#007E33",

      expectedFunction: "",
      expectedCharStyle: "#007E33",

      submitProcessing: false,

      foundDuringOptions: [
        { text: "Analysis", value: "Analysis" },
        { text: "Design", value:"Design" },
        { text: "Code / Unit Test", value: "Code / Unit Test" },
        { text: "Acceptance Test", value: "Acceptance Test" },
        { text: "In-Service Use", value: "In-Service Use" },
        { text: "Review", value: "Review" },
        { text: "Internal Training", value: "Internal Training" },
        { text: "FQT Dry Run", value: "FQT Dry Run" },
        { text: "FQT", value: "FQT" },
        { text: "Other", value: "Other" }
      ]
    }
  },
  methods: {

    clearQPRForm: function() {
      this.title = "";
      this.author = "";
      this.foundIn = "";
      this.softwareVersion = "";

      this.foundDuring = "";
      this.constraintOn = "None";
      this.currentFunction = "";
      
      this.expectedFunction = "";
    },

    submitQPR: async function() {
      this.submitProcessing = true;
      let token = this.$store.getters.getToken;

      this.title = this.sanitizeInput(this.title);
      this.author = this.sanitizeInput(this.author);
      this.foundIn = this.sanitizeInput(this.foundIn);
      this.softwareVersion = this.sanitizeInput(this.softwareVersion);
      this.foundDuring = this.sanitizeInput(this.foundDuring);
      this.constraintOn = this.sanitizeInput(this.constraintOn);
      this.currentFunction = this.sanitizeInput(this.currentFunction);
      this.expectedFunction = this.sanitizeInput(this.expectedFunction);

      let data = {
        data: {
          title: this.title,
          author: this.author,
          dateSubmitted: new Date(this.dateSubmitted),
          foundIn: this.foundIn,
          softwareVersion: this.softwareVersion,
          foundDuring: this.foundDuring,
          constraintOn: this.constraintOn,
          currentFunction: this.currentFunction,
          expectedFunction: this.expectedFunction
        }
      }
        try {
          if(this.title != '' && this.author != '' && this.foundIn != '' && this.softwareVersion != '' && this.currentFunction != '' && this.expectedFunction != ''){
            await DatabaseLib.postRequest('strapiDatabase','submitted-qpr',token,data);
            this.p1 = true;
            this.clearQPRForm(); // Housekeeping
          } else {
            this.p2 = true;
          }
          window.scroll({top: 0, behavior: 'smooth'});
          this.submitProcessing = false;
        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p2 = true;
          this.submitProcessing = false;
          return;
        }
    },

    setFoundDuring: function(data) {
      this.foundDuring = data;
    },

    sanitizeInput(content) {
			return this.$sanitize(content)
		},
  }, 
  watch: {
    currentFunction: function() {
      if(200 - this.currentFunction.length <= 100 && 200 - this.currentFunction.length > 50) {
        this.currentCharStyle = '#FF8800';
      } else if (200 - this.currentFunction.length <= 50) {
        this.currentCharStyle = '#CC0000';
      } else {
        this.currentCharStyle =  '#007E33';
      }
    },
    expectedFunction: function() {
      if(200 - this.expectedFunction.length <= 100 && 200 - this.expectedFunction.length > 50) {
        this.expectedCharStyle = '#FF8800';
      } else if (200 - this.expectedFunction.length <= 50) {
        this.expectedCharStyle = '#CC0000';
      } else {
        this.expectedCharStyle =  '#007E33';
      }
    }
  }
}
</script>