<template>
	<div class="page-start">
		<mdb-row class="justify-content-center">
			<mdb-col col="10">

			<!-- Alert Feedback -->
			<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
				Success! Submitted War Report! Go to Submitted WARs Page? (Click "No" to start New War)
				<router-link :to="'/WAR-Reports/Submitted-War'"><mdbBtn color="primary">Yes</mdbBtn></router-link>
				<mdbBtn color="danger" v-on:click="resetForm">No</mdbBtn>
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
				There was an error submitting WAR Report! Please try again!
			</mdb-alert>
			<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p3" @closeAlert="p3=false" dismiss>
				Success! Saved War Report! Go to Saved WARs Page? (Click "No" to start New War, or close to continue editing)
				<router-link :to="'/WAR-Reports/Edit-Saved-War'"><mdbBtn color="primary">Yes</mdbBtn></router-link>
				<mdbBtn color="danger" v-on:click="resetForm">No</mdbBtn>
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p4" @closeAlert="p4=false" dismiss>
				There was an error saving WAR Report! Please try again!
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p5" @closeAlert="p5=false" dismiss>
				There is already a report submitted for this Date Range, Project, and Task!
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p6" @closeAlert="p6=false" dismiss>
				There was an error retrieving data from the server! Please try again later.
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p7" @closeAlert="p7=false" dismiss>
				There is already a report saved for this Project and Task! Please submit it before starting a new one.
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p8" @closeAlert="p8=false" dismiss>
				ERROR: Unable to Save or Submit WAR Report.
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="success" v-if="p9" @closeAlert="p9=false" dismiss>
				Success! Submitted WARs for "No Activity!"
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p10" @closeAlert="p10=false" dismiss>
				There was an error when trying to submit WARs for "No Activity." Please Try Again!
			</mdb-alert>
      <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p11" @closeAlert="p11=false" dismiss>
				There was an error when trying to populate charge lines for Quick Submit! Please Try Again!
			</mdb-alert>
			<!-- /Alert Feedback -->

			<mdb-card id="warCard" class="m-2"> 
				<mdb-card-body>
					<!-- Form Start -->
					<form @submit.prevent="checkForm">
						<fieldset id="warForm"> 

						<h1 class="h1-responsive text-center mb-4">Weekly Activity Report</h1>
						<hr>
						<div class="grey-text" style="text-align: justify">

							<!-- First and Last Name -->
							<mdb-input basic class="mb-3" disabled :value="formWAR['firstLastName']">
								<span id="nameLabel" class="input-group-text" slot="prepend">First and Last name</span>
							</mdb-input>
							<!-- /First and Last Name -->

							<hr>
							<fieldset id="ProjTaskDate">

							<!-- Project Select -->
							<mdb-select id="projectSelect" label="Project" v-if="editing != true" @getValue="setProject" @change="populateTask" 
								v-model="projectOptions" required validation ref="validateProject" invalidFeedback="Please select a project."
							/>
							<mdb-input v-if="editing == true" v-model="formWAR['projectTask']" basic class="mb-3" disabled></mdb-input>
							<!-- /Project Select -->

							<hr>

							<!-- Task Select -->
							<mdb-select id="taskSelect" :disabled="taskDisable" label="Task" v-if="editing != true" @getValue="setTask" 
								v-model="taskOptions" required validation ref="validateTask" invalidFeedback="Please select a task."
							/>
							<mdb-input v-if="editing == true" v-model="formWAR['lineItem']" basic class="mb-3" disabled></mdb-input>
							<!-- /Task Select -->

							<hr>

							<!-- Date Select -->
							<mdb-select id="dateSelect" label="Date Range" v-if="editing != true" @getValue="setDate" v-model="dateOptions"
								required validation ref="validateDate" invalidFeedback="Please select a date range."
							/>
							<mdb-input v-if="editing == true" v-model="editingDate" basic class="mb-3" disabled></mdb-input>
							<!-- /Date Select -->

							</fieldset>
							<hr>
							
							<!-- Activity Editor -->
							<span id="activityLabel" class="input-group-text" slot="prepend">Activity</span>
							<textEditor id="activityForm" v-if="editing != true" v-model="formWAR['activity']" required @getContent='getActivity' />
							<textEditor id="activityEdit" v-if="editWAR" v-model="formWAR['activity']" required 
								:modelValue='this.editWAR.activity' @getContent='getActivity' 
							/>
							<!-- /Activity Editor -->

							<!-- Activity Validation Icons -->
							<div id="activityValid" style="display: none;" class="d-flex justify-content-end">
								<mdb-icon id="activityValidMark" icon="check" size="lg" class="mr-4 green-text" 
									style="position: relative; bottom: 30px; display: none;" 
								/>
								<mdb-icon id="activityInvalidMark" icon="times" size="lg" class="mr-4 red-text" 
									style="position: relative; bottom: 30px; display: none;" 
								/>
							</div>
							<!-- /Activity Validation Icons -->

							<!-- Activity Validation Feedback -->
							<div id="activityValidation" style="display: none;">
								<br>
								<p style="color: red; font-size: 0.8em;" class="text-left">Please enter an activity using the bullet points.</p>
							</div>
							<!-- /Activity Validation Feedback -->

							<hr>
						</div>

						<!-- Save & Submit Buttons if not Editing -->
						<div v-if="!editing" class="text-center">
							<mdb-btn id="submitWAR" color="primary" v-on:click="setSubmitType('submit')" v-if="!submitProcessing" type="submit" 
								@click.native="$refs.validateProject.validate(); $refs.validateTask.validate(); $refs.validateDate.validate()">
								Submit
							</mdb-btn>
							<mdb-btn v-if="submitProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
              </mdb-btn>
						</div>
						<div v-if="!editing" class="text-center">
							<mdb-btn id="saveWAR" outline="primary" v-on:click="setSubmitType('save')" v-if="!submitProcessing" type="submit"
								@click.native="$refs.validateProject.validate(); $refs.validateTask.validate(); $refs.validateDate.validate()">
								Save
							</mdb-btn>
							<mdb-btn v-if="submitProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
              </mdb-btn>
						</div>
						<!-- /Save & Submit Buttons if not Editing -->

						<!-- Save & Submit Buttons if Editing -->
						<div v-if="editing" class="text-center">
							<mdb-btn id="submitWAR" color="primary" v-on:click="setSubmitType('submit')" v-if="!submitProcessing" type="submit">
								Submit
							</mdb-btn>
							<mdb-btn v-if="submitProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
              </mdb-btn>
						</div>
						<div v-if="editing" class="text-center">
							<mdb-btn id="saveWAR" outline="primary" v-on:click="setSubmitType('save')" v-if="!submitProcessing" type="submit">
								Save
							</mdb-btn>
							<mdb-btn v-if="submitProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Processing...
              </mdb-btn>
						</div>
						<!-- /Save & Submit Buttons if Editing -->

						<!-- verify Modal -->
						<mdb-modal :show="verifyModal" centered @close="verifyModal = false">
							<mdb-modal-header>
								<mdb-modal-title>Caution!</mdb-modal-title>
							</mdb-modal-header>
							<mdb-modal-body>
								<h5 class="h5">You are about to submit your WAR report!</h5>
								<p>Are you sure you want to continue?</p>
							</mdb-modal-body>
							<mdb-modal-footer>
								<mdb-btn color="secondary" @click.native="verifyModal = false">Cancel</mdb-btn>
								<mdb-btn color="primary" v-on:click="submitted" @click.native="verifyModal = false">Continue</mdb-btn>
							</mdb-modal-footer>
						</mdb-modal>
						<!-- /Verify Modal -->

						</fieldset> 
					</form>
					<!-- /Form End -->

				</mdb-card-body>
			</mdb-card>
		</mdb-col>
	</mdb-row>
  <!--
  <form>
  <mdb-row class="justify-content-center">
    <mdb-col col="10">
      <mdb-card class="mb-3 d-flex">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Quick Submit No Activity</h3>
        <mdb-card-body>
          <br>
          <h5>This section is to quickly submit charge lines as "No Activity" at the end of the week. Simply select the charge lines you want to submit and a date range
            that the reports will be submitted under and click the "Submit" button!
          </h5>
          <br>
          <mdb-datatable-2 v-model="quickSubmitTable" multiselectable @selected="quickSubmitSelected = $event" />
          <mdb-row class="justify-content-center">
            <mdb-col col="3">
              <mdb-select id="dateSelectQuickSubmit" label="Date Range" @getValue="setDateQuickSubmit" v-model="dateOptions"
                required validation ref="validateDate" invalidFeedback="Please select a date range."/>
              <mdb-btn id="submitQuickActivity" class='QuadrusButton' v-on:click="submitNoActivity" size="lg" :disabled="(quickSubmitSelected.length == 0) || (dateQuickSubmit == null)">Submit</mdb-btn>
            </mdb-col>
          </mdb-row>
        </mdb-card-body>
      </mdb-card>
    </mdb-col>
  </mdb-row>
  </form>
  -->
	</div>
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody, mdbCol,mdbRow, mdbAlert,  mdbSelect, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbIcon } from 'mdbvue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
import TextEditor from '@/components/TextEditor.vue';

export default {
	name: "NewWar",
	components: {
		mdbInput,
		mdbBtn,
		mdbCard,
		mdbCardBody,
		mdbCol,
		mdbRow,
		mdbAlert,
		mdbSelect,
		mdbModal, 
		mdbModalHeader, 
		mdbModalTitle, 
		mdbModalBody, 
		mdbModalFooter,
		mdbIcon,
		TextEditor
	},
	data() {
		return {

			editing: false,
			editingDate: '',

			taskDisable: true,
			verifyModal: false,

			submitProcessing: false,

			projectOptions: [],
			taskOptions: [],
			dateOptions: [],

			submitType: '',

			saveSuccess: false,

      quickSubmitSelected: [],
      dateQuickSubmit: '',
      quickSubmitTable: {
        columns: [
        {
          label: 'Project',
          field: 'project',
          sort: 'true'
        },
        {
          label: 'Task',
          field: 'task',
          sort: 'true'
        }
        ],
        rows: []
      },

			formWAR: 
			{
				username: this.$store.getters.getUser,
				firstLastName: "",
				projectTask: '',
				lineItem: '',
				submittedDate: '',
				activity: '',
			},

			p1: false,
			p2: false,
			p3: false,
			p4: false,
			p5: false,
			p6: false,
			p7: false,
			p8: false,
      p9: false,
      p10: false,
      p11: false
		}
	},

	props: 
	{
		editWAR: 
		{
			id: String,
			projectTask: String,
			lineItem: String,
			activity: Object,
			startDate: String,
			endDate: String,
			editing: Boolean,
			default: null
		}
	},

	/* ----------------- Methods Start ----------------- */
	methods: 
	{

    test: function() {
      console.log("triggered");
    },

		//===========================================================================================
		//	Method Name	:	bindProps
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	----------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	If editWAR parameter is set, sets form to editing mode and populates fields using props
		//	given by parent.
		//===========================================================================================
		bindProps: function() 
		{

			if(this.editWAR != null) 
			{
				this.editing = true;
				this.formWAR['projectTask'] = this.editWAR.projectTask;
				this.formWAR['lineItem'] = this.editWAR.lineItem;
				this.formWAR['activity'] = this.editWAR.activity;

				// This is to help with user readability for the date range
				this.formWAR['submittedDate'] = this.editWAR.startDate + "," + this.editWAR.endDate;
				
				let start = new Date(this.editWAR.startDate);
				let end = new Date(this.editWAR.endDate);
				
				this.editingDate = (start.getUTCMonth() + 1) + "/" + start.getUTCDate() + " - " + (end.getUTCMonth() + 1) + "/" + end.getUTCDate()
			}
		},

		//===========================================================================================
		//	Method Name	:	verifyModal
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	----------	-------------------------------------
		//  Return Value			boolean			OUT			Date is valid or not.
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Verifies that the user wnats to submit their WAR. Prevents accidental submissions.
		//===========================================================================================
		verifyModalCall: function() 
		{
			this.verifyModal = true;
		},

		//===========================================================================================
		//	Method Name	:	checkForm
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	event					event			IN			Form submission event.
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Validates form inputs and provides validation classes and CSS feedback to each input.
		//	If any form inputs are invalid for submission, method returns null. If a war report with
		//	the same date range, project and task is found in the database, an alert is shown to the
		//	user and the method returns null. Otherwise, the submitType is checked and calls the 
		//	appropriate method [saved() or submitted()].
		//===========================================================================================
		checkForm: async function(event) 
		{
			if (this.editing != true) 
			{
				event.target.classList.add('was-validated');
			}

			// Attempts to parse out bullet point text. Fails if nothing has been typed.
			try 
			{
				JSON.parse(this.formWAR.activity).content[0].content[0].content[0].content[0];

				// If valid activity, display validation feedback
				if (this.editing != true)
				{
					document.getElementById("activityForm").classList.remove("invalidForm");
					document.getElementById("activityForm").classList.add("validForm");
				}
				else
				{
					document.getElementById("activityEdit").classList.remove("invalidForm");
					document.getElementById("activityEdit").classList.add("validForm");
				}

				document.getElementById("activityValidation").style.display = "none";
				document.getElementById("activityInvalidMark").style.display = "none";	
				document.getElementById("activityValidMark").style.display = "block";
			} 
			catch(error)
			{
				console.log(error);
				// If invalid activity, display validation feedback
				if (this.editing != true) 
				{
					document.getElementById("activityForm").classList.remove("validForm");
					document.getElementById("activityForm").classList.add("invalidForm");
					document.getElementById("activityValidMark").style.display = "none";
					document.getElementById("activityValidation").style.display = "block";
					document.getElementById("activityInvalidMark").style.display = "block";		
				}
				else
				{
					document.getElementById("activityEdit").classList.remove("validForm");
					document.getElementById("activityEdit").classList.add("invalidForm");
					document.getElementById("activityValidMark").style.display = "none";
					document.getElementById("activityValidation").style.display = "block";
					document.getElementById("activityInvalidMark").style.display = "block";		
				}
				return;
			}

			// If any form elements are empty, validation fails.
			if (this.formWAR.projectTask == null || this.formWAR.lineItem == null || this.formWAR.submittedDate == null)
			{
				return;
			}

			// Check if WAR report for this date range and project & task already exists
			if (this.editing != true && this.saveSuccess != true)
			{
				try 
				{
					let token = this.$store.getters.getToken;
					let dates = this.formWAR['submittedDate'].split(",");
					
					let sDate = new Date(dates[0]);
					let eDate = new Date(dates[1]);

					// Format Start Date for Query
					let startDate = sDate.getUTCFullYear() + '-';

					if ((sDate.getUTCMonth() + 1) < 10) {
						startDate += '0' + (sDate.getUTCMonth() + 1) + '-';
					}
					else
					{
						startDate += (sDate.getUTCMonth() + 1) + '-';
					}

					if (sDate.getUTCDate() < 10) {
						startDate += '0' + sDate.getUTCDate();
					}
					else
					{
						startDate += sDate.getUTCDate();
					}


					// Format End Date for Query
					let endDate = eDate.getUTCFullYear() + '-';

					if ((eDate.getUTCMonth() + 1) < 10) {
						endDate += '0' + (eDate.getUTCMonth() + 1) + '-';
					}
					else
					{
						endDate += (eDate.getUTCMonth() + 1) + '-';
					}

					if (eDate.getUTCDate() < 10) {
						endDate += '0' + eDate.getUTCDate();
					}
					else
					{
						endDate += eDate.getUTCDate();
					}

					// Query 1
					let query = "filters[username][$eq][0]=" + this.formWAR['username'] + "&filters[projectTask][$eq][1]=" +
					encodeURIComponent(this.formWAR['projectTask']) + "&filters[lineItem][$eq][2]=" + encodeURIComponent(this.formWAR['lineItem']) +
					"&filters[startDate][$eq][3]=" + startDate + "&filters[endDate][$eq][4]=" + endDate + "&filters[submitted][$eq]=true";

					// Response checks if submitted WAR for this charge line and date range already exists or not
					let response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);

					if (response.data.length > 0) {
						window.scroll({top: 0, behavior: 'smooth'});
						this.p5 = true;
						return;
					}

					// Query 2
					query = "filters[username][$eq][0]=" + this.formWAR['username'] + "&filters[projectTask][$eq][1]=" +
					encodeURIComponent(this.formWAR['projectTask']) + "&filters[lineItem][$eq][2]=" + encodeURIComponent(this.formWAR['lineItem']) +
					"&filters[submitted][$eq][3]=false";

					// Response checks if saved WAR for this charge line already exists or not
					response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);

					if (response.data.length > 0) {
						window.scroll({top: 0, behavior: 'smooth'});
						this.p7 = true;
						return;
					}
				} 
				catch  (error)
				{
					console.log(error);
					window.scroll({top: 0, behavior: 'smooth'});
					this.p4 = true;
					return;
				}
			}

			// Check submit type to determine which function to execute
			if (this.submitType === 'submit')
			{
				let submitForm = false;
				submitForm = this.verifyModalCall();

				// If verifyModal returns true, call submitted method
				if (submitForm == true)
				{
					this.submitted();
				}
			}
			else if (this.submitType === 'save')
			{
				this.saved();
			}
			else
			{
				window.scroll({top: 0, behavior: 'smooth'});
				this.p8 = true;
			}
		},

		//===========================================================================================
		//	Method Name	:	disableForm
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	----------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Checks submit type and disabled sections accordingly. If form is submitted, disables
		//	the entire form. If form is saved, disables project, task, and date fields.
		//===========================================================================================
		disableForm: function() 
		{
			if (this.submitType === "submit") 
			{
				document.getElementById("warForm").disabled = "disabled";
				document.getElementById("warCard").classList.add("disabledForm");
				document.getElementById("nameLabel").classList.add("disabledForm");
				document.getElementById("activityLabel").classList.add("disabledForm");
			}
			else if (this.editing != true && this.submitType === "save") 
			{
				//disable Project, Task, and Date select if saved report
				document.getElementById("ProjTaskDate").disabled = "disabled";
			}
		},

		//===========================================================================================
		//	Method Name	:	getActivity
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	jsonContent				Object			IN			Json Object of the activity field.
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Converts the Json Content in the activity field to a string and stores it in the
		//	activity field of formWAR.
		//===========================================================================================
		getActivity: function(jsonContent) 
		{
			this.formWAR.activity = JSON.stringify(jsonContent);
		},

    populateQuickSubmit: async function() {
      let token = this.$store.getters.getToken;

      try {
        
        let query = "filters[username][$eq][0]=" + this.$store.getters.getUser + "&filters[disabled][$eq][1]=" + false;
        let response = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);

        for(let i = 0; i < response.data.length; i = i + 1) {
          let fetchContract = {
            project: response.data[i].project,
            task: response.data[i].task,
            username: response.data[i].username,
            firstLastName: response.data[i].firstLastName,
            division: response.data[i].division
          }

          this.quickSubmitTable.rows.push(fetchContract);
        }
      } catch(error) {
				console.log(error);
        window.scroll({top: 0, behavior: 'smooth'});
        this.p11 = true;
        return;
      }
    },

		//===========================================================================================
		//	Method Name	:	populateFields
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	----------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Populates first and last name field, project selection dropdown, and date range dropdown.
		//	Projects options are based on what contracts user is assigned too.
		//===========================================================================================
		populateFields: async function() 
		{

			let token = this.$store.getters.getToken;
			let addProject = true;

			try 
			{
				let response = await DatabaseLib.getRequest('strapiDatabase','users/me',token);
				let tempFName = response.data.firstName;
				let tempLName = response.data.lastName;
				this.formWAR['firstLastName'] = tempFName + " " + tempLName;
			} 
			catch (error)
			{
				console.log(error);
				window.scroll({top: 0, behavior: 'smooth'});
				this.p6 = true;
				return;
			}

			try 
			{
				let query = 'filters[username][$eq][0]='+this.$store.getters.getUser+'&filters[disabled][$eq][1]='+false;
				let chargeLines = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);
				for(let i = 0; i < chargeLines.data.data.length; i = i + 1) 
				{
					addProject = true;
					// Check if project is already in options
					for (let j = 0; j < this.projectOptions.length; j = j + 1)
					
					{
							if (this.projectOptions[j].text === chargeLines.data.data[i].attributes.project)
							{
								addProject = false;
								break;
							}
					}

					// Add project if not already in options
					if (addProject == true)
					{
						this.projectOptions.push( {text: chargeLines.data.data[i].attributes.project, value: chargeLines.data.data[i].attributes.project} );
					}
				}

			} 
			catch (error)
			{
				console.log(error);
				window.scroll({top: 0, behavior: 'smooth'});
				this.p6 = true;
				return;
			}

			this.populateWeeks();

		},

		//===========================================================================================
		//	Method Name	:	populateTask
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Populates task select dropdown based on selected project.
		//===========================================================================================
		populateTask: async function() 
		{

			if(this.formWAR['projectTask'] != null) 
			{
				try 
				{
					let token = this.$store.getters.getToken;
					let query = "filters[project][$eq][0]=" + encodeURIComponent(this.formWAR['projectTask']) + "&filters[username][$eq][1]=" + this.$store.getters.getUser;
					let response =  await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);
					
					//Reset Task Options
					this.taskOptions.splice(0);

					for(let i = 0; i < response.data.data.length; i = i + 1) 
					{
						this.taskOptions.push( {text: response.data.data[i].attributes.task, value: response.data.data[i].attributes.task} )
					}

					this.taskDisable = false;
				} catch (error) {
					console.log(error);
					window.scroll({top: 0, behavior: 'smooth'});
					this.p6 = true;
					return;
				}
			}
		},

		//===========================================================================================
		//	Method Name	:	populateWeeks
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Populates date range select dropdown with the past 5 weeks.
		//===========================================================================================
		populateWeeks: function() 
		{
			var today = new Date();
			
			for(let i = 0; i < 5; i = i + 1) 
			{
				var startWeek = new Date(today.getFullYear(), today.getUTCMonth(), (today.getUTCDate() - today.getUTCDay() + (today.getUTCDay() == 0 ? -6:1))-(7*i));
				var endWeek = new Date(today.getFullYear(), today.getUTCMonth(), (today.getUTCDate() - today.getUTCDay() + (today.getUTCDay() == 0 ? -2:5))-(7*i));
				
				let optionText = (startWeek.getUTCMonth() + 1) + "/" + startWeek.getUTCDate() + " - " + (endWeek.getUTCMonth() + 1) + "/" + endWeek.getUTCDate();
				let optionValue = (startWeek.getUTCMonth() + 1) + "/" + startWeek.getUTCDate() + "/" + startWeek.getFullYear() + "," + (endWeek.getUTCMonth() + 1) + 
				"/" + endWeek.getUTCDate() + "/" + endWeek.getFullYear();

				this.dateOptions.push( {text: optionText, value: optionValue} );
			}
		},

		//===========================================================================================
		//	Method Name	:	resetForm
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Refreshes page to reset fields and CSS.
		//===========================================================================================
		resetForm: function() 
		{
			this.$router.go(0);
		},

		//===========================================================================================
		//	Method Name	:	sanitizeInput
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	content					string			IN			Input to be sanitized for DB entry.
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	
		//===========================================================================================
		sanitizeInput(content) 
		{
			let input = this.$sanitize(content);
			input = input.replace(/&amp;/g, "&");
			return input;
		},

		//===========================================================================================
		//	Method Name	:	saved
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Calls sanitizeInput method to sanatize form inputs and attempts to enter war into the
		//	database as "Saved". If successful, disables editing of Project, Task, and Date Range
		//	for current WAR and enables alert providing the option to continue editing this WAR,
		//	starting a new WAR, or viewing the saved WAR table. If database submission fails, the
		//	error alert is enabled.
		//===========================================================================================
		saved: async function () 
		{
			this.submitProcessing = true;
			// Sanatizes formWAR inputs
			await Object.keys(this.formWAR).forEach(key => {
				this.formWAR[key] = this.sanitizeInput(this.formWAR[key]);
			})
			
			let token = this.$store.getters.getToken

			// Attempt submitting WAR to the database
			try 
			{
				let query = "filters[username][$eq][0]=" + this.formWAR['username'] + "&filters[projectTask][$eq][1]=" +
				encodeURIComponent(this.formWAR['projectTask']) + "&filters[lineItem][$eq][2]=" + encodeURIComponent(this.formWAR['lineItem']);

				query = query + "&filters[submitted][$eq][3]="+false;
				// Response checks if saved WAR already exists or not
				let response =  await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
				let queryDivision = "filters[project][$eq][0]="+encodeURIComponent(this.formWAR['projectTask'])+"&filters[task][$eq][1]="+encodeURIComponent(this.formWAR['lineItem']);
				let getDivision = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,queryDivision);
				let dates = this.formWAR['submittedDate'].split(",");
				let sDate = new Date(dates[0]);
				let eDate = new Date(dates[1]);
				let data = {
					data:
					{
						firstLastName: this.formWAR['firstLastName'],
						projectTask: this.formWAR['projectTask'],
						lineItem: this.formWAR['lineItem'],
						activity: JSON.parse(this.formWAR['activity']),
						username: this.formWAR['username'],
						startDate: new Date(sDate.getTime() - sDate.getTimezoneOffset() * -60000 ),
						endDate: new Date(eDate.getTime() - eDate.getTimezoneOffset() * -60000 ),
						submitted: false,
						division: getDivision.data.data[0].division,
						lastSaved: new Date()
					}	
				}
				/*
					This may be a truly cursed piece of code, but it's what we have to work with since we have .json files

					This code cleans up any trailing empty bulletpoints from the input data before continuing in the code
					data.activity.content[0] is first layer of bulletpoints
				*/
				for (let ii = 0; ii < data.data.activity.content.length; ii = ii + 1) {
					if(data.data.activity.content[ii].content) {
					for(let i = 0; i < data.data.activity.content[ii].content.length; i = i + 1) {  // layer 1
						if(typeof data.data.activity.content[ii].content[i].content[0].content == "undefined") {
							data.data.activity.content[ii].content.splice(i, 1);
							continue;
						}
						if(data.data.activity.content[ii].content[i].content.length > 1) {
							for(let j = 0; j < data.data.activity.content[ii].content[i].content[1].content.length; j = j + 1) { // layer 2
								if(typeof data.data.activity.content[ii].content[i].content[1].content[j].content[0].content == "undefined") {
									data.data.activity.content[ii].content[i].content[1].content.splice(j, 1);
									continue;
								}
								if(data.data.activity.content[ii].content[i].content[1].content[j].content.length > 1) {

									for(let k = 0; k < data.data.activity.content[0].content[i].content[1].content[j].content[1].content.length; k = k + 1) { // layer 3
										if(typeof data.data.activity.content[ii].content[i].content[1].content[j].content[1].content[k].content[0].content == "undefined") {
											data.data.activity.content[ii].content[i].content[1].content[j].content[1].content.splice(k, 1);
											continue;
										}
									}
								}
							}
						}
					}
				}
      }
				if(response.data.data.length == 0) // If saved WAR does not exist, create it
				{	
					await DatabaseLib.postRequest('strapiDatabase','war-reports',token,data);
				}
				else if(response.data.data.length > 0) // If saved WAR already exists, update it
				{
					await DatabaseLib.putRequest('strapiDatabase','war-reports',token,response.data.data[0].id,data);
				}
				
				this.disableForm();
				window.scroll({top: 0, behavior: 'smooth'});
				this.p3 = true;	
				this.saveSuccess = true;
			} 
			catch (error)
			{
				console.log(error);
				window.scroll({top: 0, behavior: 'smooth'});
				this.p4 = true;
				this.submitProcessing = false;
				return;
			}
			this.submitProcessing = false;
		},

		//===========================================================================================
		//	Method Name	:	setDate
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	------------------------------------
		//	value					string			IN			Date string to set as submitted date
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sets formWAR submittedDate field as given date string value.
		//===========================================================================================
		setDate: function(value) 
		{
			this.formWAR['submittedDate'] = value;
		},

    setDateQuickSubmit: function(value) {
      this.dateQuickSubmit = value;
    },

		//===========================================================================================
		//	Method Name	:	setProject
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	value					string			IN			Project string to set as projectTask.
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sets formWAR projectTask field as given project string value.
		//===========================================================================================
		setProject: function(value) 
		{
			this.formWAR['projectTask'] = value;
		},

		//===========================================================================================
		//	Method Name	:	setSubmitType
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	value					string			IN			Submit Type string.
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sets the submit type to the given value.
		//===========================================================================================
		setSubmitType: function(value) 
		{
			this.submitType = value;
		},

		//===========================================================================================
		//	Method Name	:	setTask
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//	value					string			IN			Task string to set as line item.
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Sets formWAR lineItem field as given task string value.
		//===========================================================================================
		setTask: function(value) 
		{
			this.formWAR['lineItem'] = value;
		},

		//===========================================================================================
		//	Method Name	:	submitted
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Calls sanitizeInput method to sanatize form inputs and attempts to enter war into the
		//	database as "Submitted". If successful, disables editing of entire form for current
		//	WAR and enables alert providing the option to view submitted WAR table or create new WAR.
		//	If database submission fails, the error alert is enabled.
		//===========================================================================================
		submitted: async function () 
		{
			this.submitProcessing = true;
			// Sanatizes formWAR inputs
			Object.keys(this.formWAR).forEach(key => {
				this.formWAR[key] = this.sanitizeInput(this.formWAR[key]);
			})

			let token = this.$store.getters.getToken;

			// Attempt submitting WAR to the database
			try 
			{
				let query = "filters[username][$eq][0]=" + this.formWAR['username']+"&filters[projectTask][$eq][1]=" +
				encodeURIComponent(this.formWAR['projectTask']) + "&filters[lineItem][$eq][2]=" + encodeURIComponent(this.formWAR['lineItem']);
				
				query = query + "&filters[submitted][$eq][3]="+false;

				// Response checks if saved WAR already exists or not
				let response =  await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);

				let queryDivision = "filters[project][$eq][0]="+encodeURIComponent(this.formWAR['projectTask'])+"&filters[task][$eq][1]="+encodeURIComponent(this.formWAR['lineItem']);
				let getDivision = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,queryDivision);


				let dates = this.formWAR['submittedDate'].split(",")
				let sDate = new Date(dates[0]);
				let eDate = new Date(dates[1]);

				let data = 
				{
					data: {
						firstLastName: this.formWAR['firstLastName'],
						projectTask: this.formWAR['projectTask'],
						lineItem: this.formWAR['lineItem'],
						activity: JSON.parse(this.formWAR['activity']),
						username: this.formWAR['username'],
						startDate: new Date(sDate.getTime() - sDate.getTimezoneOffset() * -60000 ),
						endDate: new Date(eDate.getTime() - eDate.getTimezoneOffset() * -60000 ),
						submitted: true,
						division: getDivision.data.data[0].division
					}
				}
				/*
					This may be a truly cursed piece of code, but it's what we have to work with since we have .json files

					This code cleans up any trailing empty bulletpoints from the input data before continuing in the code
					data.activity.content[0] is first layer of bulletpoints
				*/
				for (let ii = 0; ii < data.data.activity.content.length; ii = ii + 1) {
					if(data.data.activity.content[ii].content) {
					for(let i = 0; i < data.data.activity.content[ii].content.length; i = i + 1) {  // layer 1
						if(typeof data.data.activity.content[ii].content[i].content[0].content == "undefined") {
							data.data.activity.content[ii].content.splice(i, 1);
							continue;
						}
						if(data.data.activity.content[ii].content[i].content.length > 1) {
							for(let j = 0; j < data.data.activity.content[ii].content[i].content[1].content.length; j = j + 1) { // layer 2
								if(typeof data.data.activity.content[ii].content[i].content[1].content[j].content[0].content == "undefined") {
									data.data.activity.content[ii].content[i].content[1].content.splice(j, 1);
									continue;
								}
								if(data.data.activity.content[ii].content[i].content[1].content[j].content.length > 1) {

									for(let k = 0; k < data.data.activity.content[0].content[i].content[1].content[j].content[1].content.length; k = k + 1) { // layer 3
										if(typeof data.data.activity.content[ii].content[i].content[1].content[j].content[1].content[k].content[0].content == "undefined") {
											data.data.activity.content[ii].content[i].content[1].content[j].content[1].content.splice(k, 1);
											continue;
										}
									}
								}
							}
						}
					}
				}
      }


				if(response.data.data.length == 0)	// If saved WAR does not exist, create it
				{
					await DatabaseLib.postRequest('strapiDatabase','war-reports',token,data);
				} 
				else if(response.data.data.length > 0) // If saved WAR exists, update it
				{
					await DatabaseLib.putRequest('strapiDatabase','war-reports',token,response.data.data[0].id,data);
				}

				window.scroll({top: 0, behavior: 'smooth'});
				
				this.disableForm();
				this.p1 = true;
			} 
			catch (error)
			{
				console.log(error);
				window.scroll({top: 0, behavior: 'smooth'});
				this.p2 = true;
				console.log(error);
				this.submitProcessing = false;
				return;
			}
			this.submitProcessing = false;
		},

    /*
    submitNoActivity: async function() {

      let token = this.$store.getters.getToken;

      try {
        for(let i = 0; i < this.quickSubmitSelected.length; i = i + 1) {

          let dates = this.dateQuickSubmit.split(",")
          let noActivity = '{"type":"doc","content":[{"type":"bulletList","content":[{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"No Activity"}]}]}]}]}';

          let data = {
            firstLastName: this.quickSubmitSelected[i].firstLastName,
            projectTask: this.quickSubmitSelected[i].project,
            lineItem: this.quickSubmitSelected[i].task,
            username: this.quickSubmitSelected[i].username,
            submitted: true,
            startDate: new Date(dates[0]),
            endDate: new Date(dates[1]),
            division: this.quickSubmitSelected[i].division,
            activity: JSON.parse(noActivity)
          }

          await DatabaseLib.postRequest('strapiDatabase','war-reports',token,data);

        }

        window.scroll({top: 0, behavior: 'smooth'});
        this.p9 = true;

      } catch(error) {

        window.scroll({top: 0, behavior: 'smooth'});
        this.p10 = true;
        return;
      }
    }
    */
	},
	/* ------------------ Methods End ------------------ */

	mounted() 
	{
		this.populateFields();
		this.bindProps();
    //this.populateQuickSubmit();
	}
}
</script>