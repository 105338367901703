<template>
	<div class="page-start">
		<mdb-row class='justify-content-center'>
			<mdb-col col='10'>
				<mdb-card>
          
					<h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Edit account
            <br>
            <p class="mt-2"> OR </p>
            <mdb-container>
              <router-link :to="'/View-Edit-User-Page'"><mdbBtn block class='mb-3 mt-3' color="default" ><h5>Return to Users Page</h5></mdbBtn></router-link>
            </mdb-container>
          </h3>
          
					<mdb-card-body>
						<!-- Submission Alerts -->
						<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
							Success! User updated! Go back to View/Edit Page?
							<router-link :to="'/View-Edit-User-Page'"><mdbBtn color="primary">Yes</mdbBtn></router-link>
							<mdbBtn color="danger" v-on:click="p10=false">No</mdbBtn>
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
							There was an error updating user! Please try again! Error Code P2
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p3" @closeAlert="p3=false" dismiss>
							Invalid Inputs! Please check them and try again! Error Code P3
						</mdb-alert>
						<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p4" @closeAlert="p4=false" dismiss>
							Success! User disabled!
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p5" @closeAlert="p5=false" dismiss>
							There was an error disabling user! Please try again! Error Code P5
						</mdb-alert>
						<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p6" @closeAlert="p6=false" dismiss>
							Success! User enabled!
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p7" @closeAlert="p7=false" dismiss>
							There was an error enabling user! Please try again! Error Code P7
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p8" @closeAlert="p8=false" dismiss>
							There was an error retrieving information from database! Error Code P8
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p9" @closeAlert="p9=false" dismiss>
							There was an error verifying user data! Please try again! Error Code P9
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p10" @closeAlert="p10=false" dismiss>
							There was an error fetching user access data! Please try again! Error Code P10
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p11" @closeAlert="p11=false" dismiss>
							There was an error fetching direct reports data! Please try again! Error Code P11
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p12" @closeAlert="p12=false" dismiss>
							There was an error updating user disable status! Please try again! Error Code P12
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p13" @closeAlert="p13=false" dismiss>
							There was an error getting disabled user contract data! Please try again! Error Code P13
						</mdb-alert>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p14" @closeAlert="p14=false" dismiss>
							There was an error remopving disabled user's contract data! Please try again! Error Code P14
						</mdb-alert>
						<!-- /Submission Alerts -->
						<form @submit.prevent="checkForm" novalidate>
							<div class= 'grey-text text-left'>
								<mdb-container>
									<h3 class="h3-responsive">Account Information</h3>
									<mdb-input sm label='Username' id="usernameInput" v-model="user.username" type='text' required 
										:customValidation="validation.username.validated"
										:isValid="validation.username.valid"
										@change="validate('username')"
										invalidFeedback="Username Invalid or Already in Use" validFeedback="Looks Good!"
										/>
									<mdb-input sm label='E-mail' id="emailInput" v-model="user.email" type='email' required 
										:customValidation="validation.email.validated"
										:isValid="validation.email.valid"
										@change="validate('email')"
										invalidFeedback="Email Invalid or Already in Use" validFeedback="Looks Good!"/>

									<mdb-btn v-if="disableUser" block color="danger" id="disableUserCheck" v-on:click="disableUserModal = true">Disable User</mdb-btn>
									<mdb-btn v-if="enableUser" block color="primary" id="enableUserCheck" v-on:click="enableUserModal = true">Enable User</mdb-btn>
								</mdb-container>
								<br><hr><br>
								<mdb-container>
									<h3 class="h3-responsive">Personal Information</h3>
									<mdb-input sm label='First Name' id="firstNameInput" v-model="user.firstName" group type='text' required 
										:customValidation="validation.firstName.validated" 
										:isValid="validation.firstName.valid"
										@change="validate('firstName')"
										invalidFeedback="Required Field" validFeedback="Looks Good!"/>
									<mdb-input sm label='Last Name' id="lastNameInput" v-model="user.lastName" group type='text' required 
										:customValidation="validation.lastName.validated" 
										:isValid="validation.lastName.valid"
										@change="validate('lastName')"
										invalidFeedback="Required Field" validFeedback="Looks Good!"/>
									<mdb-date-picker-2 v-model="user.dob" label= 'Date of Birth' id="dobInput" title= 'Select Date' disabledFuture required
										:customValidation="validation.dob.validated" 
										:isValid="validation.dob.valid"
										@change="validate('dob')"
										invalidFeedback="Required Field" validFeedback="Looks Good!"/>

								</mdb-container>
								<br><hr><br>
								<mdb-container>
									<h3 class="h3-responsive">Professional Information</h3>
									<mdb-input sm v-model="user.title" label='Title' group type='text' validFeedback="Optional"/>
									<mdb-input sm v-model="user.division" label='Division' group type='text' validFeedback="Optional"/>
									<mdb-select v-model="reportOptions" @getValue="setDirectReport" label="Direct Report"/>
									<mdb-input sm label='Office Phone #' v-model="user.workPhone" group type='phone' validFeedback="Optional"/>
									<mdb-input sm label='Work Cell Phone #' v-model="user.workCellPhone" group type='phone' validFeedback="Optional"/>

									<br>
									<div>
										<h3 class="h3-responsive">User Access</h3>
										<h5 class="h5-responsive"><u>NOTE:</u> Everyone has a default "Public" role that allows then to access basic functions. Only add below roles if more advanced functions are needed. </h5>

										<mdb-select multiple :options="accessLevels" @getValue="setUserAccessLevel" label="User Access"/>
									</div>

								</mdb-container>
								<br><hr><br>
								<mdb-container>
									<h3 class="h3-responsive">Other</h3>
									<mdb-file-input @getValue="getProfilePic" sm btn-title='ADD PHOTO' btnColor='blue'/>
									<mdb-input sm label='Real Colors Result' v-model="user.realColorResult" group type='text' />
								</mdb-container>
							</div>
							<br>
							<mdb-btn block type="submit" class='pl-3 pr-3 QuadrusButton'>Update User</mdb-btn>
              
						</form>

						<!-- Disable User Modal -->
						<mdb-modal size="lg" centered :show="disableUserModal" @close="disableUserModal = false">
							<mdb-modal-header>
								<mdb-modal-title><p class="h2 red-text">WARNING!</p></mdb-modal-title>
							</mdb-modal-header>
							<mdb-modal-body>
								<h5><u>You are DISABLING user {{ this.user.firstName + ' ' + this.user.lastName }} </u></h5>
								<h5>Disabling a user will do the following:</h5>
								<hr>
									<ul class="text-left">
										<li>They will be removed from any user access levels they are assigned to.</li>
										<li>It will remove them from all charge lines they are assigned to and will no longer appear as an option for assignment.</li>
										<li>They will no longer be able to log in and will lose all database access immediately (even if currently logged in).</li>
									</ul>
								<hr>
								<h5>Do you wish to continue?</h5>
							</mdb-modal-body>
							<mdb-modal-footer class="d-flex justify-content-between">	
								<mdb-btn color="secondary" @click.native="disableUserModal = false">Nevermind</mdb-btn>
								<mdb-btn color="danger" v-on:click="toggleDisable" @click.native="disableUserModal = false">Disable user</mdb-btn>
							</mdb-modal-footer>
						</mdb-modal>
						<!-- /Disable User Modal -->

						<!-- Enable User Modal -->
						<mdb-modal size="lg" centered :show="enableUserModal" @close="enableUserModal = false">
							<mdb-modal-header>
								<mdb-modal-title><p class="h2 red-text">WARNING!</p></mdb-modal-title>
							</mdb-modal-header>
							<mdb-modal-body>
								<h5><u>You are ENABLING user {{ this.user.firstName + ' ' + this.user.lastName }} </u></h5>
								<h5>Enabling a user will do the following:</h5>
								<hr>
									<ul class="text-left">
										<li>They will be able to log in again.</li>
										<li>They will need to be reassigned user access levels.</li>
										<li>They will reappear as an option in charge line assignments.</li>
									</ul>
								<hr>
								<h5>Do you wish to continue?</h5>
							</mdb-modal-body>
							<mdb-modal-footer class="d-flex justify-content-between">	
								<mdb-btn color="secondary" @click.native="enableUserModal = false">Nevermind</mdb-btn>
								<mdb-btn color="danger" v-on:click="toggleEnable" @click.native="enableUserModal = false">Enable user</mdb-btn>
							</mdb-modal-footer>
						</mdb-modal>
						<!-- /Enable User Modal -->

					</mdb-card-body>
				</mdb-card>
			</mdb-col>
		</mdb-row>
	</div>
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody, mdbCol, mdbRow, mdbDatePicker2, mdbFileInput, mdbContainer, 
mdbSelect, mdbAlert, mdbModal, mdbModalHeader, mdbModalBody, mdbModalFooter, mdbModalTitle } from 'mdbvue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
	name:"Edit-User",

	components: {
		mdbInput,
		mdbBtn,
		mdbCard,
		mdbCardBody,
		mdbCol,
		mdbRow,
		mdbDatePicker2,
		mdbFileInput,
		mdbContainer,
		mdbSelect,
		mdbAlert,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter
	},

	data() {
		return {
			user: 
			{
				id: '',
				username: '',
				email: '',
				firstName: '',
				lastName: '',
				workPhone: '',
				workCellPhone: '',
				dob: '',
				role: '',
				reportsTo: '',
				accessLevel: '',
				groups: [],
				realColorResult: '',
			},

			validation: 
			{
				username: 
				{
					valid: false,
					validated: false
				},
				email: 
				{
					valid: false,
					validated: false
				},
				firstName: 
				{
					valid: false,
					validated: false
				},
				lastName: 
				{
					valid: false,
					validated: false
				},
				dob: 
				{
					valid: false,
					validated: false
				}
			},

			accessLevels: [],

			formerUsername: '',
			formerEmail: '',
			
			enableUser: false,
			enableUserModal: false,

			disableUser: false,
			disableUserModal: false,

			adminId: '',

			roleAdmin: '',
			roleUser: '',

			profilePic: { file: null },

			reportOptions: [],

			p1: false,
			p2: false,
			p3: false,
			p4: false,
			p5: false,
			p6: false,
			p7: false,
			p8: false,
			p9: false,
			p10: false,
			p11: false,
			p12: false,
			p13: false,
			p14: false
		}
	},

	methods: {

	//===========================================================================================
	//	Method Name	:	checkForm
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sanitizes and validates form data. Once sanitized, the data is then checked if it is valid.
	//  If the data is valid, then the function updateUser is called.
	//===========================================================================================
		checkForm: async function() 
		{

			let token = this.$store.getters.getToken;
			let query = '';

			await Object.keys(this.user).forEach(key => {
				this.user[key] = this.sanitizeInput(this.user[key]);
			})

			let isValid = true;
			await Object.keys(this.user).forEach(async key => {
				if(!await this.validate(key)) {
					isValid = false;

					window.scroll({top: 0, behavior: 'smooth'});
					this.p3 = true;
					return;
				}
			})

			if (this.user.username != this.formerUsername) 
			{
				query = "filters[username][$eq][0]="+this.user['username'];
				let response =  '';
				try {
					response = await DatabaseLib.getRequest('strapiDatabase','users',token,query,false);
				} catch (error) {
					window.scroll({top: 0, behavior: 'smooth'});
					this.p9 = true;
					return;
				}
				if (response.data.length != 0 || !this.user['username']) {
					this.validation['username'].valid = false;
					isValid = false;
				} else if(response.data.length == 0 && this.user['username']) {
					this.validation['username'].valid = true;
				}
				this.validation['username'].validated = true;
			}
			

			if (this.user.email != this.formerEmail) 
			{
				query = "filters[email][$eq][0]="+this.user['email'];
				let response =  '';
				try {
					response = await DatabaseLib.getRequest('strapiDatabase','users',token,query,false);
				} catch (error) {
					window.scroll({top: 0, behavior: 'smooth'});
					this.p9 = true;
					return;
				}

				if (response.data.length != 0 || !this.user['email']) {
					this.validation['email'].valid = false;
					isValid = false;
				} else if(response.data.length == 0 && this.user['email']) {
					this.validation['email'].valid = true;
				}
				this.validation['email'].validated = true;
			}
			
			if(isValid) {
				this.updateUser();
			}

		},
	//===========================================================================================
	//	Method Name	:	populateFields
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Populates the form fields with the passed user data. The data is gathered from the uid
	//  from the router.
	//===========================================================================================
		populateFields: async function()
		{
			try {
				let token = this.$store.getters.getToken;
				let id = this.$route.params.id;
				let response = '';
				let query = "populate[0]=role&populate[1]=groups"
				response = await DatabaseLib.getRequest('strapiDatabase','users/' + id,token, query, false);
				
				this.user.id = response.data.id;
				this.user.firstName = response.data.firstName;
				this.user.lastName = response.data.lastName;
				this.user.username = response.data.username;
				this.user.email = response.data.email;
				this.user.role = response.data.role.name;
				this.user.dob = response.data.dob;
				this.user.workPhone = response.data.workPhone;
				this.user.workCellPhone = response.data.workCellPhone;
				this.user.title = response.data.title;
				this.user.division = response.data.division;
				this.user.reportsTo = response.data.reportsTo;
				this.user.groups = response.data.groups;
				this.user.realColorResult = response.data.realColor;
				
				// Set Access Levels in form
				this.populateUserAccess();

				// Set Direct Report options in form
				this.populateDirectReports();

				// Set Username and Email in case they change for validation
				this.formerUsername = this.user.username;
				this.formerEmail = this.user.email;

				// Check if user is Enabled or Disabled
				if (response.data.blocked == true)
				{
					this.enableUser = true;
				}
				else
				{
					this.disableUser = true;
				}
			} catch(error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p8 = true;
				return;	
			}
		},

	//===========================================================================================
	//	Method Name	:	setUserAccessLevel
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  value                   String          in          holds the user access level selected
	//                                                      from the form dropdown list
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sets the form value for user access from the dropdown list
	//===========================================================================================
		setUserAccessLevel: async function(value) 
		{
			this.user.accessLevel = value;
		},

	//===========================================================================================
	//	Method Name	:	setRoles
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sets the role IDs for the respective roles. 
	//  CAUTION: Roles differ from Groups. These roles are useful for what can be done in the
	//  database.
	//===========================================================================================
		setRoles: async function()
		{
			try 
			{
				let token = this.$store.getters.getToken;
				let response = await DatabaseLib.getRequest('strapiDatabase','users-permissions/roles',token, '',false);
				response.data.roles.forEach(role => {
					if (role.name === 'Administrator')
					{
						this.roleAdmin = role.id;
					}
					if (role.name === 'Authenticated')
					{
						this.roleUser = role.id;
					}
				});
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p8 = true;
				return;		
			}
		},

	//===========================================================================================
	//	Method Name	:	populateUserAccess
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Populates the form dropdown data user groups.  
	//===========================================================================================
		populateUserAccess: async function() 
		{
			let userGroups = [];
			this.user.groups.forEach(group => {
				userGroups.push(group.name);
			});

			let token = this.$store.getters.getToken;

      // Get group data from database
			let groups = '';
			try {
				groups = await DatabaseLib.getRequest('strapiDatabase','groups',token,'',false);
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p10 = true;
				return;
			}
      // Set the dropdown data
			for(let i = 0; i < groups.data.data.length; i = i + 1) {
				if (groups.data.data[i].name === 'Administration')
				{
					this.adminId = groups.data.data[i].id;
				}

				let obj = {};
				obj["text"] = groups.data.data[i].attributes.name;
				obj["value"] = groups.data.data[i].id;
				if (userGroups.includes(obj.text))
				{
					obj["selected"] = true;
				}
				this.accessLevels.push(obj);
			}
		},

	//===========================================================================================
	//	Method Name	:	populateDirectReports
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Populates the form dropdown data for Direct Reports
	//===========================================================================================
		populateDirectReports: async function() {
			let token = this.$store.getters.getToken;
			let query = "&populate[0]=groups";
			
			//Default Option if no reports
			let none = {};
			none["text"] = "None";
			none["value"] = "None";

      // Get direct report data
			let response = '';
			try {
				response = await DatabaseLib.getRequest('strapiDatabase','users',token,query,false);
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p11 = true;
				return;
			}
			let sup = [];
			for(let c = 0; c < response.data.length; c = c + 1){
				for(let i = 0; i < response.data[c].groups.length; i = i + 1) {
					if(response.data[c].groups[i].name == "Supervisor"){
						sup.push(response.data[c]);
					}
				}
			}


      // Populate dropdown with direct report data
			sup.forEach(supervisor => {
				let obj = {};
				obj["text"] = supervisor.firstName + ' ' + supervisor.lastName;
				obj["value"] = supervisor.username;
				if (this.user.reportsTo === obj.value)
				{
					obj["selected"] = true;
				}
				this.reportOptions.push(obj);
			});

			// Don't put at beginning, it will break for literally no reason
			if (this.user.reportsTo === "None")
			{
				none["selected"] = true;
			}
			
			this.reportOptions.splice(0,0,none);
		},

	//===========================================================================================
	//	Method Name	:	setDirectReport
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  Value                   String          in          Holds the username for direct report
	//                                                      selected from the dropdown list
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sets the form value for direct report from the dropdown list
	//===========================================================================================
		setDirectReport: function(value) 
		{
			this.user.reportsTo = value;
		},

	//===========================================================================================
	//	Method Name	:	getProfilePic
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  Value                   Object          in          Profile pic form data from user input
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sets the form value for profile picture from user input
	//===========================================================================================
		getProfilePic: function(value) {
			this.profilePic.file = value[0];
		},

    //===========================================================================================
	//	Method Name	:	sanitizeInput
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//	content					Any   			IN			Input to be sanitized for DB entry.
	//  Return Value			Any				Out			Returned sanitized input
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sanitizes form input and returns the sanitized data
	//===========================================================================================
		sanitizeInput(content) 
		{
			return this.$sanitize(content)
		},

    //===========================================================================================
	//	Method Name	:	toggleDisable
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Disables the specified user account and deletes the charge lines associated with the account
	//===========================================================================================
		toggleDisable: async function()
		{
			try
			{
				let token = this.$store.getters.getToken;
				let data = { blocked: true, groups: [] };

        // Disables the users account
				try {
					await DatabaseLib.putRequest('strapiDatabase','users',token,this.$route.params.id,data);
				} catch (error) {
					window.scroll({top: 0, behavior: 'smooth'});
					this.p12 = true;
					return;
				}

				let query = 'filters[username][$eq][0]='+ this.user.username;
				let contractIDs = [];
				let response = '';

        // Fetches contract data associated with the newly disabled user
				try {
					response = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query,false);
				} catch (error) {
					window.scroll({top: 0, behavior: 'smooth'});
					this.p13 = true;
					return;
				}

				response.data.data.forEach(contract => 
				{
					contractIDs.push(contract.id);
				});

        // Deletes the charge lines for the newly disabled user
				for (let i = 0; i < contractIDs.length; i = i + 1)
				{
					try {
						await DatabaseLib.deleteRequest('strapiDatabase','assigned-contracts',token,contractIDs[i]);
					} catch (error) {
						window.scroll({top: 0, behavior: 'smooth'});
						this.p14 = true;
						return;
					}
				}

				this.disableUser = false;
				this.enableUser = true;
				this.user.accessLevel = '';
				this.accessLevels.forEach(level => {
					level.selected = false;
				});

				window.scroll({top: 0, behavior: 'smooth'});
				this.p4 = true;
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p5 = true;
				return;
			}
		},

    //===========================================================================================
	//	Method Name	:	toggleEnable
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Enables the specified user account
	//===========================================================================================
		toggleEnable: async function()
		{
			try
			{
				let token = this.$store.getters.getToken;
				let data = { blocked: false };

				await DatabaseLib.putRequest('strapiDatabase','users',token,this.$route.params.id,data);

				this.enableUser = false;
				this.disableUser = true;

				window.scroll({top: 0, behavior: 'smooth'});
				this.p6 = true;
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p7 = true;
				return;
			}
		},

    //===========================================================================================
	//	Method Name	:	updateUser
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Uses the form data and updates the specified user account with the new information
	//===========================================================================================
		updateUser: async function()
		{
			let token = this.$store.getters.getToken;
			try {
				
				let data = {
					username: this.user.username,
					email: this.user.email,
					firstName: this.user.firstName,
					lastName: this.user.lastName,
					dob: this.user.dob,
					workPhone: this.user.workPhone,
					workCellPhone: this.user.workCellPhone,
					title: this.user.title,
					division: this.user.division,
					reportsTo: this.user.reportsTo,
					groups: this.user.accessLevel.split(","),
					realColor: this.user.realColorResult
				};
				// Sets User Profile Pic
				if (this.profilePic.file !== null)
				{
					let imageUpload = new FormData();
					imageUpload.append('files', this.profilePic.file, this.user.username + '.jpg');
					imageUpload.append('ref', 'user');
					imageUpload.append('refId', this.$route.params.id);
					imageUpload.append('field', 'profilePic');
					imageUpload.append('source', 'users-permissions');
					
					let response = await DatabaseLib.postRequest('strapiDatabase','upload',token,imageUpload);
					
					data.profilePic = response.data;
				}
				
				// Sets Role to Administrator if in Administration Group, else sets role to Authenticated
				if (this.user.accessLevel.includes(this.adminId))
				{
					data.role = this.roleAdmin;
				}
				else
				{
					data.role = this.roleUser;
				}

				await DatabaseLib.putRequest('strapiDatabase','users',token,this.$route.params.id,data);
				
				window.scroll({top: 0, behavior: 'smooth'});
				this.p1 = true;
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p2 = true;
				return;
			}
		},

    //===========================================================================================
	//	Method Name	:	validate
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  Key                     String          in          Holds the valu eof the field being
	//                                                      validated
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Validates the form fields with the custom validation. Checks to see if the fields are blank
	//===========================================================================================
		validate: async function(key) 
		{
			if(key === 'username') {
				if(this.user['username'] == '') {
					this.validation['username'].valid = false;
				} else {
					this.validation['username'].valid = true;
				}
				this.validation['username'].validated = true;
				
			}

			if(key === 'email') {
				if(this.user['email'] == '') {
					this.validation['email'].valid = false;
				} else {
					this.validation['email'].valid = true;
				}
				this.validation['email'].validated = true;
				
			}
			
			if(key === 'firstName' || key === 'lastName' || key === 'dob') {
				if(this.user[key] == '') {
					this.validation[key].valid = false;
					this.validation[key].validated = true;
					return false;
				} else if(this.user[key] != '') {
					this.validation[key].valid = true;
				}
				this.validation[key].validated = true;
			}

			return true;

		},
	},

	mounted()
	{
		this.populateFields();
		this.setRoles();
	}
}

</script>