<template>
  <div>
    <floating-menu
      class="floating-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      v-if="editor"
    >
      <div type="button" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        Add Bullet
      </div>
    </floating-menu>

    <editor-content class="editorStyle" :editor="editor" />
  </div>
</template>

<script>
import {
  Editor,
  EditorContent,
  FloatingMenu,
} from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'

export default {
  components: {
    EditorContent,
    FloatingMenu,
  },

	props: {
	/*
		modelValue: {
			type: String,
			content: Array
		}
	*/
		modelValue: String
	},

  data() {
    return {
      editor: null,
    }
  },
/*
	watch: {
    modelValue(value) {
      // JSON
      const isSame = this.editor.getJSON().toString() === value.toString()
			
      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
*/
  mounted() {
		let input = null;

		if (this.modelValue != null) {
			input = JSON.parse(this.modelValue);
		} else {
			input = '';
		}

    this.editor = new Editor({
      extensions: [
        StarterKit,
				Placeholder.configure({
					placeholder: 'Add Bullet to start adding activity'
				})
      ],
			content: input,
			onUpdate: () => {
        //this.$emit('getContent', this.editor.getJSON());
        this.processActivities(this.editor.getJSON());

      },
    })

		if (input == '') this.editor.chain().focus().toggleBulletList().run();
		
  },

  beforeUnmount() {
    this.editor.destroy()
  },

  methods: {
    //===========================================================================================
		//	Method Name	:	processActivities
		//	
		//  Parameters
		//  Name										Type						  Direction		Description
		//  ----------------------	--------------	  ---------		-------------------------------------
		//  Return Value            [Emit] Data tree  Out         Processes the activities text field and
    //                                                        emits the processed tree data structure
    //
    //  content                 Data Tree         In          Gets the JSON data tree structure from
    //                                                        the text field
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	This looks horrid, I know. The main IF statement checks to see if the data field is empty
    //  or contains bullet points. The subsequent FOR loops loop through all of the branches
    //  in the data tree. The IF statements check to see if there is another tier of branches within
    //  the branch that it is checking. This will check for a total of 3 tiers of branches in the 
    //  tree. If the user tries to add a 4th tier branch, i.e. a 4th sub-bullet list, it will not
    //  allow it.
    //
    //  As a side note, we do not have any control over what this data structure looks like. It 
    //  comes directly from the library used to make the text field. So yes, I know it looks bad
    //  and the naming conventions are awful, but unfortunately we cannot change that.
		//===========================================================================================
    processActivities: function(content) {
      if(typeof content.content[0].content === 'undefined') {
        this.editor.chain().focus().toggleBulletList().run()
      }
      if(content.content[0].content) {
        for(let i = 0; i < content.content[0].content.length; i = i + 1) {  // layer 1
          if(content.content[0].content[i].content.length > 1) {

            if(typeof content.content[0].content[i].content[1].content === 'undefined') {break;}
            for(let j = 0; j < content.content[0].content[i].content[1].content.length; j = j + 1) { // layer 2
              if(typeof content.content[0].content[i].content[0].content === 'undefined') {
                this.editor.chain().focus().liftListItem('listItem').run();
              }

              if(content.content[0].content[i].content[1].content[j].content.length > 1 ) {
                if (typeof content.content[0].content[i].content[1].content[j].content[1].content === 'undefined') {break;}
                for(let k = 0; k < content.content[0].content[i].content[1].content[j].content[1].content.length; k = k + 1) { // layer 3
                  if(typeof content.content[0].content[i].content[1].content[j].content[0].content === 'undefined') {
                     this.editor.chain().focus().liftListItem('listItem').run();
                  }

                  if(content.content[0].content[i].content[1].content[j].content[1].content[k].content.length > 1
                    && typeof content.content[0].content[i].content[1].content[j].content[1].content[k].content.length != 'undefined') {
                    this.editor.chain().focus().liftListItem('listItem').run(); // Disallows for 4th-tier bullet list
                  }
                }
              }
            }
          }
        }
      }
      this.$emit('getContent',content); // Emits the content to the correct method
    }
  }
}
</script>

<style scoped lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

}

.editorStyle {
	color: black;
	border-style: solid;
	border-radius: 4px;
	border-color: rgba(gray, 0.4);
	border-width: 0.1em;
	min-height: 350px;
}

.floating-menu {
  display: flex;
  background-color: #0D0D0D10;
  padding: 0.2rem;
  border-radius: 0.5rem;

  button {
    border: none;
    background: none;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>