<template >
<div class='login' style="display: flex;">
  <mdb-container style="margin: auto auto;">


		<mdb-card class="p-1">
      <mdb-container >
        <mdb-row class="justify-content-center">
        <mdb-col col="10">
        <img src="@/assets/Quadrus Logo color hz.png" class="img-fluid logo" alt="Quadrus Corporation logo">
        </mdb-col>
        </mdb-row>
      </mdb-container>
			<mdb-card-body>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
				Wrong username or password! Please try again.
			</mdb-alert>
			<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
				Your previous session has expired. Please log in again.
			</mdb-alert>
				<form @submit.prevent="login">
					<p class="h4 text-center mb-4">Sign in</p>
					<div class="grey-text" style="text-align: justify">
						<mdb-input v-model="email" label="Your email" icon="user" group type="text"/>
						<mdb-input v-model="password" label="Your password" icon="lock" type="password"/>
					</div>
					<div class="text-center">
						<mdb-btn class='QuadrusButton' type="submit">Login</mdb-btn>
					</div>
				</form>
			</mdb-card-body>
		</mdb-card>

  </mdb-container>
</div>	
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody, mdbCol, mdbRow, mdbContainer, mdbAlert} from 'mdbvue';

export default {
	name: "Login-export",
	components: {
		mdbInput,
		mdbBtn,
		mdbCard,
		mdbCardBody,
		mdbCol,
		mdbRow,
		mdbAlert,
		mdbContainer
	},
	data () {
		return {
			email: '',
			password: '',
			p1: false,
			p2: false
		}
	},


	methods: {
    
    //===========================================================================================
	//	Method Name	:	login
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	----------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Dispatches a login request with the credentials entered on the login page. The dispatch
	//  checks if the credentials are valid, then if good, uses the router to redirect to the
	//  homepage, if not then displays an error
	//===========================================================================================
		login: function () {
			let email = this.email
			let password = this.password
			this.$store.dispatch('login', { email, password })
			.then(() => {this.$router.push('/'); this.$router.go(0);}) // this solution sucks. Figure out a better one
			.catch(error => {this.p1 = true; console.log(error);});
		}
	}
}
</script>

<style scoped>
.logo {
	padding-top: 20px;
	padding-bottom: 20px;
}
.login {
	background-color: #1b365d;
}



</style>