<template>
  <div id="app">

    <Navside v-if="$route.name !=='Login'&& $route.name !=='ResetPassword' && $route.name !== 'ForgotPassword'"/>
    <router-view style="min-height: 100vh"/>
    <Footer v-if="$route.name !=='Login' && $route.name !== 'ResetPassword' && $route.name !== 'ForgotPassword'"/>
  </div>

</template>

<script>
  import Navside from "@/components/Navside.vue";
  import Footer from "@/components/Footer.vue";

  export default {
    components: {
      Navside,
      // eslint-disable-next-line
      Footer,
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

$image-path: '~@/../mdb/mdbvue/img';
@import '~@/../mdb/mdbvue/scss/mdb-pro.scss';

#app 
{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav 
{
  padding: 30px;

  a 
  {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active 
    {
      color: #42b983;
    }
  }
}

html,body
{
    overflow-x: hidden; 
}

.page-start 
{
  padding-top: 6em;
  background-color: #1b365d;
}

.QuadrusButton 
{
	background-color: #407ec9 !important;
  color: white;
}

/* -TipTap Text Editor CSS- */
.ProseMirror 
{
	min-height: 350px;
}

.ProseMirror p.is-editor-empty:first-child::before 
{
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 20px;
}

.ProseMirror:focus 
{
	outline: none;
}
/* ------------------------ */

/* ----Form CSS Classes---- */
.invalidForm 
{
  box-shadow: 0 1px 0 red;
}

.validForm 
{
  box-shadow: 0 1px 0 green;
}

.disabledForm 
{
  background-color: rgb(228, 228, 228);

  .form-control:disabled, .form-control[readonly] 
  {
		background-color: rgb(228, 228, 228) !important;
  }
}
/* ------------------------- */

</style>
