<template>
  <div class='page-start'>
    <mdb-container class="pb-3" fluid>

      <mdb-card class="mb-3 d-flex">
      <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Quadrus Corporation Support Tickets</h3>
      <mdb-card-body>
        <mdb-input class="mb-0 mt-2" type="checkbox" id="showClosed" name="showClosed" @change="populateQPRData" label="Show Closed QPRs"/>
        <QDataTable id='qprTable' :rowData="this.qprData.rowData" :filterWords="this.qprData.filterWords" :headers="this.qprData.columns" 
        :btnName="'Preview'" :hasBtn="true" @use-method='previewQPR'>
        </QDataTable>
      </mdb-card-body>
      </mdb-card>

      <div ref="preview" v-show="previewing">
      <mdb-card  class="mb-3 d-flex">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">QPR Preview</h3>
        <mdb-card-body>
          <mdb-row>
            <mdb-col col="1">
              <mdb-input class="mb-3" v-model="qprNumber" label="QPR Number" disabled/>
            </mdb-col>
            <mdb-col col="9">
              <mdb-input class="mb-3" v-model="title" label="Title" disabled/>
            </mdb-col>
            <mdb-col col="2">
              <mdb-input class="mb-3" v-model="author" label="Author" disabled/>
            </mdb-col>
          </mdb-row>
          <br>
          <mdb-row>
            <mdb-col>
              <mdb-input class="mb-3" v-model="foundIn" label="Found In" disabled/>
            </mdb-col>
            <mdb-col>
              <mdb-input class="mb-3" v-model="softwareVersion" label="Software Version" disabled />
            </mdb-col>
            <mdb-col>
              <mdb-input class="mb-3" v-model="foundDuring" label="Found During" disabled/>
            </mdb-col>
            <mdb-col>
              <mdb-input class="mb-3" v-model="constraintOn" label="Constraint On" disabled/>
            </mdb-col>
          </mdb-row>
          <br>
          <mdb-row>
            <mdb-col>
              <mdb-input class="mb-3" v-model="currentFunction" label="Current Functionality" disabled />
            </mdb-col>
          </mdb-row>
          <br>
          <mdb-row>
            <mdb-col>
              <mdb-input class="mb-3" v-model="expectedFunction" label="Expected Functionality" disabled />
            </mdb-col>
          </mdb-row>
        </mdb-card-body>
      </mdb-card>
      </div>

    </mdb-container>
  </div>
</template>
<script>
import { mdbInput, mdbCard, mdbCardBody, mdbContainer, mdbRow, mdbCol } from 'mdbvue';
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
	name: 'IT-Tickets',
	components: {
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbContainer,
    QDataTable,
    mdbRow,
    mdbCol
  },

  data() {
    return {
      showClosed: false,
      previewing: false,

      qprNumber: '',
      title: '',
      author: '',
      foundIn: '',
      softwareVersion: '',
      foundDuring: '',
      constraintOn: '',
      currentFunction: '',
      expectedFunction: '',

      qprData: {
        columns: [  
          {
            colTitle: 'QPR Number',
            sortBy: 'id'
          },
          {
            colTitle: 'Title',
            sortBy: 'title'
          },
          {
            colTitle: 'Found In',
            sortBy: 'foundIn'
          },
          {
            colTitle: 'Software Version',
            sortBy: 'softwareVersion'
          },
          {
            colTitle: 'Found During',
            sortBy: 'foundDuring'
          },
          {
            colTitle: 'Constraint On',
            sortBy: 'constraintOn'
          }
        ],
        rowData: [],
        filterWords: [
          'id',
          'title',
          'foundIn',
          'softwareVersion',
          'foundDuring',
          'constraintOn'
        ]
      }

    }
  },
  methods: {

    resetQPRTable: function() {
      this.qprData.rowData.splice(0);
    },

    populateQPRData: async function() {
      let token = this.$store.getters.getToken;
      var query = '';
      this.resetQPRTable();

      this.showClosed = document.getElementById('showClosed').checked;
      try {
        if(this.showClosed) {
          query = "filters[closed][$eq][0]="+true;
        } else {
          query = "filters[closed][$eq][0]="+false;
        }
        let response = '';
        response = await DatabaseLib.getRequest('strapiDatabase','submitted-qpr',token,query,false);
        for(let i = 0; i < response.data.data.length; i = i + 1) {
          this.qprData.rowData.push({
            id: response.data.data[i].id,
            title: response.data.data[i].attributes.title,
            author: response.data.data[i].attributes.author,
            foundIn: response.data.data[i].attributes.foundIn,
            softwareVersion: response.data.data[i].attributes.softwareVersion,
            foundDuring: response.data.data[i].attributes.foundDuring,
            constraintOn: response.data.data[i].attributes.constraintOn,
            currentFunction: response.data.data[i].attributes.currentFunction,
            expectedFunction: response.data.data[i].attributes.expectedFunction
          }) 
        }
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        return;
      }
    },

    previewQPR: function(data) {
      this.qprNumber = data.id;
      this.title = data.title;
      this.author = data.author;
      this.author = data.author;
      this.foundIn = data.foundIn;
      this.softwareVersion = data.softwareVersion;
      this.foundDuring = data.foundDuring;
      this.constraintOn = data.constraintOn;
      this.currentFunction = data.currentFunction;
      this.expectedFunction = data.expectedFunction;

      this.previewing = true;
      this.$nextTick(function() {
        this.$refs.preview.scrollIntoView({behavior: 'smooth'})
      })
    }
  },

  mounted() {
    this.populateQPRData();
  }
}
</script>
