<template>
	<div class='page-start'>
		<mdb-row class='justify-content-center'>
			<mdb-col col='10'>
			<mdb-card>
				<h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">create new account</h3>
				<mdb-card-body>

				<!-- Submission Alerts -->
				<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p10" @closeAlert="p10=false" dismiss>
					Success! User created! Go to View/Edit Users Page? (Click "No" to create New User)
					<router-link :to="'/View-Edit-User-Page'"><mdbBtn color="primary">Yes</mdbBtn></router-link>
					<mdbBtn color="danger" v-on:click="resetForm">No</mdbBtn>
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p11" @closeAlert="p11=false" dismiss>
					There was an error creating user! Please try again! Error Code P11
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p12" @closeAlert="p12=false" dismiss>
					Invalid Inputs! Please check them and try again! Error Code P12
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p13" @closeAlert="p13=false" dismiss>
					There was an error fetching information from the database! Error Code P13
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p14" @closeAlert="p14=false" dismiss>
					There was an error fetching user data. Please try again! Error Code P14
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p15" @closeAlert="p15=false" dismiss>
					There was an error fetching user data. Please try again! Error Code P15
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p16" @closeAlert="p16=false" dismiss>
					There was an error fetching drect repot data. Please try again! Error Code P16
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p17" @closeAlert="p17=false" dismiss>
					There was an error fetching user access data. Please try again! Error Code P17
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p18" @closeAlert="p18=false" dismiss>
					The chosen profile picture is too large, maximum file size is 1MB! Error Code P18
				</mdb-alert>
				<!-- /Submission Alerts -->

					<form @submit.prevent="checkForm" novalidate>
						<div class= 'grey-text text-left'>
							<mdb-container>
								<h3 class="h3-responsive">Account Information</h3>
								<mdb-input sm label='Username' id="usernameInput" v-model="validationValues.username" type='text' required 
									:customValidation="validation.username.validated"
									:isValid="validation.username.valid"
									@change="validate('username')"
									invalidFeedback="Username Invalid or Already in Use" validFeedback="Looks Good!"/>
								<mdb-input sm label='E-mail' id="emailInput" v-model="validationValues.email" type='email' required 
									:customValidation="validation.email.validated"
									:isValid="validation.email.valid"
									@change="validate('email')"
									invalidFeedback="Email Invalid or Already in Use" validFeedback="Looks Good!"/>
								<mdb-input sm label='Password' id="password" v-model="validationValues.password" type='password' required 
									:customValidation="validation.password.validated"
									:isValid="validation.password.valid"
									@change="validate('password')"
									invalidFeedback="Passwords must match" validFeedback="Looks Good!"/>
								<a class='' style="color: #ff4444" v-if="!passwordLength"><mdb-icon icon="exclamation-circle" />At least 10 Characters Long</a>
								<a class='' style="color: #00C851" v-if="passwordLength"><mdb-icon icon="check-circle" />At least 10 Characters Long</a>
								<br>
								<a class='' style="color: #ff4444" v-if="!passwordSpecial"><mdb-icon icon="exclamation-circle" />At least 1 Special Character, i.e. !, @, #, $, etc.</a>
								<a class='' style="color: #00C851" v-if="passwordSpecial"><mdb-icon icon="check-circle" />At least 1 Special Character, i.e. !, @, #, $, etc.</a>
								<br>
								<a class='' style="color: #ff4444" v-if="!passwordUpper"><mdb-icon icon="exclamation-circle" />At least 1 Uppercase Character</a>
								<a class='' style="color: #00C851" v-if="passwordUpper"><mdb-icon icon="check-circle" />At least 1 Uppercase Character</a>
								<br>
								<a class='' style="color: #ff4444" v-if="!passwordLower"><mdb-icon icon="exclamation-circle" />At least 1 Lowercase Character</a>
								<a class='' style="color: #00C851" v-if="passwordLower"><mdb-icon icon="check-circle" />At least 1 Lowercase Character</a>
								<br>
								<a class='' style="color: #ff4444" v-if="!passwordDigit"><mdb-icon icon="exclamation-circle" />At least 1 Numeric Character</a>
								<a class='' style="color: #00C851" v-if="passwordDigit"><mdb-icon icon="check-circle" />At least 1 Numeric Character</a>

								<mdb-input sm label='Verify Password' id="confirmPassword" v-model="validationValues.confirmPassword" type='password' required 
									:customValidation="validation.confirmPassword.validated"
									:isValid="validation.confirmPassword.valid"
									@change="validate('confirmPassword')"
									invalidFeedback="Passwords must match" validFeedback="Looks Good!"/>
							</mdb-container>
							<br><hr><br>
							<mdb-container>
								<h3 class="h3-responsive">Personal Information</h3>
								<mdb-input sm label='First Name' id="fnameInput" v-model="validationValues.fname" group type='text' required 
									:customValidation="validation.fname.validated" 
									:isValid="validation.fname.valid"
									@change="validate('fname')"
									invalidFeedback="Required Field" validFeedback="Looks Good!"/>
								<mdb-input sm label='Last Name' id="lnameInput" v-model="validationValues.lname" group type='text' required 
									:customValidation="validation.lname.validated" 
									:isValid="validation.lname.valid"
									@change="validate('lname')"
									invalidFeedback="Required Field" validFeedback="Looks Good!"/>
								<mdb-date-picker-2 v-model="validationValues.dob" label= 'Date of Birth' id="dobInput" title= 'Select Date' disabledFuture required
									:customValidation="validation.dob.validated" 
									:isValid="validation.dob.valid"
									@change="validate('dob')"
									invalidFeedback="Required Field" validFeedback="Looks Good!"/>

								<!--
								<mdb-input sm label='Personal E-mail' v-model="validationValues.personalEmail" group type='email' validFeedback="Optional"/>
								<mdb-input sm label='Personal Phone #' v-model="validationValues.personalPhone" group type='phone' validFeedback="Optional"/>
								<mdb-input sm label='Home Phone #' v-model="validationValues.homePhone" group type='phone' validFeedback="Optional"/>
								<br>
								<mdb-select v-model='countryOptions' @getValue='setCountry' label='Country' placeholder='Choose your Country' validFeedback="Optional"/>
								<mdb-input sm v-model="validationValues.street" label='Street' group type='text' validFeedback="Optional"/>
								<mdb-input sm v-model="validationValues.city" label='City' group type='text' validFeedback="Optional"/>
								<mdb-select v-model='stateOptions' @getValue='setState' label='State' placeholder='If in the US choose State' validFeedback="Optional"/>
								<mdb-input sm v-model="validationValues.zip" label='Zip Code' group type='number' validFeedback="Optional"/>
							-->
							</mdb-container>
							<br><hr><br>
							<mdb-container>
								<h3 class="h3-responsive">Professional Information</h3>
								<mdb-input sm v-model="validationValues.title" label='Title' group type='text' validFeedback="Optional"/>
								<mdb-input sm v-model="validationValues.division" label='Division' group type='text' validFeedback="Optional"/>
								<mdb-select v-model="reportOptions" @getValue="setDirectReport" label="Direct Report"/>							
								<mdb-input sm label='Office Phone #' v-model="validationValues.workPhone" group type='phone' validFeedback="Optional"/>
								<mdb-input sm label='Work Cell Phone #' v-model="validationValues.workCellPhone" group type='phone' validFeedback="Optional"/>

								<br>
								<div>
									<h3 class="h3-responsive">User Access</h3>
									<h5 class="h5-responsive"><u>NOTE:</u> Everyone has a default "Public" role that allows then to access basic functions. Only add below roles if more advanced functions are needed. </h5>

									<mdb-select multiple :options="accessLevels" @getValue="setUserAccessLevel" label="User Access"/>
									<!--<mdb-input type="radio" id="access-1" name="UserAccessGroup" radioValue="CUS" v-model="validationValues.userAccess" label="CUS: Customer, Consultant" required/>
									<mdb-input type="radio" id="access-2" name="UserAccessGroup" radioValue="SRI" v-model="validationValues.userAccess" label="SRI:  Specialist, Associate, Assistant, Representative, Intern" required />
									<mdb-input type="radio" id="access-3" name="UserAccessGroup" radioValue="MGR" v-model="validationValues.userAccess" label="MGR: Manager, Supervisor" required />
									<mdb-input type="radio" id="access-4" name="UserAccessGroup" radioValue="DIR" v-model="validationValues.userAccess" label="DIR: Director" required />
									<mdb-input type="radio" id="access-5" name="UserAccessGroup" radioValue="ENG" v-model="validationValues.userAccess" label="ENG: Engineer, Developer (RE)" required />
									<mdb-input type="radio" id="access-6" name="UserAccessGroup" radioValue="QCM" v-model="validationValues.userAccess" label="QCM: Configuration Manager" required />
									<mdb-input type="radio" id="access-7" name="UserAccessGroup" radioValue="QPM" v-model="validationValues.userAccess" label="QPM: Project Manager" required />
									<mdb-input type="radio" id="access-8" name="UserAccessGroup" radioValue="QEM" v-model="validationValues.userAccess" label="QEM: Executive Management" required /> -->

								</div>


							</mdb-container>
							<br><hr><br>
							<!--
							<mdb-container>
								<h3 class="h3-responsive">Emergency Contact Information</h3>
								<br>
								<h5 class="h5-responsive">Contact One</h5>
								<mdb-input sm label='Emergency Contact Name' v-model="validationValues.emerNameOne" group type='text' validFeedback="Optional"/>
								<mdb-input sm label='Emergency Contact Number' v-model="validationValues.emerPhoneOne" group type='phone' validFeedback="Optional"/>
								<br>
								<h5 class="h5-responsive">Contact Two</h5>
								<mdb-input sm label='Emergency Contact Name' v-model="validationValues.emerNameTwo" group type='text' validFeedback="Optional"/>
								<mdb-input sm label='Emergency Contact Number' v-model="validationValues.emerPhoneTwo" group type='phone' validFeedback="Optional"/>
								<br>
								<h5 class="h5-responsive">Contact Three</h5>
								<mdb-input sm label='Emergency Contact Name' v-model="validationValues.emerNameThree" group type='text' validFeedback="Optional"/>
								<mdb-input sm label='Emergency Contact Number' v-model="validationValues.emerPhoneThree" group type='phone' validFeedback="Optional"/>
							</mdb-container>
							<br><hr><br>
							-->
							<mdb-container>
								<h3 class="h3-responsive">Other</h3>
								<mdb-file-input @getValue="getProfilePic" sm btn-title='ADD PHOTO' btnColor='blue'/>
								<mdb-input sm label='Real Colors Result' v-model="validationValues.realColorResult" group type='text' />
							</mdb-container>
						</div>
						<br>
						<!--<mdb-btn block type="submit" class='pl-3 pr-3 QuadrusButton'>Submit New User</mdb-btn>-->
						<mdb-btn size="block" class="mt-4" color="primary" type="submit" v-if="!submitProcessing">Submit New User</mdb-btn>
            <mdb-btn size="block" class="mt-4" color="primary" v-if="submitProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Processing...
            </mdb-btn>
					</form>
				</mdb-card-body>
			</mdb-card>
			</mdb-col>
		</mdb-row>
	</div>
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody, mdbCol, mdbRow, mdbDatePicker2, mdbFileInput, mdbContainer, mdbSelect, mdbAlert, mdbIcon} from 'mdbvue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
	name: 'CreateNewUser',
	components: {
		mdbInput,
		mdbBtn,
		mdbCard,
		mdbCardBody,
		mdbCol,
		mdbRow,
		mdbDatePicker2,
		mdbFileInput,
		mdbContainer,
		mdbSelect,
		mdbAlert,
		mdbIcon,
	},

	data() {
		return {
		
		p10: false,
		p11: false,
		p12: false,
		p13: false,
		p14: false,
		p15: false,
		p16: false,
		p17: false,
		p18: false,

		passwordLength: false,
		passwordSpecial: false,
		passwordUpper: false,
		passwordLower: false,
		passwordDigit: false,
		submitProcessing: false,

		validationValues: {
			username: "",
			password: "",
			confirmPassword: "",
			fname: "",
			lname: "",
			dob: "",
			/*
			personalEmail: "",
			personalPhone: "",
			homePhone: "",
			workPhone: "",
			workCellPhone: "",
			/*
			email: "",
			street: "",
			city: "",
			zip: "",
			*/
			title: "",
			division: "",
			reportsTo: "",
			userAccess: [],
			/*
			state: "",
			country: "",

			emerNameOne: "",
			emerNameTwo: "",
			emerNameThree: "",

			emerPhoneOne: "",
			emerPhoneTwo: "",
			emerPhoneThree: "",
			*/

			realColorResult: "",
		},

    validation: {
			username: {
				valid: false,
				validated: false
			},
			email: {
				valid: false,
				validated: false
			},
			password: {
				valid: false,
				validated: false
			},
			confirmPassword: {
				valid: false,
				validated: false
			},
			fname: {
				valid: false,
				validated: false
			},
			lname: {
				valid: false,
				validated: false
			},
			dob: {
				valid: false,
				validated: false
			}
		},

		reportOptions: [],
		accessLevels: [],

		profilePic: { file: null },

		adminId: '',

		roleAdmin: '',
		}
  },

	methods: {
		checkForm: async function() {
			window.scroll({top: 0, behavior: 'smooth'});

			let token = this.$store.getters.getToken;

			await Object.keys(this.validationValues).forEach(key => {
				this.validationValues[key] = this.sanitizeInput(this.validationValues[key]);
			})

			let isValid = true;
			await Object.keys(this.validationValues).forEach(async key => {
				if(!await this.validate(key)) {
					isValid = false;

					window.scroll({top: 0, behavior: 'smooth'});
					this.p12 = true;
					return;
				}
			})

			let query = "filters[username][$eq][0]="+this.validationValues['username'];
			let response =  '';

			try {
				response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p14 = true;
				return;
			}

			if (response.data.length != 0 || !this.validationValues['username']) {
				this.validation['username'].valid = false;
				isValid = false;
			} else if(response.data.length == 0 && this.validationValues['username']) {
				this.validation['username'].valid = true;
			}
			this.validation['username'].validated = true;

			query = "filters[email][$eq][0]="+this.validationValues['email'];
			response =  '';
			try {
				response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p15 = true;
				return;
			}

			if (response.data.length != 0 || !this.validationValues['email']) {
				this.validation['email'].valid = false;
				isValid = false;
			} else if(response.data.length == 0 && this.validationValues['email']) {
				this.validation['email'].valid = true;
			}
			this.validation['email'].validated = true;

			if(isValid) {
				this.submitProcessing = true;
				this.submittedAddUser();
			}
		},

		//===========================================================================================
		//	Method Name	:	resetForm
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value			void			n/a			n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Refreshes page to reset fields and CSS.
		//===========================================================================================
		resetForm: function() 
		{
			this.$router.go(0);
		},

		validate: async function(key) {

			if(key === 'username') {
				if(this.validationValues['username'] == '') {
					this.validation['username'].valid = false;
				} else {
					this.validation['username'].valid = true;
				}
				this.validation['username'].validated = true;
				
			}

			if(key === 'email') {
				if(this.validationValues['email'] == '') {
					this.validation['email'].valid = false;
				} else {
					this.validation['email'].valid = true;
				}
				this.validation['email'].validated = true;
				
			}


			if(key === 'password' || key === 'confirmPassword') {
				this.checkPassword(this.validationValues['password']);

				if (this.validationValues['password'] != this.validationValues['confirmPassword'] || (this.validationValues['password'] == '' && this.validationValues['confirmPassword'] == '') || !this.passwordLength || !this.passwordSpecial || !this.passwordUpper || !this.passwordLower || !this.passwordDigit) {
					this.validation['password'].valid = false;
					this.validation['confirmPassword'].valid = false;

					this.validation['password'].validated = true;
					this.validation['confirmPassword'].validated = true

					return false;

				} else if(this.validationValues['password'] ==  this.validationValues['confirmPassword'] && this.validationValues['password'] != '' &&  this.validationValues['confirmPassword'] != '' && this.passwordLength && this.passwordSpecial && this.passwordUpper && this.passwordLower && this.passwordDigit) {
					this.validation['password'].valid = true;
					this.validation['confirmPassword'].valid = true;
					this.valid = true;
				}
				this.validation['password'].validated = true;
				this.validation['confirmPassword'].validated = true
			}

			if(key === 'fname' || key === 'lname' || key === 'dob') {
				if(this.validationValues[key] == '') {
					this.validation[key].valid = false;
					this.validation[key].validated = true;
					return false;
				} else if(this.validationValues[key] != '') {
					this.validation[key].valid = true;
				}
				this.validation[key].validated = true;
			}

			return true;

		},

		checkPassword: function(value) {
			let regex = '';
			regex = new RegExp('^\\S{10,30}$');

			if(regex.test(value)) {
				this.passwordLength = true;
			} else {
				this.passwordLength = false;
			}

			regex = new RegExp('[\\!@#\\$%\\^\\&\\*\\(\\)\\-\\_\\=\\+\\|;:\'",.\\/\\?`~]+');

			if(regex.test(value)) {
				this.passwordSpecial = true;
			} else {
				this.passwordSpecial = false;
			}

			regex = new RegExp('[A-Z]+');

			if(regex.test(value)) {
				this.passwordUpper = true;
			} else {
				this.passwordUpper = false;
			}

			regex = new RegExp('[a-z]+');

			if(regex.test(value)) {
				this.passwordLower = true;
			} else {
				this.passwordLower = false;
			}

			regex = new RegExp('[0-9]+');
			if(regex.test(value)) {
				this.passwordDigit = true;
			} else {
				this.passwordDigit = false;
			}
			let pass = document.getElementById('password');
			let confpass = document.getElementById('confirmPassword');
			if(pass == confpass){
				this.validation.password.valid = true;
			}
		},

		submittedAddUser: async function() {
			let token = this.$store.getters.getToken;
			
			try {
				let data = {
					blocked: false,
					confirmed: true,
					username: this.validationValues.username,
					email: this.validationValues.email,
					password: this.validationValues.password,
					provider: 'local',
					firstName: this.validationValues.fname,
					lastName: this.validationValues.lname,
					dob: this.validationValues.dob,
					/*
					personalEmail: this.validationValues.personalEmail,
					personalPhone: this.validationValues.personalPhone,
					homePhone: this.validationValues.homePhone,
					*/
					workPhone: this.validationValues.workPhone,
					workCellPhone: this.validationValues.workCellPhone,
					/*
					country: this.validationValues.country,
					street: this.validationValues.street,
					city:  this.validationValues.city,
					state: this.validationValues.state,
					zip: this.validationValues.zip,
					*/
					title: this.validationValues.title,
					division: this.validationValues.division,
					reportsTo: this.validationValues.reportsTo,
					/*
					emerNameOne: this.validationValues.emerNameOne,
					emerNameTwo: this.validationValues.emerNameTwo,
					emerNameThree: this.validationValues.emerNameThree,
					emerPhoneOne: this.validationValues.emerPhoneOne,
					emerPhoneTwo: this.validationValues.emerPhoneTwo,
					emerPhoneThree: this.validationValues.emerPhoneThree,
					*/
					groups: this.validationValues.userAccess.split(","),
					realColor: this.validationValues.realColorResult
				};

				
				await DatabaseLib.postRequest('strapiDatabase','auth/local/register',token,data);

				// Sets User Profile Pic
				if (this.profilePic.file !== null)
				{
					let query = "filters[username][$eq][0]=" + this.validationValues.username + "&populate[1]=profilePic";
				
					// Gets New User's ID
					let response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
					let userId = response.data[0].id;
					
					let imageUpload = new FormData();
					imageUpload.append('files', this.profilePic.file, this.validationValues.username + '.jpg');
					imageUpload.append('ref', 'user');
					imageUpload.append('refId', userId);
					imageUpload.append('field', 'profilePic');
					imageUpload.append('source', 'users-permissions');
						
					response = await DatabaseLib.postRequest('strapiDatabase','upload',token,imageUpload);
					data = {
						profilePic: response.data
					}
					response = await DatabaseLib.putRequest('strapiDatabase','users',token,userId,data);
				}
				else
				{
					let query = "filters[username][$eq][0]=" + this.validationValues.username;

					// Gets New User's ID
					let response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
					let userId = response.data[0].id;

					let defaultPic = {
						profilePic: "1"
					};

					await DatabaseLib.putRequest('strapiDatabase','users',token,userId,defaultPic);
				}

				// Sets Role to Administrator if in Administration Group, else sets role to Authenticated
				if (this.validationValues.userAccess.includes(this.adminId))
				{
					let roleData = 
					{
						role: this.roleAdmin
					};

					let query = "filters[username][$eq][0]=" + this.validationValues.username;

					// Gets New User's ID
					let response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);

					let userId = response.data[0].id;
					
					// Updates Role to Administrator
					await DatabaseLib.putRequest('strapiDatabase','users',token,userId,roleData);
				}
				

				window.scroll({top: 0, behavior: 'smooth'});
				this.p10 = true;

			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p11 = true;
				this.submitProcessing = false;
				return;

			}
		},

		// value[0].size = image size in bytes (5244276 = 5MB)
		// max size is 1MB (1,048,855)

		getProfilePic: function(value) {
			if (value[0].size < 1048855) {
				this.profilePic.file = value[0];
			}
			else {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p18 = true;
			}
		},

		sanitizeInput(content) {
			this.submitProcessing = false;
			return this.$sanitize(content)
		},

		setUserAccessLevel: async function(value) {
			this.validationValues.userAccess = value;
		},

		setRoles: async function()
		{
			try 
			{
				let token = this.$store.getters.getToken;
				let response = await DatabaseLib.getRequest('strapiDatabase','users-permissions/roles',token, '', false);
				response.data.roles.forEach(role => {
					if (role.name === 'Administrator')
					{
						this.roleAdmin = role.id;
					}
				});
			}
			catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p13 = true;
				this.submitProcessing = false;
				return;		
			}
		},

		populateUserAccess: async function() {
			let token = this.$store.getters.getToken;

			let groups = '';
			
			try {
				groups = await DatabaseLib.getRequest('strapiDatabase','groups',token);
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p16 = true;
				this.submitProcessing = false;
				return;
			}
			
			for(let i = 0; i < groups.data.data.length; i = i + 1) {
				//Gets Group ID in case role needs to be changed to Administrator
				if (groups.data.data[i].attributes.name === 'Administration')
				{
					this.adminId = groups.data.data[i].id;
				}

				let obj = {};
				obj["text"] = groups.data.data[i].attributes.name;
				obj["value"] = groups.data.data[i].id;
				this.accessLevels.push(obj);
			}
		},

		populateDirectReports: async function() {
			let token = this.$store.getters.getToken;
			let query = "filters[blocked][$eq][0]="+false+"&populate[1]=groups&populate[2]=role";
			
				//Default Option
			let none = {};
			none["text"] = "None";
			none["value"] = "None";
			none["selected"] = true;
			this.reportOptions.push(none);

			let response = '';
			try {
				response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p17 = true;
				this.submitProcessing = false;
				return;
			}
			let sup = [];
			for(let c = 0; c < response.data.length; c = c + 1){
				if(response.data[c].role.name != "Administrator"){
					for(let i = 0; i < response.data[c].groups.length; i = i + 1) {
						if(response.data[c].groups[i].name == "Supervisor"){
							sup.push(response.data[c]);
						}
					}
				}
			}


			sup.forEach(supervisor => {
				let obj = {};
				obj["text"] = supervisor.firstName + ' ' + supervisor.lastName;
				obj["value"] = supervisor.username;
				this.reportOptions.push(obj);
			});
			this.submitProcessing = false;
		},

		setDirectReport: function(value) 
		{
			this.validationValues.reportsTo = value;
		},

		setCountry: function(value) {
			this.validationValues.country = value;
		},

		setState: function(value) {
			this.validationValues.state = value;
		}

	},
	mounted() {
		this.populateUserAccess();
		this.setRoles();
		this.populateDirectReports();
	},

}

</script>