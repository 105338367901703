<template>
  <div class="page-start">

    <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
				There was an error creating login timestamp datasheet! Please try again!
		</mdb-alert>
    <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
      There was an error creating WAR timestamp datasheet! Please try again!
    </mdb-alert>
    <mdb-alert id="feedback-failure" class="mt-3" color="success" v-if="p3" @closeAlert="p3=false" dismiss>
      Success! Check browser Downloads!
    </mdb-alert>

    <mdb-card class="mb-3 d-flex">
      <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Direct Reports</h3>
      <mdb-card-body>
        <mdb-datatable-2 v-model="directReportTable" multiselectable @selected="directReportSelected = $event" />


      <h3 class="text-center font-weight-bold text-uppercase py-4">Generate Timestamp Reports</h3>

        <!--<mdb-btn class='QuadrusButton' v-on:click="timestampLoginReport">Login Timestamp Report</mdb-btn>-->
        <download-csv :data="directReportSelected" name="Login-Timestamp-Report.csv" :labels="loginLabels" :class='directReportSelected.length == 0 ? "disabled btn btn-primary QuadrusButton" : "btn btn-primary QuadrusButton"' @export-finished="exportFinished" :disabled="directReportSelected.length == 0">Login Timestamp Report</download-csv>
        <download-csv :data="WARData" name="WAR-Timestamp-Report.csv" :labels="WARLabels" :class='directReportSelected.length == 0 || WARData.length == 0 ? "disabled btn btn-primary QuadrusButton" : "btn btn-primary QuadrusButton"' ref="WARBtn" @export-finished="exportFinished" :disabled="directReportSelected.length == 0 || WARData.length == 0">Saved WAR Timestamp Report</download-csv>
        <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="directReportSelected.length == 0">
          No Direct Reports Selected!
        </mdb-alert>
        <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="WARData.length == 0">
          No saved WAR reports for selected direct reports!
        </mdb-alert>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>
<script>
import Vue from 'vue';
import { mdbCard, mdbCardBody, mdbDatatable2, mdbAlert } from 'mdbvue';
//import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
import * as JsonCSV from 'vue-json-csv';

Vue.component('downloadCsv', JsonCSV)

export default {
  name: 'TimestampReport',
  components: {
    mdbCard,
    mdbCardBody,
    mdbDatatable2,
    mdbAlert
  },
  data() {
    return {

      p1: false,
      p2: false,
      p3: false,
      p4: true,
      p5: true,

      WARData: [],
      WARDataFields: {},

      directReportSelected: [],
      directReportTable: {
        columns: [
        {
          label: 'Name',
          field: 'name',
          sort: 'true'
        },
        {
          label: 'Title',
          field: 'title',
          sort: 'false'
        },
        {
          label: 'Division',
          field: 'division',
          sort: 'true'
        }
        ],
        rows: []
      },
      loginLabels: {
        username: "Username" ,
        name: "Name",
        title: "Title" ,
        division: "Division" ,
        lastLoginDate: "Last Login Date" ,
        lastLoginTime: "Last Login Time" 
      },
      WARLabels: {
        username: "Username" ,
        name: "Name",
        title: "Title" ,
        division: "Division" ,
        project: "Project",
        task: "Task",
        lastSaveDate: "Last Save Date",
        lastSaveTime: "Last Save Time"

      }
    }
  },
  methods: { 

    populateDirectReports: async function() {

      let token = this.$store.getters.getToken;
      let query = 'filters[blocked][$eq][0]=' + false+'&filters[reportsTo][$eq][2]='+ this.$store.getters.getUser;
      
      try {
        let response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
        let timeDateTempArr = [];
        let tempDate = '';
        for(let i = 0; i < response.data.length; i = i + 1) {
            if(response.data[i].lastLogin != null){
              tempDate = String(new Date(response.data[i].lastLogin.replace(/-/g, '/')));
              timeDateTempArr = tempDate.split(" ");
            }
            this.directReportTable.rows.push(
              {
                username: response.data[i].username,
                name: response.data[i].firstName + " " + response.data[i].lastName,
                title: response.data[i].title,
                division: response.data[i].division,
                lastLoginDate: timeDateTempArr[0] + " " + timeDateTempArr[1] + " " + timeDateTempArr[2],
                lastLoginTime: timeDateTempArr[4]
              }
            )
        }
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
				this.p1 = true;
				return;
      }
    },
  
    timestampWARReport: async function() {
    
      let token = this.$store.getters.getToken;
      let WARData = [];
      this.p5 = false;
      
      if(this.directReportSelected.length != 0) {

        try {
          for(let i = 0; i < this.directReportSelected.length; i = i + 1) {
            let query = "filters[username][$eq][0]="+this.directReportSelected[i].username+"&filters[submitted][$eq][1]="+false;
            let response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
            
            if(response.data.data.length == 0 && this.p4 != true) {
              window.scroll({top: 0, behavior: 'smooth'});
              this.p5 = true;
            } else {
              this.p5 = false;
              for(let j = 0; j < response.data.data.length; j = j + 1) {

                let WARRecord = {
                  username: '',
                  name: '',
                  title: '',
                  division: '',
                  project: '',
                  task: '',
                  lastSaveDate: '',
                  lastSaveTime: ''
                }

                WARRecord.name = this.directReportSelected[i].name;
                WARRecord.title = this.directReportSelected[i].title;
                WARRecord.division = this.directReportSelected[i].division;
                WARRecord.username = this.directReportSelected[i].username;

                WARRecord.project = response.data.data[j].attributes.projectTask;
                WARRecord.task = response.data.data[j].attributes.lineItem;

                let tempDate = String(new Date(response.data.data[j].attributes.lastSaved.replace(/-/g, '/')));
                let timeDateTempArr = tempDate.split(" ");

                WARRecord.lastSaveDate = timeDateTempArr[0] + " " + timeDateTempArr[1] + " " + timeDateTempArr[2];
                WARRecord.lastSaveTime = timeDateTempArr[4];

                WARData.push(WARRecord);
              }
            }
          }

        } catch(error) {
          window.scroll({top: 0, behavior: 'smooth'});
          this.p2 = true;
          console.log(error);
          return;
        }

        this.WARData = WARData;
      }
    },

    exportFinished: function() {
      this.p3 = true;
    }

  },
  watch: {
    directReportSelected: {
      handler() {
        this.timestampWARReport();

        if(this.directReportSelected.length == 0) {
          this.p4 = true;
        } else {
          this.p4 = false;
        }
      }
    } 
  },

  mounted() {
    this.populateDirectReports();
  }
}

</script>