<template>

<div class="page-start">
    <mdb-row class="justify-content-center" >
        <mdb-col class="pt-3" col="10" style="background-color: white;">
            <h2 class="h2-responsive">Filter Options for viewing Monthly Reports</h2>
            <hr>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
					There was an error retrieving information from the server! Please try again later.
				</mdb-alert>
        </mdb-col>
				
    </mdb-row>
		<div v-if="this.$store.getters.getGroups.includes('Administration') || this.$store.getters.getGroups.includes('Supervisor')">
			<mdb-row class="justify-content-center">
					<mdb-col class="pt-3" col="3" style="background-color: white; text-align: left !important;">
							<h5 class="h5-responsive">1. Choose parameter to create report by</h5>
							<hr>
							<!-- The @change only needs to be bound to a single radio button in the group -->
							<mdb-input type="radio" id="filter-1" name="filterRadio" radioValue="Person" v-model="filter" label="Person" @change="populateParameter" />
							<mdb-input type="radio" id="filter-2" name="filterRadio" radioValue="Charge Line" v-model="filter" label="Charge Line"/>
					</mdb-col>
					<mdb-col class="pt-3" col="3" style="background-color: white;">
							<h5 class="h5-responsive">2. Choose value of parameter</h5>
							<hr>
							<mdb-select v-if="this.filter == 'Person'" search v-model="parameterValue" placeholder="Select an option" @getValue="setParameter" :disabled="parameterDisable" />
							<mdb-select v-if="this.filter == 'Charge Line'" search multiple v-model="parameterValue" placeholder="Select an option" @getValue="setParameter" :disabled="parameterDisable" />
							<mdb-select v-if="this.filter == 'Charge Line'" search v-model="parameterTask" placeholder="Select a task" 
									@getValue="setParameterTask" :disabled="parameterDisableTask" 
							/>
					</mdb-col>
					<mdb-col class="pt-3" col="4" style="background-color: white;">
							<h5 class="h5-responsive">[ Optional ] Scope</h5>
							<p>By default, the previous month will be grabbed. If you want a specific month, use this option </p>
							<hr>
							<mdb-select label="Month" @getValue="setScopeMonth" v-model="monthOptions" required />
							<mdb-input type="number" label="Year" v-model="scopeYear" :min="2021" />
					</mdb-col>
			</mdb-row>
			<mdb-row class="justify-content-center">
        <mdb-col class="pt-3 pb-3" col="10" style="background-color: white;">
            <mdb-btn size="lg" color="info" style="width: 100%; max-width: 50%;" v-on:click="populateReports" :disabled="applyDisableFilter">
                Apply Filter
            </mdb-btn>
        </mdb-col>
			</mdb-row>
		</div>
		<div v-else>
			<mdb-row class="justify-content-center">
					<mdb-col class="pt-3" col="10" style="background-color: white;">
						<mdb-alert id="feedback-failure2" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
							There was an error retrieving information from the server! Please try again later.
						</mdb-alert>

						<h5 class="h5-responsive">Select Month of Report</h5>
						<p>By default, the previous month will be grabbed. If you want a specific month, select an option below. </p>
						<hr>

						<mdb-select label="Month" @getValue="setScopeMonth" v-model="monthOptions" required />
						<mdb-input type="number" label="Year" v-model="scopeYear" :min="2021" />

					</mdb-col>
			</mdb-row>
			<mdb-row class="justify-content-center">
        <mdb-col class="pt-3 pb-3" col="10" style="background-color: white;">
            <mdb-btn size="lg" color="info" style="width: 100%; max-width: 50%;" v-on:click="populateReports">
                Apply Filter
            </mdb-btn>
        </mdb-col>
			</mdb-row>
		</div>
    
    <br>

		<!-- Monthly Report Table -->
    <mdb-row class="justify-content-center" >
        <mdb-col col="10" class="pl-0 pr-0" style="background-color: white;">
            <hr>
            <h3 class="h3-responsive">Reports</h3>
            <hr>
            <mdb-row class="justify-content-center">
                <mdb-col col="10" style="background-color: white;">
                  <div disabled="this.$store.getters.getGroups.includes('Administration') || this.$store.getters.getGroups.includes('Supervisor')">
                    <mdb-btn size="lg" color="secondary" style="width: 100%; max-width: 50%;" v-on:click="processMonthly" v-if="!submitProcessing" :disabled="applyDisableMonthly">
                        Build Monthly Report
                    </mdb-btn>
                  </div>
                    <mdb-btn class='QuadrusButton' v-if="submitProcessing">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Processing...
                    </mdb-btn>
                </mdb-col>
            </mdb-row>
            <QDataTable :filterWords="this.wars.filterWords" :rowData="this.wars.rowData" :headers="this.wars.columns" 
                :hasBtn="true" :btnName="'Download'" @use-method="downloadPDF"
                :hasBtn2="true" :btnName2="'Preview'" @use-method-2="previewPDF"
            />
        </mdb-col>
    </mdb-row>
		<!-- /Monthly Report Table -->
</div>

</template>
<script>
import { mdbCol, mdbRow, mdbInput, mdbSelect, mdbBtn, mdbAlert } from 'mdbvue';
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
import * as PDFDownload from '@/assets/js/downloadPDF.js';

const buildMonthly = require('@/assets/js/buildMonthlyWord.js');
const buildMonthlyMulti = require('@/assets/js/buildMonthlyMultiWord.js');
const buildMonthlyPerson = require('@/assets/js/buildMonthlyPersonWord.js');

export default {
    components: {
        mdbCol,
        mdbRow,
        mdbInput,
        mdbSelect,
        mdbBtn,
        mdbAlert,
        QDataTable
    },
    data() {
        return {
            filter: '',

            parameter: '',
						multiParameters: '',
            parameterProjTask: '',
            parameterDisable: true,
            parameterDisableTask: true,
            parameterValue: [],
            parameterTask: [],
						activityList: '',
						names: '',														//Employee names to be printed in the report header
						projects: '',													//Projects to be printed in the report header
            projNameInfo: [],                     //Temp array before restucture

            scopeMonth: '',
            scopeYear: '',
            scopeDisable: false,

            applyDisableFilter: true,
            applyDisableMonthly: true,

            monthOptions: [
                { text: 'January', value: '1', selected: false },
                { text: 'February', value: '2', selected: false },
                { text: 'March', value: '3', selected: false },
                { text: 'April', value: '4', selected: false },
                { text: 'May', value: '5', selected: false },
                { text: 'June', value: '6', selected: false },
                { text: 'July', value: '7', selected: false },
                { text: 'August', value: '8', selected: false },
                { text: 'September', value: '9', selected: false },
                { text: 'October', value: '10', selected: false },
                { text: 'November', value: '11', selected: false },
                { text: 'December', value: '12', selected: false }
            ],
            wars: {
                columns: [
                    {
                        colTitle: 'Person',
                        sortBy: 'name'
                    },
                    {
                        colTitle: 'Project',
                        sortBy: 'projectTask'
                    },
                    {
                        colTitle: 'Task',
                        sortBy: 'lineItem'
                    },
                    {
                        colTitle: 'Date',
                        sortBy: 'dateSubmitted'
                    },
                    {
                        colTitle: 'Action',
                    }
                ],
                rowData: [],
                filterWords: [
                    'name',
                    'projectTask',
                    'lineItem',
                    'dateSubmitted'
                ]
            },

            submitProcessing: false,

            p1: false,
						p2: false,
        }
    }, 
    methods: {
        setParameter: function(value) 
				{
					if(value != null) 
					{
						// Person Filter
						if (this.filter === "Person") 
						{
							this.parameter = value;
							this.applyDisableFilter = false;
						}

						// Charge Line Filter
						if (this.filter === "Charge Line")
						{
							if (value.length == 0)
							{
								//Reset Task Options
								this.parameterTask.splice(0);
								//Disable Task
								this.parameterDisableTask = true;
								this.applyDisableFilter = true;
							}

							if (value.length == 1) 
							{
								this.applyDisableFilter = true;
								this.multiParameters = value;
								this.parameter = value;
								this.populateTask();
							}

							else if (value.length > 1)
							{
								//Reset Task Options
								this.parameterTask.splice(0);
								//Disable Task
								this.parameterDisableTask = true;

								this.multiParameters = value;

								this.applyDisableFilter = false;
							}
						}
					}
        },

        setParameterTask: function(value)
				{
            if(value != null) {
                this.parameterProjTask = value;
                this.applyDisableFilter = false;
            }
        },

        setScopeMonth: function(value)
				{
					this.scopeMonth = parseInt(value);
        },

        populateParameter: async function()
				{
            this.parameterValue.splice(0); // Clears drop down list to avoid overlapping options
            this.parameterTask.splice(0); // Clears drop down list to avoid overlapping options
            if(this.filter == "Person") 
						{
                let token = this.$store.getters.getToken;
                let response = '';
                let query = '';

                this.parameterDisable = false;
                this.parameterDisableTask = true;
                if(this.$store.getters.getGroups.includes('Supervisor') && !this.$store.getters.getGroups.includes('Administration')) { // See only specific people assigned to you
                  try 
                  {
                      query = 'filters[blocked][$eq][0]='+false+'&filters[reportsTo][$eq][1]='+this.$store.getters.getUser+'&populate[2]=role';
                  } 
                  catch 
                  {
                      window.scroll({top: 0, behavior: 'smooth'});
                      this.p1 = true;
                      return;
                  }
                } else if(this.$store.getters.getGroups.includes('Administration')){ // Allows administration to see all charge lines
                  query = 'filters[blocked][$eq][0]='+false+'&populate[1]=role';
                }

                response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);

                for(let i = 0; i < response.data.length; i = i + 1) {
                    let pushData = { text: response.data[i].firstName + " " + response.data[i].lastName, value: response.data[i].username }
                    this.parameterValue.push(pushData);
                }

            } 
						else if (this.filter == "Charge Line") 
						{
                let token = this.$store.getters.getToken;
                let addProject = true;
                let chargeLines = '';

                try {

                  if(this.$store.getters.getGroups.includes('Supervisor') && !this.$store.getters.getGroups.includes('Administration')) { // See only specific people assigned to you
                    let query = 'filters[blocked][$eq][0]='+false+'&filters[reportsTo][$eq][1]='+this.$store.getters.getUser;
                    let userResponse = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
                    
                    query = '';
                    for(let i = 0; i < userResponse.data.length; i = i + 1) {
                      if(i > 0) {
                        query = query + "&filters[$or]["+i+"][username][$eq]="+userResponse.data[i].username;
                      } else {
                        query = query + "filters[$or]["+i+"][username][$eq]="+userResponse.data[i].username;
                      }
                    }
                    chargeLines = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);

                  } else if(this.$store.getters.getGroups.includes('Administration')){ // Allows administration to see all charge lines

                    /*
                    let count = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts/count',token);
                    console.log(count);

                    if(count.data > 100) {
                      for(let i = 0; i <= Math.floor(count.data/100); i = i + 1) {
                        let query = "_start="+(i * 100);
                        let result = await DatabaseLib.getRequest('strapiDatabase','contract-data',token, query);
                        console.log(chargeLines);

                      }
                    } else {
                      chargeLines = await DatabaseLib.getRequest('strapiDatabase','contract-data',token);
                    }
                    */
                    chargeLines = await DatabaseLib.getRequest('strapiDatabase','contract-datas',token);
                  }

									for(let i = 0; i < chargeLines.data.data.length; i = i + 1) 
									{
										addProject = true;

										// Check if project is already in options
										for (let j = 0; j < this.parameterValue.length; j = j + 1)
										{
											if (this.parameterValue[j].text === chargeLines.data.data[i].attributes.project)
											{
												addProject = false;
												break;
											}
										}

										// Add project if not already in options
										if (addProject == true)
										{
											this.parameterValue.push( {text: chargeLines.data.data[i].attributes.project, value: chargeLines.data.data[i].attributes.project} );
										}
									}
									this.parameterDisable = false;
                }
                catch
                {
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                }
            }
        },

        populateReports: async function()
				{
						this.wars.rowData = [];
						this.applyDisableMonthly = true;
						let endMonth = parseInt(this.scopeMonth)+1;
						let endYear = parseInt(this.scopeYear);
						let token = this.$store.getters.getToken;
						let query = '';

						if (endMonth == 13)
						{
							endMonth = 1;
							endYear = parseInt(this.scopeYear) + 1;
						}

						/****************************** If Regular Employee ****************************/
						if (!this.$store.getters.getGroups.includes('Supervisor') && !this.$store.getters.getGroups.includes('Administration'))
						{
              query = 'filters[startDate][$gte]='+this.scopeYear+'-'+('0'+this.scopeMonth).slice(-2)+'-01';
              query = query + '&filters[startDate][$lt]='+endYear+'-'+('0' + (endMonth)).slice(-2) + '-01';
              query = query + '&filters[submitted][$eq]='+true+'&filters[username][$eq]='+ this.$store.getters.getUser; //this.parameter;
              
							let response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
              
							if (response instanceof Error)
							{
								window.scroll({top: 0, behavior: 'smooth'});
								this.p2 = true;
								return;
							}

            
              response.data.data.forEach(tableRow => {
                  let startDate = new Date(tableRow.attributes.startDate)
                  let endDate = new Date(tableRow.attributes.endDate)
                  
                  this.wars.rowData.push({
                      id: tableRow.id,
                      name: tableRow.attributes.firstLastName,
                      dateSubmitted: (startDate.getUTCMonth() + 1) + "/" + startDate.getUTCDate() + "/" + startDate.getFullYear().toString().substr(-2) + " - " + (endDate.getUTCMonth() + 1) + "/" + endDate.getUTCDate() + "/" + endDate.getFullYear().toString().substr(-2),
                      projectTask: tableRow.attributes.projectTask,
                      lineItem: tableRow.attributes.lineItem,
                      activity: tableRow.attributes.activity,
                      division: tableRow.attributes.division
                  })
              })
            

							if (response.data.data.length != 0)
              {
								this.applyDisableMonthly = false;		//Enables Build Monthly Report Button
							}

							return;
						}
						/****************************** /If Regular Employee ****************************/
           
            if(this.filter == 'Person') 
						{
                try 
								{
                    query = 'filters[username][$eq][0]=' + this.parameter + '&';
                    query = query + 'filters[startDate][$gte][1]='+ this.scopeYear + '-' + ('0' + this.scopeMonth).slice(-2) + '-01';
                    query = query + '&filters[startDate][$lt][2]='+ endYear + '-' + ('0' + (endMonth)).slice(-2) + '-01';
                    query = query + '&filters[submitted][$eq][3]='+true;

                    let response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
                                       
                    response.data.data.forEach(tableRow => {
                        let startDate = new Date(tableRow.attributes.startDate)
                        let endDate = new Date(tableRow.attributes.endDate)

                        this.wars.rowData.push({
                            id: tableRow.id,
                            name: tableRow.attributes.firstLastName,
                            dateSubmitted: (startDate.getMonth() + 1) + "/" + startDate.getUTCDate() + "/" + startDate.getFullYear().toString().substr(-2) + " - " + (endDate.getMonth() + 1) + "/" + endDate.getUTCDate() + "/" + endDate.getFullYear().toString().substr(-2),
                            projectTask: tableRow.attributes.projectTask,
                            lineItem: tableRow.attributes.lineItem,
                            activity: tableRow.attributes.activity,
                            division: tableRow.attributes.division
                        })
                    })

                    if (response.data.data.length != 0)
                    {
                        this.applyDisableMonthly = false;
                    }

                } 
								catch 
								{
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                }
                
            } 
            else if (this.filter == 'Charge Line') 
            {
                try 
									{
										if (this.multiParameters.length > 1)	//If multiple selected
										{
											for (let i = 0; i < this.multiParameters.length; i++)
											{
                        query = query + 'filters[$or]['+i+'][projectTask][$eq]='+ encodeURIComponent(this.multiParameters[i]) + '&';
											}
										}
										else	//If single selected
										{
											query = 'filters[projectTask][$eq]='+encodeURIComponent(this.parameter)+'&filters[lineItem][$eq][1]=' + encodeURIComponent(this.parameterProjTask) + '&';
										}
                    
                    query = query + 'filters[startDate][$gte]='+ this.scopeYear + '-' + ('0' + this.scopeMonth).slice(-2) + '-01';
                    query = query + '&filters[startDate][$lt]=' + endYear + '-' + ('0' + (endMonth)).slice(-2) + '-01';
                    query = query + '&filters[submitted][$eq]='+true;

                    let response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
                   
                    response.data.data.forEach(tableRow => {
                        let startDate = new Date(tableRow.attributes.startDate)
                        let endDate = new Date(tableRow.attributes.endDate)

                        this.wars.rowData.push({
                            id: tableRow.id,
                            name: tableRow.attributes.firstLastName,
                            dateSubmitted: (startDate.getMonth() + 1) + "/" + startDate.getUTCDate() + "/" + startDate.getFullYear().toString().substr(-2) + " - " + (endDate.getMonth() + 1) + "/" + endDate.getUTCDate() + "/" + endDate.getFullYear().toString().substr(-2),
                            projectTask: tableRow.attributes.projectTask,
                            lineItem: tableRow.attributes.lineItem,
                            activity: tableRow.attributes.activity,
                            division: tableRow.attributes.division
                        })
                    })

                    if (response.data.data.length != 0)
                    {
                        this.applyDisableMonthly = false;
                    }

                } 
								catch 
								{
                    window.scroll({top: 0, behavior: 'smooth'});
                    this.p1 = true;
                }
            }
        },

        populateTask: async function() 
				{
						//Reset Task Options
						this.parameterTask.splice(0);

						try 
						{
								let token = this.$store.getters.getToken;
                let query = 'filters[project][$eq][0]='+ encodeURIComponent(this.parameter);
								let response = await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query);
               
								for(let i = 0; i < response.data.data.length; i = i + 1) 
								{
										this.parameterTask.push( {text: response.data.data[i].attributes.task, value: response.data.data[i].attributes.task} )
								}

								this.parameterDisableTask = false;
						} 
						catch 
						{
								window.scroll({top: 0, behavior: 'smooth'});
								this.p1 = true;
						}
				},

        downloadPDF: function(war) 
				{
            PDFDownload.fileDownload(war.id, war.projectTask, war.lineItem, war.activity, war.name, war.dateSubmitted, war.division, 'Download')
        },

        previewPDF: function(war) 
				{
            PDFDownload.fileDownload(war.id, war.projectTask, war.lineItem, war.activity, war.name, war.dateSubmitted, war.division, 'Preview')
        },

				processMonthly: async function()
				{
          this.submitProcessing = true;
					this.activityList = '';

					//**************************** Charge Line Filter *****************************/
					if (this.filter == 'Charge Line')
					{
						if (this.multiParameters.length > 1)
						{
							//*************************** Multi Charge Line Report ***************************//

							let projTaskArray = [];											//Array of Tasks associated with each Project
							let namesArray = [];												//Holds each individual employee name that is on the passed charge lines
							let token = this.$store.getters.getToken;
							let query = '';
							let temp = [];															//Used to hold the tasks for the currently selected Project
							let response = null;
              let namesWithProject = [];                  //Temporary Variable before restructure
							this.names = '';
              query = '';
							for (let i = 0; i < this.multiParameters.length; i = i + 1)
							{
                query = 'filters[project][$eq]='+ encodeURIComponent(this.multiParameters[i]);
								response = await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query);
                
								response.data.data.forEach(tableRow => {
									temp.push(tableRow.attributes.task)					//Pushes each task for this project into a single array
								})
								projTaskArray.push(temp);						//Pushes task array in same position as the corresponding project in multiParameters variable
								temp = [];
							}

							// Fills Names Array
							this.wars.rowData.forEach(war => {
								if (this.names.includes(war.name) != true) 
								{
									this.names += war.name + ', ';
									namesArray.push(war.name);
								}
							});

              // Fills namesWithProject Array
              // Create Objects
              for (let i = 0; i < this.multiParameters.length; i = i + 1)
              {
                for (let j = 0; j < projTaskArray[i].length; j = j + 1)
                {
                  namesWithProject.push({project: this.multiParameters[i], task: projTaskArray[i][j], names: [], activities: []})
                }
              }

              // Fills Names
              for (let i = 0; i < namesWithProject.length; i = i + 1)
              {
               let query = 'filters[project][$eq][0]='+encodeURIComponent(namesWithProject[i].project)+'&filters[task][$eq][1]='+encodeURIComponent(namesWithProject[i].task);

                let response = '';
                try {
                  response = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);
                } catch(error) {
                  window.scroll({top: 0, behavior: 'smooth'});
                  this.p1 = true;
                  this.submitProcessing = false;
                  return;
                }

                response.data.data.forEach(tableRow => {
									namesWithProject[i].names.push(tableRow.attributes.firstLastName)        // Fill names array associated with proj + task
                  namesWithProject[i].activities.push({person: tableRow.attributes.firstLastName, activity: ''})
								})
              }

              this.wars.rowData.forEach(war => {
                for (let i = 0; i < namesWithProject.length; i = i + 1)
                {
                  if ((namesWithProject[i].project == war.projectTask) && (namesWithProject[i].task == war.lineItem))
                  {
                    for (let j = 0; j < namesWithProject[i].names.length; j = j + 1)
                    {
                      if (namesWithProject[i].names[j] == war.name) {
                        for(let x = 0; x < war.activity.content[0].content.length; x = x + 1) {

                          namesWithProject[i].activities[j].activity = namesWithProject[i].activities[j].activity + '•  ' + war.activity.content[0].content[x].content[0].content[0].text + '\n';

                          if(war.activity.content[0].content[x].content.length > 1) {
                            for(let y = 0; y < war.activity.content[0].content[x].content[1].content.length; y = y + 1) {

                              namesWithProject[i].activities[j].activity = namesWithProject[i].activities[j].activity + '\t○  ' + war.activity.content[0].content[x].content[1].content[y].content[0].content[0].text + '\n';

                              if(war.activity.content[0].content[x].content[1].content[y].content.length > 1) {
                                for(let z = 0; z < war.activity.content[0].content[x].content[1].content[y].content[1].content.length; z = z + 1) {
                                  
                                    namesWithProject[i].activities[j].activity = namesWithProject[i].activities[j].activity + '\t\t□ ' + war.activity.content[0].content[x].content[1].content[y].content[1].content[z].content[0].content[0].text + '\n';

                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });

              this.projNameInfo = namesWithProject;
							//*************************** /Multi Charge Line Report ***************************//
						}
						else
						{
							//*************************** Single Charge Line Report ***************************//
							this.names = '';
							let namesArray = [];
							this.activityList = '';
							let activitiesPerName = [];

							// Fills Names Array
							this.wars.rowData.forEach(war => {
								if (this.names.includes(war.name) != true) 
								{
									this.names += war.name + ', ';
									namesArray.push(war.name);
								}
							});
							
							// Cuts off trailing comma
							this.names = this.names.slice(0, -2); 

							// Create Activity List for each Name
              for(let i = 0; i < namesArray.length; i = i + 1) {
                activitiesPerName[i] = { "name": namesArray[i], "activity": ''}
              }

              this.wars.rowData.forEach(war => {
                for(let i = 0; i < activitiesPerName.length; i = i + 1) {
                  if(activitiesPerName[i].name == war.name) {
                    for(let x = 0; x < war.activity.content[0].content.length; x = x + 1) {

                      activitiesPerName[i].activity = activitiesPerName[i].activity + '•  ' + war.activity.content[0].content[x].content[0].content[0].text + '\n';
                      
                      if(war.activity.content[0].content[x].content.length > 1) {
                        for(let y = 0; y < war.activity.content[0].content[x].content[1].content.length; y = y + 1) {

                          activitiesPerName[i].activity = activitiesPerName[i].activity + '\t○  ' + war.activity.content[0].content[x].content[1].content[y].content[0].content[0].text + '\n';

                          if(war.activity.content[0].content[x].content[1].content[y].content.length > 1) {
                            for(let z = 0; z < war.activity.content[0].content[x].content[1].content[y].content[1].content.length; z = z + 1) {
                              
                                activitiesPerName[i].activity = activitiesPerName[i].activity + '\t\t□ ' + war.activity.content[0].content[x].content[1].content[y].content[1].content[z].content[0].content[0].text + '\n';

                            }
                          }
                        }
                      }
                    }
                  }
                }
              });


							activitiesPerName.forEach(person => 
							{
								this.activityList = this.activityList + "Name" + '\n' + person.activity + '\n';
							});
              
							//*************************** /Single Charge Line Report ***************************//
						}
					}
					//**************************** /Charge Line Filter *****************************/

					//**************************** Person Filter *****************************/
					if (this.filter == 'Person')
					{
						let projectOptions = [];
						let addToOptions = true;

						// Populates Project options
						try 
						{
							let query = '';
							let token = this.$store.getters.getToken;

							if (this.$store.getters.getGroups.includes('Administration') || this.$store.getters.getGroups.includes('Supervisor'))		// Checks if user is Supervisor/Admin
							{
                query = 'filters[username][$eq]['+0+']='+ this.parameter;
							}
							else	//If user is regular employee
							{
                query = 'filters[username][$eq]['+0+']=' + this.$store.getters.getUser;
							}

							let chargeLines = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);
							for(let i = 0; i < chargeLines.data.data.length; i = i + 1) 
							{
									addToOptions = true;
									
									// Check if project + task combination already inside project options
									for (let j = 0; j < projectOptions.length; j = j + 1)
									{
											if (projectOptions[j].project === chargeLines.data.data[i].attributes.project && projectOptions[j].task === chargeLines.data.data[i].attributes.task)
											{
													addToOptions = false;
													break;
											}
									}
									// Add to project options if project + task combination doesn't already exist
									if (addToOptions == true)
									{
											projectOptions.push( { 'project': chargeLines.data.data[i].attributes.project, 'task': chargeLines.data.data[i].attributes.task} );
									}
							}
						} 
						catch 
						{
								window.scroll({top: 0, behavior: 'smooth'});
								this.p1 = true;
                this.submitProcessing = false;
								return;
						}

						this.activityList = '';
						this.projects = '';
						let emptyActivity = true;

						// Sort Projects Array
						projectOptions.sort(function(a, b) {
							var keyA = a.project,
							keyB = b.project;
							// Compare the 2 projects
							if (keyA < keyB) return -1;
							if (keyA > keyB) return 1;
							return 0;
						});

						// Populate Projects Section
						projectOptions.forEach(projTask => 
						{
							this.projects += projTask.project + ' - ' + projTask.task + '\n';
						});

						// Populate Activity List
						projectOptions.forEach(projTask =>
						{
							emptyActivity = true;

							this.activityList += '#' + projTask.project + ' - ' + projTask.task + '#' + '\n';
							
							// Populate activities if they exist
							for (let i = 0; i < this.wars.rowData.length; i = i + 1)
							{
								if (this.wars.rowData[i].projectTask === projTask.project && this.wars.rowData[i].lineItem === projTask.task)
								{
                  for(let x = 0; x < this.wars.rowData[i].activity.content[0].content.length; x = x + 1) {

                    this.activityList += '•  ' + this.wars.rowData[i].activity.content[0].content[x].content[0].content[0].text + '\n';
                    if(this.wars.rowData[i].activity.content[0].content[x].content.length > 1) {
                      for(let y = 0; y < this.wars.rowData[i].activity.content[0].content[x].content[1].content.length; y = y + 1) {

                        this.activityList += '\t○  ' + this.wars.rowData[i].activity.content[0].content[x].content[1].content[y].content[0].content[0].text + '\n';

                        if(this.wars.rowData[i].activity.content[0].content[x].content[1].content[y].content.length > 1) {
                          for(let z = 0; z < this.wars.rowData[i].activity.content[0].content[x].content[1].content[y].content[1].content.length; z = z + 1) {
                            
                            this.activityList += '\t\t□ ' + this.wars.rowData[i].activity.content[0].content[x].content[1].content[y].content[1].content[z].content[0].content[0].text + '\n';

                          }
                        }
                      }
                    }
                  }
                  emptyActivity = false;
								}
							}

							// If no activities, take note of it
							if (emptyActivity == true)
							{
								this.activityList += 'No Activities Reported\n';
							}

							this.activityList += '\n';
						});
					}
					//**************************** /Person Filter *****************************/
					
					this.buildMonthly();

				},

        buildMonthly: function() {
						let division = this.wars.rowData[0].division;		//Division name to be printed in the report header
            let month = '';
            switch(this.scopeMonth.toString()) 
            {
                case '1':
                    month = 'January ' + this.scopeYear;
                    break;
                case '2':
                    month = 'February ' + this.scopeYear;
                    break;
                case '3':
                    month = 'March ' + this.scopeYear;
                    break;
                case '4':
                    month = 'April ' + this.scopeYear;
                    break;
                case '5':
                    month = 'May ' + this.scopeYear;
                    break;
                case '6':
                    month = 'June ' + this.scopeYear;
                    break;
                case '7':
                    month = 'July ' + this.scopeYear;
                    break;
                case '8':
                    month = 'August ' + this.scopeYear;
                    break;
                case '9':
                    month = 'September ' + this.scopeYear;
                    break;
                case '10':
                    month = 'October ' + this.scopeYear;
                    break;
                case '11':
                    month = 'November ' + this.scopeYear;
                    break;
                case '12':
                    month = 'December ' + this.scopeYear;
                    break;
                default:
                    month = 'NA';
            }
            
            // For when Charge Line is selected
            if (this.filter == 'Charge Line')
            {
							if (this.multiParameters.length > 1)
							{
								buildMonthlyMulti(division, month, this.projNameInfo);
							}
							else
							{
								buildMonthly(this.activityList, this.names, division, this.parameter, this.parameterProjTask, month, 'Preview');
							}
            }

						// For when Person is selected or Regular Employee
            if (this.filter == 'Person')
            {
							let name = this.wars.rowData[0].name;						//Employee name to be printed in the report header

							buildMonthlyPerson(this.activityList, division, name, month, 'Preview');

            }

            this.submitProcessing = false;	
        },

				setDateScope: function() {
					let todayMonth = new Date().getMonth();

					if(todayMonth == 0) { 
						this.monthOptions[11].selected = true;
						this.scopeMonth = this.monthOptions[11].value;
						this.scopeYear = new Date().getFullYear() - 1;
					} else {
						this.monthOptions[todayMonth-1].selected = true;
						this.scopeMonth = this.monthOptions[todayMonth-1].value;
						this.scopeYear = new Date().getFullYear();
					}

				},
    },

    watch: 
    {
			filter: function()
			{
				this.applyDisableFilter = true;
				this.applyDisableMonthly = true;
      }
    },

    mounted()
    {
			this.setDateScope();
    }
}
</script>