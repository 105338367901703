<template >
<div class="page-start">
		
		<mdb-row class="justify-content-center">
			<mdb-col col="10">
				<mdb-card class="mb-3">
					<h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">WAR Submission Status</h3>
					<mdb-card-body>
            <mdb-input type="checkbox" id="billable" name="billable" @change="populateTable" label="Toggle Billable Filter" />

            <QDataTable :filterWords="this.supervisors.filterWords" :rowData="this.supervisors.rowData" :headers="this.supervisors.columns"
							:hasBtn="true" :btnName="'Details'" @use-method="view"
						/>

            <!-- View User Modal -->
						<mdb-modal size="xl" centered :show="viewModal" @close="viewModal = false">
							<mdb-modal-header>
								<mdb-modal-title><p class="h2 grey-text">Details</p></mdb-modal-title>
							</mdb-modal-header>
							<mdb-modal-body style="font-size: 0.9em;">
								<QDataTable :filterWords="this.users.filterWords" :rowData="this.users.selectedData" :headers="this.users.columns"
                  :hasBtn="false"
                />
							</mdb-modal-body>

							<mdb-modal-footer class="d-flex justify-content-center">
								<mdb-btn color="secondary" @click.native="viewModal = false">Close</mdb-btn>
							</mdb-modal-footer>
						</mdb-modal>
					</mdb-card-body>
				</mdb-card>
			</mdb-col>
		</mdb-row>
	</div>
</template>

<script>

//import {mdbCol, mdbRow, mdbModal, mdbModalHeader, mdbModalBody, mdbModalFooter, mdbModalTitle, mdbBtn, mdbListGroup, mdbListGroupItem, mdbContainer, mdbInput, mdbCard, mdbCardBody, mdbAlert} from 'mdbvue';
import QDataTable from "@/components/QDataTable.vue";
import {mdbCol, mdbRow, mdbCard,mdbCardBody, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbInput} from 'mdbvue';
//import {MDBIcon} from 'mdb-vue-ui-kit';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
//import { now } from "moment";

export default {
  name:"WarSubStatus",
  components: {
    //mdbContainer,
		mdbCol,
		mdbRow,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter,
		mdbBtn,
		//mdbListGroup,
    //mdbListGroupItem,
		mdbInput,
		mdbCard,
		mdbCardBody,
		//mdbAlert,
		QDataTable
  },
  data() {
    return {
      viewModal: false,
      billable: false,
      oneShot: false,
      supervisors: {
				columns: [
					{
						colTitle: 'Name',
						sortBy: 'firstLastName'
					},
					{
						colTitle: 'Email',
						sortBy: 'email'
					},
          {
            colTitle: 'Submitted?',
            sortBy: 'submitted'
          },
					{
						colTitle: 'Action'
					},
				],
				rowData: [],
        superData: [],
				filterWords: [
					'firstLastName',
					'email',
          'submitted'
				],
			},

      users: {
        columns: [
          {
						colTitle: 'Name',
						sortBy: 'firstLastName'
					},
          {
						colTitle: 'Email',
						sortBy: 'email'
					},
          {
						colTitle: 'Task',
						sortBy: 'projectName'
					},
          {
						colTitle: 'Submitted?',
						sortBy: 'submitted'
					},
          {
						colTitle: 'Last Submit Week',
						sortBy: 'startDate'
					},
        ],
        rowData: [],
        userData: [],
        selectedData: [],
        filterWords: [
          'firstLastName',
          'email',
          'projectName',
          'submitted',
          'startDate'
        ],
      },
      contracts: {
        rowData: [],
      },
      assignedContracts: {
        rowData:[],
      },
      reports: {
        rowData: [],
      }
    }
  },
  methods: {

    // function to get the list of supervisors in the database
    populateTable: async function() {
      this.clearData();
      if (this.oneShot == true) {
        if (this.billable == false) {
          this.billable = true;
        }
        else {this.billable = false;}
      }
      else {this.oneShot = true;}
      
      this.supervisors.rowData = [];
      this.users.rowData = [];
      let token = this.$store.getters.getToken;
      let query = "filters[disabled][$eq][0]="+false;

      // get all contracts in database
      let response = '';
      try {
        response = await DatabaseLib.getRequest('strapiDatabase','contract-datas',token,query);
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p1 = true;
        return;
      }
      for(let i = 0; i < response.data.data.length; i = i + 1) {
        if(this.billable == response.data.data[i].attributes.billable){
          this.contracts.rowData.push({
            project: response.data.data[i].attributes.project,
            task: response.data.data[i].attributes.task,
            supervisor: response.data.data[i].attributes.supervisor,
            billable: response.data.data[i].attributes.billable
          })
        }
      }

      // get all assigned contracts
      response = '';
      try {
        response = await DatabaseLib.getRequest('strapiDatabase','assigned-contracts',token,query);
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p1 = true;
        return;
      }
      for(let i = 0; i < response.data.data.length; i = i + 1) {
        this.assignedContracts.rowData.push({
          project: response.data.data[i].attributes.project,
          task: response.data.data[i].attributes.task,
          username: response.data.data[i].attributes.username,
        })
      }
      
      // get users
      query = "filters[blocked][$eq][0]="+false+"&populate[1]=role&populate[2]=profilePic&populate[3]=groups";
      response = '';
      try {
        response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p1 = true;
        return;
      }

      for(let i = 0; i < response.data.length; i = i + 1) {
        this.users.userData.push({
          username: response.data[i].username,
          email: response.data[i].email,
          firstLastName: response.data[i].firstName + " " + response.data[i].lastName,
          reportsTo: response.data[i].reportsTo
        })
      }
      
      //  get supervisors
      let sup = [];
      for(let c = 0; c < response.data.length; c = c + 1){
        if(response.data[c].role.name != "Administrator"){
          for(let i = 0; i < response.data[c].groups.length; i = i + 1) {
            if(response.data[c].groups[i].name == "Supervisor"){
              sup.push(response.data[c]);
            }
          }
        }
      }


      for(let i = 0; i < sup.length; i = i + 1) {
        this.supervisors.superData.push({
          username: sup[i].username,
          email: sup[i].email,
          firstLastName: sup[i].firstName + " " + sup[i].lastName,
        })
      }

      // generate table data
      for (let i = 0; i < this.supervisors.superData.length; i = i + 1) {
        let submitChecked = "bad";
        submitChecked = await this.testSupervisors(this.supervisors.superData[i].username);
        this.supervisors.rowData.push({
          firstLastName:  this.supervisors.superData[i].firstLastName,
          email:          this.supervisors.superData[i].email,
          username:       this.supervisors.superData[i].username,
          submitted:      submitChecked,
        })
      }

      return;
    },

    // test if a supervisor's employees are fully submitted
    testSupervisors: async function(supervisor) {
      let token = this.$store.getters.getToken;
      let submitStatus = 'Yes';
      // search list of users for all reports to this supervisor
      
      for (let ii = 0; ii < this.users.userData.length; ii = ii + 1) {
        if (this.users.userData[ii].reportsTo == supervisor) {
                    
          // search assigned contracts for this user's contracts
          for (let jj = 0; jj < this.assignedContracts.rowData.length; jj = jj + 1) {
            if (this.assignedContracts.rowData[jj].username == this.users.userData[ii].username) { 
                
              //get most recent WAR for that user
              let query = "filters[username][$eq][0]=" + this.assignedContracts.rowData[jj].username + 
              '&filters[submitted][$eq][1]=' + true + 
              "&filters[lineItem][$eq][2]=" + this.assignedContracts.rowData[jj].task + 
              "&filters[projectTask][$eq][3]=" + this.assignedContracts.rowData[jj].project + 
              "&sort=startDate:desc" +
              "&pagination[start]=0&pagination[limit]=1";

              let response = '';
              try {
                response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
              } catch(error) {
                window.scroll({top: 0, behavior: 'smooth'});
                this.p1 = true;
                return;
              }
             
              if(response.data.data != ""){
              // filter by billable chargelines
                if (this.billable == true) {  // if the billable flag is set on the page
                  for (let kk = 0; kk < this.contracts.rowData.length; kk = kk + 1) {
                    if (this.contracts.rowData[kk].project == response.data.data[0].attributes.projectTask && this.contracts.rowData[kk].task == response.data.data[0].attributes.lineItem) {
                      if (this.contracts.rowData[kk].billable == true) {
                        this.users.rowData.push({
                          firstLastName:  this.users.userData[ii].firstLastName,
                          email:          this.users.userData[ii].email,
                          reportsTo:      this.users.userData[ii].reportsTo,
                          projectName:    response.data.data[0].attributes.projectTask + " - " + response.data.data[0].attributes.lineItem,
                          submitted:      response.data.data[0].attributes.submitted,
                          startDate:      response.data.data[0].attributes.startDate
                        })
                        let temp = new Date();
                        let lastWeek = new Date(temp - 7 * 24 * 60 * 60 * 1000);
                        let today = new Date(response.data.data[0].attributes.startDate);
                        if ((today.valueOf() < lastWeek.valueOf()) || response.data.data[0].attributes.submitted == false) {
                          submitStatus = "No";
                        }
                      }
                    }
                  }
                }

                else {  // if the billable flag is not set on the page
                  this.users.rowData.push({
                    firstLastName:  this.users.userData[ii].firstLastName,
                    email:          this.users.userData[ii].email,
                    reportsTo:      this.users.userData[ii].reportsTo,
                    projectName:    response.data.data[0].attributes.projectTask + " - " + response.data.data[0].attributes.lineItem,
                    submitted:      response.data.data[0].attributes.submitted,
                    startDate:      response.data.data[0].attributes.startDate
                  })
                  let temp = new Date();
                  let lastWeek = new Date(temp - 7 * 24 * 60 * 60 * 1000);
                  let today = new Date(response.data.data[0].attributes.startDate)
                  
                  if ((today.valueOf() < lastWeek.valueOf()) || response.data.data[0].attributes.submitted == false) {
                    submitStatus = "No";
                  }
                }
              }
            }
          }
        }
      }
      return submitStatus;
    },

    view: function(user) {
      // find users associated with selected supervisor
      this.users.selectedData = [];

      for(let u = 0; u < this.users.rowData.length; u = u + 1) {
        if (this.users.rowData[u].reportsTo == user.username) {
          // and add their details to the table
          this.users.selectedData.push({
            username:       this.users.rowData[u].username,
            firstLastName:  this.users.rowData[u].firstLastName,
            email:          this.users.rowData[u].email,
            projectName:    this.users.rowData[u].projectName,
            startDate:      this.users.rowData[u].startDate,
            submitted:      this.users.rowData[u].submitted,
            reportsTo:      this.users.rowData[u].reportsTo,
            billable:       this.users.rowData[u].billable,
          });
        }
      }

      this.viewModal = true;
    },

    clearData: function() {
      this.supervisors.rowData = [];
      this.supervisors.superData = [];
      this.users.rowData = [];
      this.users.userData = [];
      this.assignedContracts.rowData = [];
      this.contracts.rowData = [];
      this.reports.rowData = [];
    }
  },
  mounted() {
    this.populateTable();
  }
}
</script>