<template>
  <div class="page-start">
		<mdb-row class='justify-content-center'>
			<mdb-col col='10'>
			<mdb-card>
				<h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Edit Home Page</h3>
				<mdb-card-body>

					<!-- Submission Alerts -->
					<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
						Success! Home page is updated!
					</mdb-alert>
					<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
						There was an error updating home page! Please try again!
					</mdb-alert>
					<mdb-alert id="feedback-success" class="mt-3" color="info" v-if="p3" @closeAlert="p3=false" dismiss>
						<mdb-spinner multicolor/>
					</mdb-alert>
					<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p4" @closeAlert="p4=false" dismiss>
						The chosen picture is too large, maximum file size is 1MB!
					</mdb-alert>
					<!-- /Submission Alerts -->

					<form @submit.prevent="checkForm" novalidate>
						<div class= 'grey-text text-left'>
							<mdb-container>
								<hr>
								<h3 class="h3-responsive text-center">Employee Spotlight & Book of the Month</h3>
								<hr>
								<mdb-row>
									<mdb-col>
										<QFileInput @getValue="getSpotlightPhoto" class="editHomePics" btnColor="mdb-color" btn-title="Edit Photo" rounded img :src="formData.spotlightPhoto" />
										<mdb-input sm label='Employee Spotlight Title' v-model="formData.spotlightTitle" type='text' required />
										<mdb-input sm label='Employee Spotlight Description' v-model="formData.spotlightDesc" outline type='textarea' :rows="8" required />
									</mdb-col>
									<mdb-col>
										<QFileInput @getValue="getBookPhoto" class="editHomePics" btnColor="mdb-color" btn-title="Edit Photo" rounded img :src="formData.bookOfTheMonthPhoto" />
										<mdb-input sm label='Book of the Month Title' v-model="formData.bookOfTheMonthTitle" type='text' required />
										<mdb-input sm label='Book of the Month Description' v-model="formData.bookOfTheMonthDesc" outline type='textarea' :rows="8" required />
									</mdb-col>
								</mdb-row>
								<hr>
								<h3 class="h3-responsive text-center">Carousel Photos</h3>
								<hr>
								<mdb-row class="d-flex justify-content-center mb-3">
									<div v-for="(photo,index) in formData.carousel" :key="index">
										<mdb-col class="text-center">
											<QFileInput :id="index" @getValue="getCarousel" class="editHomePics" :position="index" btnColor="mdb-color" btn-title="Edit Photo" rounded img :src="photo" />
											<mdb-btn v-on:click="removeCarouselPhoto(index)" class="mt-n3 mr-3" color="danger" rounded>Remove</mdb-btn>
										</mdb-col>
									</div>
								</mdb-row>

								<!-- Carousel Validation Feedback -->
								<div id="carouselValidation" style="display: none;">
									<br>
									<p style="color: red; font-size: 0.8em;" class="text-center">Please edit the placeholder image or remove it before submitting.</p>
								</div>
								<!-- /Carousel Validation Feedback -->

								<mdb-btn size="block" class="mb-4" rounded v-on:click="addCarouselPhoto">Add Carousel Photo</mdb-btn>
								<hr>
								<mdb-btn size="block" class="mt-4" color="primary" type="submit" v-if="!submitProcessing">Submit Changes</mdb-btn>
                <mdb-btn size="block" class="mt-4" color="primary" v-if="submitProcessing">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Processing...
                </mdb-btn>
							</mdb-container>
						</div>
					</form>
				</mdb-card-body>
			</mdb-card>
			</mdb-col>
		</mdb-row>
  </div>
</template>

<script>
import {mdbRow, mdbCol, mdbCard, mdbCardBody, mdbContainer, mdbInput, mdbBtn, mdbAlert, mdbSpinner} from "mdbvue";
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';
import QFileInput from '@/components/QFileInput.vue'

export default {
  name: 'Edit-Homepage',
  components: {
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardBody,
		mdbContainer,
		mdbInput,
		QFileInput,
		mdbAlert,
		mdbBtn,
		mdbSpinner
  },
	data() {
		return {
			formData: {
				spotlightTitle: '',
				spotlightDesc: '',
				spotlightPhoto: '',
				bookOfTheMonthTitle: '',
				bookOfTheMonthDesc: '',
				bookOfTheMonthPhoto: '',
				carousel: []
			},
			
			newPhotos: {
				spotlightPhoto: { file: null },
				bookOfTheMonthPhoto: { file: null },
			},

			homePageId: '',

			preCarouselLength: 0,

			tempCarousel: [],

      submitProcessing: false, 

			p1: false,
			p2: false,
			p3: false,
			p4: false
		}
	},
	methods: {

		//===========================================================================================
		//	Method Name	:	addCarouselPhoto
		//	
		//  Parameters
		//  Name					Type			Direction	Description
		//  ----------------------	--------------	---------	-------------------------------------
		//  Return Value            void            n/a         n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Adds an entry to the carousel photo array
		//===========================================================================================
		addCarouselPhoto: function() {
			for (let i = 0; i < this.formData.carousel.length; i = i + 1)
			{
				if (typeof(this.tempCarousel[i]) != String)
				{
					this.formData.carousel[i] = document.getElementById(i).getElementsByTagName('div')[0].getElementsByTagName('img')[0].src;
				}
			}
			this.formData.carousel.push('addPhotoHere.png');
			this.tempCarousel.push('addPhotoHere.png');
		},

	//===========================================================================================
	//	Method Name	:	checkForm
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	----------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Checks the form that there are no blank input fields and sanitizes the form data to be
	//  passed to the submission function
	//===========================================================================================
		checkForm: function() {
			this.p3 = true;
			if (this.tempCarousel.includes('addPhotoHere.png'))
			{
				document.getElementById("carouselValidation").style.display = "block";
				this.p3 = false;
				return;
			}

			document.getElementById("carouselValidation").style.display = "none";

			this.formData.spotlightTitle = this.sanitizeInput(this.formData.spotlightTitle);
			this.formData.spotlightDesc = this.sanitizeInput(this.formData.spotlightDesc);
			this.formData.bookOfTheMonthTitle = this.sanitizeInput(this.formData.bookOfTheMonthTitle);
			this.formData.bookOfTheMonthDesc = this.sanitizeInput(this.formData.bookOfTheMonthDesc);

      this.submitProcessing = true;
			this.submitChanges();
			this.p3 = false;
		},

    //===========================================================================================
	//	Method Name	:	getBookPhoto
	//	
	//  Parameters
	//  Name					Type			Direction		Description
	//  ----------------------	--------------	---------		-------------------------------------
	//  Return Value            void            n/a	    	    n/a
	//  Val                     Object          in  	        Holds the file for the book of the month
	//                                                      	photo
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sets the form data to the inputed file for the book of the month
	//===========================================================================================
		getBookPhoto (val) {
			if (val.file[0].size < 1048855) {
				this.newPhotos.bookOfTheMonthPhoto.file = val.file[0];
			}
			else {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p4 = true;
			}
		},

    //===========================================================================================
	//	Method Name	:	getCarouselPhoto
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  Val                     Object          in          Holds the file for the carousel photos
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sets the form data to the inputed file for the carousel
	//===========================================================================================
		getCarousel (val) {
			if (val.file[0].size < 1048855) {
				this.tempCarousel[val.position] = val.file[0];
			}
			else {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p4 = true;
			}
		},

    //===========================================================================================
	//	Method Name	:	getSpotlightPhoto
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  Val                     Object          in          Holds the file for the employee spotlight
	//                                                      photo 
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sets the form data to the inputed file for the employee spotlight
	//===========================================================================================
		getSpotlightPhoto (val) {
			if (val.file[0].size < 1048855) {
				this.newPhotos.spotlightPhoto.file = val.file[0];
			}
			else {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p4 = true;
			}
		},

    //===========================================================================================
	//	Method Name	:	removeCarouselPhoto
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  Val                     Int             in          Holds the index of the carousel photo
	//                                                      to remove
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Removes a slot in the carousel photo array
	//===========================================================================================
		removeCarouselPhoto (val) {
			for (let i = 0; i < this.formData.carousel.length; i = i + 1)
			{
				if (typeof(this.tempCarousel[i]) != String)
				{
					this.formData.carousel[i] = document.getElementById(i).getElementsByTagName('div')[0].getElementsByTagName('img')[0].src;
				}
			}
			this.formData.carousel.splice(val, 1);
			this.tempCarousel.splice(val, 1);
		},

    //===========================================================================================
	//	Method Name	:	populateFields
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Populates the page fields with the current data. Called on mount.
	//===========================================================================================
		populateFields: async function()
		{
			let token = this.$store.getters.getToken;
			let query = "sort[0]=id:asc&populate[1]=spotlightPhoto&populate[2]=bookOfTheMonthPhoto&populate[3]=carousel";
			let response = await DatabaseLib.getRequest('strapiDatabase','home-pages',token, query);
			this.homePageId = response.data.data[0].id;

			this.formData.spotlightTitle = response.data.data[0].attributes.spotlightTitle;
			this.formData.spotlightDesc = response.data.data[0].attributes.spotlightDescription;
			this.formData.spotlightPhoto = DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) + response.data.data[0].attributes.spotlightPhoto.data.attributes.url;
			this.formData.bookOfTheMonthTitle = response.data.data[0].attributes.bookOfTheMonthTitle;
			this.formData.bookOfTheMonthDesc = response.data.data[0].attributes.bookOfTheMonthDesc;
			this.formData.bookOfTheMonthPhoto = DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) + response.data.data[0].attributes.bookOfTheMonthPhoto.data.attributes.url;
			
			response.data.data[0].attributes.carousel.data.forEach(photo => {
				this.formData.carousel.push(DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1) + photo.attributes.url);
				this.tempCarousel.push(photo.id);
			})

			this.preCarouselLength = this.tempCarousel.length;			// Saves carousel length on load in case some are deleted
		},

    //===========================================================================================
	//	Method Name	:	sanitizeInput
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//	content					Any				IN			Input to be sanitized for DB entry.
	//  Return Value			Any				Out			Returned sanitized input
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Sanitizes form input and returns the sanitized data
	//===========================================================================================
		sanitizeInput(content) 
		{
      let input = this.$sanitize(content);
      input = input.replace(/&amp;/g, "&");
			return input;
    },

    //===========================================================================================
	//	Method Name	:	submitChanges
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Called after the form has been verified. Submits the form data to the database.
	//===========================================================================================
		submitChanges: async function()
		{
			let token = this.$store.getters.getToken;			// JWT Bearer Token for Strapi requests
			let response = null;								// Holds Strapi responses after sending a request
			let newFiles = [];									// Holds the array positions of new files in tempCarousel that need to be added to database
			let newFileIds = [];								// Holds the IDs of new files after being added to database
			let counter = 0;									// Counter that iterates the position in newFileIds when replacing tempCarousel files with new IDs

			let holdOldData = "sort[0]=id:asc&populate[1]=spotlightPhoto&populate[2]=bookOfTheMonthPhoto&populate[3]=carousel";
			let response2 = await DatabaseLib.getRequest('strapiDatabase','home-pages',token, holdOldData);
			
			let data = {
				data:{
					spotlightTitle: this.formData.spotlightTitle,
					spotlightDescription: this.formData.spotlightDesc,
					bookOfTheMonthTitle: this.formData.bookOfTheMonthTitle,
					bookOfTheMonthDesc: this.formData.bookOfTheMonthDesc
				}
			};
			try {
				await DatabaseLib.putRequest('strapiDatabase','home-pages',token,this.homePageId,data);
			} catch {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p2 = true;
				this.submitProcessing = false;
				return;
			}

			// Sets Employee Spotlight Photo
			if (this.newPhotos.spotlightPhoto.file !== null)
			{
				let imageUpload = new FormData();
				imageUpload.append('files', this.newPhotos.spotlightPhoto.file, 'employeeSpotlight.png');
				imageUpload.append('ref', 'home-page');
				imageUpload.append('refId', this.homePageId);
				imageUpload.append('field', 'spotlightPhoto');
				
				response = await DatabaseLib.postRequest('strapiDatabase','upload',token,imageUpload);
				
				if (response instanceof Error)
				{
					window.scroll({top: 0, behavior: 'smooth'});
					this.p2 = true;
					this.submitProcessing = false;
					return;
				}
				let oldImage = response2.data.data[0].attributes.spotlightPhoto.data.id;
				console.log(oldImage);
				data = {
					data:{
						spotlightPhoto: response.data
					}
				}
				response = await DatabaseLib.putRequest('strapiDatabase','home-pages',token,this.homePageId,data);
				await DatabaseLib.deleteRequest('strapiDatabase','upload/files',token,oldImage);
			}

			// Sets Book of the Month Photo
			if (this.newPhotos.bookOfTheMonthPhoto.file !== null)
			{
				let imageUpload = new FormData();
				imageUpload.append('files', this.newPhotos.bookOfTheMonthPhoto.file, 'bookOfTheMonthPhoto.png');
				imageUpload.append('ref', 'home-page');
				imageUpload.append('refId', this.homePageId);
				imageUpload.append('field', 'bookOfTheMonthPhoto');
				
				response = await DatabaseLib.postRequest('strapiDatabase','upload',token,imageUpload);
				if (response instanceof Error)
				{
					window.scroll({top: 0, behavior: 'smooth'});
					this.p2 = true;
					this.submitProcessing = false;
					return;
				}
				data = {
					data:{
						bookOfTheMonthPhoto: response.data
					}
				}
				response = await DatabaseLib.putRequest('strapiDatabase','home-pages',token,this.homePageId,data);
				let oldImage = response2.data.data[0].attributes.bookOfTheMonthPhoto.data.id;
				await DatabaseLib.deleteRequest('strapiDatabase','upload/files',token,oldImage);
			}

			// Sets Carousel Photos
			for (let i = 0; i < this.tempCarousel.length; i = i + 1)
			{
				if (this.tempCarousel[i] instanceof File)
				{
					newFiles.push(i);
				}
			}

			// If new photos exist, add to database and update carousel
			if (newFiles.length > 0)
			{
				// Upload new photos to generate their IDs in the database
				for (let i = 0; i < newFiles.length; i = i + 1)
				{
					let imageUpload = new FormData();
					
					imageUpload.append('files', this.tempCarousel[newFiles[i]], 'carouselPhoto.png');

					response = await DatabaseLib.postRequest('strapiDatabase','upload',token,imageUpload);
					
					if (response instanceof Error)
					{
						window.scroll({top: 0, behavior: 'smooth'});
						this.p2 = true;
						this.submitProcessing = false;
						return;
					}
					
					newFileIds.push(response.data[0].id);
				}

				// Update Carousel with new photo Ids
				for (let i = 0; i < this.tempCarousel.length; i = i + 1)
				{
					if (this.tempCarousel[i] instanceof File)
					{
						this.tempCarousel[i] = newFileIds[counter];			//Replaces Photos with their corresponding IDs
						counter++;
					}
				}

				// Upload Carousel changes to Home Page
				data = {
					data:{
						carousel: this.tempCarousel
					}
				};

				response = await DatabaseLib.putRequest('strapiDatabase','home-pages',token,this.homePageId,data);

				let oldImage = '';
				for(let j = 0; j < response2.data.data[0].attributes.carousel.data.length; j = j + 1){
					if (!data.data.carousel.includes(response2.data.data[0].attributes.carousel.data[j].id))
					{
						oldImage = response2.data.data[0].attributes.carousel.data[j].id;
						await DatabaseLib.deleteRequest('strapiDatabase','upload/files',token,oldImage);
					}
				}
				if (response instanceof Error)
				{
					window.scroll({top: 0, behavior: 'smooth'});
					this.p2 = true;
					this.submitProcessing = false;
					return;
				}
			}
			else if (this.preCarouselLength > this.tempCarousel.length)			// If no new photos but at least one photo removed
			{
				// Upload Changes to Carousel
				data = {
					data: {
						carousel: this.tempCarousel
					}
				};

				response = await DatabaseLib.putRequest('strapiDatabase','home-pages',token,this.homePageId,data);
				let oldImage = '';
				for(let j = 0; j < response2.data.data[0].attributes.carousel.data.length; j = j + 1){
					if (!data.data.carousel.includes(response2.data.data[0].attributes.carousel.data[j].id))
					{
						oldImage = response2.data.data[0].attributes.carousel.data[j].id;
						await DatabaseLib.deleteRequest('strapiDatabase','upload/files',token,oldImage);
					}
				}
				if (response instanceof Error)
				{
					window.scroll({top: 0, behavior: 'smooth'});
					this.p2 = true;
					this.submitProcessing = false;
					return;
				}
			}
					

			// If everything is successful
			window.scroll({top: 0, behavior: 'smooth'});
			this.p1 = true;
			this.submitProcessing = false;
		}
	},

	mounted() {
		this.populateFields();
	}
};
</script>
<style>
form.editHomePics img 
{
		height: 250px !important;
		width: auto !important;
}

</style>
