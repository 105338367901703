<template>

	<div class="page-start">
		<mdb-row class="justify-content-center">
			<mdb-col col="10">
				<mdb-card class="mb-3">
					<h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Employee Directory</h3>
					<mdb-card-body>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
							There was an error retrieving users from server! Please try again later.
						</mdb-alert>
						<mdb-col md="2" offsetMd="0">
							<mdb-input type="text" v-model="filter" placeholder="Search" />
						</mdb-col>
						<div v-for="user in users.displayData" :key="user.id">
							<hr>
							<mdb-row class="mb-3">
								<hr>
									<mdb-col col='4' class="">
										<img :src="user.profilePic" class="z-depth-1 img-fluid" style="height: 175px;" round alt="Sample avatar"/>
									</mdb-col>
									<mdb-col col='4' class="">
										<h4 class="font-weight-bold mb-3">{{ user.firstName + ' ' + user.lastName}}</h4>
										<h6 class="font-weight-bold grey-text mb-3">{{ user.title }}</h6>
										<p class="grey-text">
											Email: {{ user.email }}<br>
											Office Phone #: {{ user.workPhone }}<br>
											Work Cell #: {{ user.workCellPhone }}<br>
											Divison: {{ user.division }}
										</p>
									</mdb-col>
								<hr>
							</mdb-row>
						</div>
						<hr>
					</mdb-card-body>
				</mdb-card>
			</mdb-col>
		</mdb-row>
	</div>
	
</template>

<script>

import {mdbCol, mdbRow, mdbCard, mdbCardBody, mdbAlert, mdbInput } from 'mdbvue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';


export default {
	name:"Employee-Directory",
	components: {
		mdbCol,
		mdbRow,
		mdbCard,
		mdbCardBody,
		mdbAlert,
		mdbInput
	},
	data() {
		return {
			users: {
				rowData: [],
				displayData: [],
			},
			filter: '',
			temp: 0,
			p1: false,
		}
	},

	methods: {

		populateTable: async function()
		{
			try {
				this.users.rowData = [];
				this.users.displayData = [];
				let avatarUrl = DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1);
				let token = this.$store.getters.getToken;

				let query = 'filters[blocked][$eq][0]='+false+'&populate[1]=role&populate[2]=profilePic&populate[3]=groups';
				let response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
				
				response.data.forEach(userRow => {
					if(userRow.role.name != "Administrator"){
						this.users.rowData.push({
							id: userRow.id,
							firstName: userRow.firstName,
							lastName: userRow.lastName,
							email: userRow.email,
							workPhone: userRow.workPhone,
							workCellPhone: userRow.workCellPhone,
							title: userRow.title,
							division: userRow.division,
							profilePic: avatarUrl + userRow.profilePic[0].url,
							groups: userRow.groups
						})
					}
				})
				for (let i = 0; i < this.users.rowData.length; i = i + 1) {
					this.users.displayData.push(this.users.rowData[i]);
				}
						
			/*	query = "filters[name][$eq][0]=Administration";
				let filter = await DatabaseLib.getRequest('strapiDatabase','groups',token,query);
				
				for (let i = 0; i < this.users.rowData.length; i = i + 1) {
					let match = false;
					if(filter.data.data != ''){
						for(let z = 0; z < this.users.rowData[i].groups.length; z = z + 1){
							if (this.users.rowData[i].groups[z].name == filter.data.data[0].attributes.name) { 
								match = true; 
								console.log(this.users.rowData[i])
								this.users.rowData.pop(this.users.rowData[i]);
							}
						}
					}					
					if (!match) {
						this.users.displayData.push(this.users.rowData[i]);
					}
				}*/

				// Sorts Employees by Last Name
				this.users.displayData.sort(function(a, b) {
					let keyA = a.lastName,
						keyB = b.lastName;
					// Compare the 2 Names
					if (keyA < keyB) return -1;
					if (keyA > keyB) return 1;
					return 0;
				});
			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p1 = true;
				console.log(error)
				return;		
			}
			return 0;
		},

		// i = user
		filterTable: function() {
			if (this.filter) {
				this.users.displayData = [];	// clear the displayed data
				let gotMatch = false;
				for (let i = 0; i < this.users.rowData.length; i = i + 1) {	// filter through all users
					if(this.users.rowData[i].lastName != null && this.users.rowData[i].firstName != null){
						let temp = this.users.rowData[i].firstName.toString().toLowerCase() + " " + this.users.rowData[i].lastName.toString().toLowerCase();
						if(temp.includes(this.filter.toString().toLowerCase())){
							gotMatch = true;
						}
					}
					if(this.users.rowData[i].email != null){
						if(this.users.rowData[i].email.toString().toLowerCase().includes(this.filter.toString().toLowerCase())){
							gotMatch = true;
						}
					}
					if(this.users.rowData[i].title != null){
						if(this.users.rowData[i].title.toString().toLowerCase().includes(this.filter.toString().toLowerCase())){
							gotMatch = true;
						}
					}
					if(this.users.rowData[i].division != null){
						if(this.users.rowData[i].division.toString().toLowerCase().includes(this.filter.toString().toLowerCase())){
							gotMatch = true;
						}
					}
					if(this.users.rowData[i].workPhone != null){
						if(this.users.rowData[i].workPhone.toString().toLowerCase().includes(this.filter.toString().toLowerCase())){
							gotMatch = true;	
						}
					}
					if(this.users.rowData[i].workCellPhone != null){
						if(this.users.rowData[i].workCellPhone.toString().toLowerCase().includes(this.filter.toString().toLowerCase())){
							gotMatch = true;
						}
					}
					if(gotMatch){this.users.displayData.push(this.users.rowData[i]);}
				}
			}
			else {	// if there is no filter
				this.users.displayData = this.users.rowData;	// restore full list
				this.users.displayData.sort(function(a, b) {	// sort the list by last name
					let keyA = a.lastName,
						keyB = b.lastName;
					// Compare the 2 Names
					if (keyA < keyB) return -1;
					if (keyA > keyB) return 1;
					return 0;
				});
			}
		}

	},

	watch: {
		filter: function() {
			this.filterTable();
		}
	},

	mounted()
	{
		this.populateTable();
	}
}

</script>
