import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'


Vue.config.productionTip = false
Vue.prototype.$http = axios;
const token = localStorage.getItem('token')

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

import * as Notify from 'mdbvue/lib/components/Notify'
Vue.use(Notify)

import * as VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
