<template>

	<div class="page-start">
		
		<mdb-row class="justify-content-center">
			<mdb-col col="10">
				<mdb-card class="mb-3">
					<h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">User Table</h3>
					<mdb-card-body>
						<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
							There was an error retrieving users from server! Please try again later.
						</mdb-alert>
						<mdb-input type="checkbox" id="showDisable" name="showDisable" @change="populateDisabled" label="Display Disabled Users" />

            <!-- NON-Disabled Users Table -->
						<QDataTable v-if="!showDisabled" :filterWords="this.users.filterWords" :rowData="this.users.rowData" :headers="this.users.columns"
							:hasBtn="true" :btnName="'View'" @use-method="view"
							:hasBtn2="true" :btnName2="'Edit'" @use-method-2="edit"
							:hasBtn3="true" :btnName3="'Reset Password'" @use-method-3="view"
						/>
            <!-- -->

            <!-- Disabled Users Table -->
						<QDataTable v-if="showDisabled" :filterWords="this.disabledUsers.filterWords" :rowData="this.disabledUsers.rowData" :headers="this.disabledUsers.columns"
							:hasBtn="true" :btnName="'View'" @use-method="view"
							:hasBtn2="true" :btnName2="'Edit'" @use-method-2="edit"
						>
							<h5><u>You are ENABLING user {{ this.selectedUser.firstName + ' ' + this.selectedUser.lastName }} </u></h5>
							<h5>Enabling a user will do the following:</h5>
							<hr>
								<ul class="text-left">
									<li>They will be able to log in again.</li>
									<li>They will need to be reassigned user access levels.</li>
									<li>They will reappear as an option in charge line assignments.</li>
								</ul>
							<hr>
							<h5>Do you wish to continue?</h5>
						</QDataTable>
            <!-- -->

						<!-- View User Modal -->
						<mdb-modal size="lg" centered :show="viewModal" @close="viewModal = false">
							<mdb-modal-header>
								<mdb-modal-title><p class="h2 grey-text">User Information</p></mdb-modal-title>
							</mdb-modal-header>
							<mdb-modal-body style="font-size: 0.9em;">
								<img :src="selectedUser.profilePic" class="rounded-circle img-fluid" style="width: 100px" />
								<mdb-list-group flush>
									<mdb-row>
										<mdb-col>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Name:</mdb-col>
														<mdb-col>{{ selectedUser.firstName + ' ' + selectedUser.lastName }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Email (Work)</mdb-col>
														<mdb-col>{{ selectedUser.email }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Phone # (Office)</mdb-col>
														<mdb-col>{{ selectedUser.workPhone }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
										</mdb-col>
										<mdb-col>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Username:</mdb-col>
														<mdb-col>{{ selectedUser.username }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Title</mdb-col>
														<mdb-col>{{ selectedUser.title }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Division</mdb-col>
														<mdb-col>{{ selectedUser.division }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Direct Report</mdb-col>
														<mdb-col>{{ selectedUser.reportsTo }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>DOB</mdb-col>
														<mdb-col>{{ this.selectedUser.dob }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
											<mdb-list-group-item>
												<mdb-container class="text-left">
													<mdb-row>
														<mdb-col>Real Color Result</mdb-col>
														<mdb-col>{{ this.selectedUser.realColor }}</mdb-col>
													</mdb-row>
												</mdb-container>
											</mdb-list-group-item>
										</mdb-col>
									</mdb-row>
								</mdb-list-group>
							</mdb-modal-body>

							<mdb-modal-footer class="d-flex justify-content-center">
								<mdb-btn color="secondary" @click.native="viewModal = false">Close</mdb-btn>
							</mdb-modal-footer>
						</mdb-modal>

						<mdb-modal size="lg" centered :show="resetPasswordFlag" @close="resetPasswordFlag = false">
							<mdb-modal-header>
								<mdb-modal-title><p class="h2 grey-text">Reset Password</p></mdb-modal-title>
							</mdb-modal-header>
							<mdb-modal-body style="font-size: 0.9em;">
								
							</mdb-modal-body>

							<mdb-modal-footer class="d-flex justify-content-center">
								<mdb-btn color="secondary" @click.native="viewModal = false">Close</mdb-btn>
							</mdb-modal-footer>
						</mdb-modal>

						<!-- View User Modal -->
					</mdb-card-body>
				</mdb-card>
			</mdb-col>
		</mdb-row>
	</div>
	
</template>

<script>

import {mdbCol, mdbRow, mdbModal, mdbModalHeader, mdbModalBody, mdbModalFooter, mdbModalTitle, mdbBtn, mdbListGroup, mdbListGroupItem, mdbContainer, mdbInput, mdbCard, mdbCardBody, mdbAlert} from 'mdbvue';
import QDataTable from "@/components/QDataTable.vue";
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';


export default {
	name:"ViewEditUser",
	components: {
		mdbContainer,
		mdbCol,
		mdbRow,
		mdbModal,
		mdbModalHeader,
		mdbModalTitle,
		mdbModalBody,
		mdbModalFooter,
		mdbBtn,
		mdbListGroup,
		mdbListGroupItem,
		mdbInput,
		mdbCard,
		mdbCardBody,
		mdbAlert,
		QDataTable
	},
	data() {
		return {
			users: {
				columns: [
					{
						colTitle: 'First Last Name',
						sortBy: 'firstLastName'
					},
					{
						colTitle:'Username',
						sortBy: 'username'
					},
					{
						colTitle: 'Email',
						sortBy: 'email',
					},
					{
						colTitle: 'Action'
					},
				],
				rowData: [],
				filterWords: [
					'firstLastName',
					'username',
					'email'
				],
			},

			disabledUsers: {
				columns: [
					{
						colTitle: 'First Last Name',
						sortBy: 'firstLastName'
					},
					{
						colTitle:'Username',
						sortBy: 'username'
					},
					{
						colTitle: 'Email',
						sortBy: 'email',
					},
					{
						colTitle: 'Action'
					}
				],
				rowData: [],
				filterWords: [
					'firstLastName',
					'username',
					'email'
				],
			},

			selectedUser: 
			{
				id: '',
				username: '',
				email: '',
				firstName: '',
				lastName: '',
				workPhone: '',
				title: '',
				division: '',
				reportsTo: '',
				dob: '',
				role: '',
				groups: [],
				realColor: '',
				profilePic: ''
			},

			viewModal: false,
			showDisabled: false,
			resetPasswordFlag: false,

			p1: false,
		}
	},

	methods: {
    //===========================================================================================
	//	Method Name	:	edit
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  user                    Object          In          Contains the selected user's data
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Re-routes page to the Edit-User page and passing along the selected user's ID
	//===========================================================================================
		edit: function(user)
		{
			this.$router.push('/Edit-User/' + user.id);
		},

    //===========================================================================================
	//	Method Name	:	populateTable
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Populates the main table with all the user's data, providing the option to view, edit, or
	//  reset password for each
	//===========================================================================================
		populateTable: async function()
		{
			try {
				this.users.rowData = [];

				let avatarUrl = DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1);

				let token = this.$store.getters.getToken;
				let query = 'filters[blocked][$eq][0]='+false+'&populate[1]=role&populate[2]=profilePic&populate[3]=groups';

				let response = await DatabaseLib.getRequest('strapiDatabase','users',token,query);
				
				response.data.forEach(userRow => {
					if(userRow.role.name != "Administrator"){
						this.users.rowData.push({
							id: userRow.id,
							firstName: userRow.firstName,
							lastName: userRow.lastName,
							firstLastName: userRow.firstName + ' ' + userRow.lastName,
							username: userRow.username,
							email: userRow.email,
							role: userRow.role.name,
							dob: userRow.dob,
							workPhone: userRow.workPhone,
							title: userRow.title,
							division: userRow.division,
							reportsTo: userRow.reportsTo,
							groups: userRow.groups,
							realColor: userRow.realColor,
							profilePic: avatarUrl + userRow.profilePic[0].url
						})
					}
				});
			} catch(error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p1 = true;	
				return;
			}
			return 0;
		},

    //===========================================================================================
	//	Method Name	:	view
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//  user                    Object          in          contains the selected user's data
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Populates the selected user's data and enables the View User modal to view the data
	//===========================================================================================
		view: function(user)
		{
			this.selectedUser.id = user.id;
			this.selectedUser.username = user.username;
			this.selectedUser.email = user.email;
			this.selectedUser.firstName = user.firstName;
			this.selectedUser.lastName = user.lastName;
			this.selectedUser.workPhone = user.workPhone;
			this.selectedUser.title = user.title;
			this.selectedUser.division = user.division;
			this.selectedUser.reportsTo = user.reportsTo;
			this.selectedUser.dob = user.dob;
			this.selectedUser.role = user.role;
			this.selectedUser.groups = user.groups;
			this.selectedUser.realColor = user.realColor;
			this.selectedUser.profilePic = user.profilePic;

			let dateOfBirth = new Date(this.selectedUser.dob);
			if(this.selectedUser.dob != null){this.selectedUser.dob = (dateOfBirth.getMonth() + 1) + '/' + dateOfBirth.getUTCDate() + '/' + dateOfBirth.getFullYear();}
			this.viewModal = true;
		},


		resetPassword: function(user)
		{
			this.selectedUser.id = user.id;
			this.selectedUser.username = user.username;
			this.selectedUser.email = user.email;
			this.selectedUser.firstName = user.firstName;
			this.selectedUser.lastName = user.lastName;
			this.selectedUser.workPhone = user.workPhone;
			this.selectedUser.title = user.title;
			this.selectedUser.division = user.division;
			this.selectedUser.reportsTo = user.reportsTo;
			this.selectedUser.dob = user.dob;
			this.selectedUser.role = user.role;
			this.selectedUser.groups = user.groups;
			this.selectedUser.realColor = user.realColor;
			this.selectedUser.profilePic = user.profilePic;

			let dateOfBirth = new Date(this.selectedUser.dob);
			if(this.selectedUser.dob != null){this.selectedUser.dob = (dateOfBirth.getMonth() + 1) + '/' + dateOfBirth.getUTCDate() + '/' + dateOfBirth.getFullYear();}
			
			//this.resetPasswordFlag = true;
			this.viewModal = true;
		},

    //===========================================================================================
	//	Method Name	:	populateDisabled
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Populates and enables the table for user's with disabled accounts.  This table still 
	//  allows for viewing and editing of the user data
	//===========================================================================================
		populateDisabled: async function()
		{
			if (this.showDisabled == false)
			{
				try {
					this.disabledUsers.rowData = [];

					let avatarUrl = DatabaseLib.getEndpoint('strapiDatabase').slice(0, -1);

					let token = this.$store.getters.getToken;
					let query = 'filters[blocked][$eq][0]='+true+'&populate[1]=role&populate[2]=profilePic&populate[3]=groups';

					let response = await DatabaseLib.getRequest('strapiDatabase','users',token, query);
					response.data.forEach(userRow => {
							this.disabledUsers.rowData.push({
									id: userRow.id,
									firstName: userRow.firstName,
									lastName: userRow.lastName,
									firstLastName: userRow.firstName + ' ' + userRow.lastName,
									username: userRow.username,
									email: userRow.email,
									role: userRow.role.name,
									dob: userRow.dob,
									workPhone: userRow.workPhone,
									title: userRow.title,
									division: userRow.division,
									reportsTo: userRow.reportsTo,
									groups: userRow.groups,
									realColor: userRow.realColor,
									profilePic: avatarUrl + userRow.profilePic[0].url
							})
					});
					this.showDisabled = true;
				} catch (error) {
					window.scroll({top: 0, behavior: 'smooth'});
					this.p1 = true;
					return;
				}

			}
			else
			{
				this.populateTable();
				this.showDisabled = false;
			}
			
		}
	},

	mounted()
	{
		this.populateTable();
	}
}

</script>
