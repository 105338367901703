<template>
<div class='login'>
	<mdb-container>
		<mdb-row class="justify-content-center">
		<mdb-col col="10">
		<img src="@/assets/Quadrus Logo color hz.png" class="img-fluid logo" alt="Quadrus Corporation logo">
		</mdb-col>
		</mdb-row>
	</mdb-container>
		
	<mdb-row class="justify-content-center">
		<mdb-col col="6">
		<mdb-card>
			<mdb-card-body>
				<mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
					{{ feedback }}
				</mdb-alert>
				<mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
					{{ feedback }}
				</mdb-alert>
				<!-- Material form login -->
				<form @submit.prevent="checkForm" novalidate>
					<p class="h4 text-center mb-4">Reset Password</p>
					<div class="grey-text" style="text-align: justify">
						
						<!-- Current Password -->
						<mdb-input v-model="validationValues.currentPass" label="Current Password" icon="unlock" type="password"
							:customValidation="validation.currentPass.validated"
							:isValid="validation.currentPass.valid"
							invalidFeedback="Please enter current password" validFeedback="Looks Good!"
						/>
						
						<!-- New Password -->
						<mdb-input v-model="validationValues.newPass" label="New Password" icon="user-lock" type="password" @change="checkPassword()" required
							:customValidation="validation.newPass.validated"
							:isValid="validation.newPass.valid"
							invalidFeedback="Password must meet complexity requirements" validFeedback="Looks Good!"
						/>
						<a class='' style="color: #ff4444;" v-if="!passwordLength"><mdb-icon icon="exclamation-circle" />At least 10 Characters Long (30 max)</a>
						<a class='' style="color: #00C851" v-if="passwordLength"><mdb-icon icon="check-circle" />At least 10 Characters Long (30 max)</a>
						<br>
						<a class='' style="color: #ff4444" v-if="!passwordSpecial"><mdb-icon icon="exclamation-circle" />At least 1 Special Character, i.e. !, @, #, $, etc.</a>
						<a class='' style="color: #00C851" v-if="passwordSpecial"><mdb-icon icon="check-circle" />At least 1 Special Character, i.e. !, @, #, $, etc.</a>
						<br>
						<a class='' style="color: #ff4444" v-if="!passwordUpper"><mdb-icon icon="exclamation-circle" />At least 1 Uppercase Character</a>
						<a class='' style="color: #00C851" v-if="passwordUpper"><mdb-icon icon="check-circle" />At least 1 Uppercase Character</a>
						<br>
						<a class='' style="color: #ff4444" v-if="!passwordLower"><mdb-icon icon="exclamation-circle" />At least 1 Lowercase Character</a>
						<a class='' style="color: #00C851" v-if="passwordLower"><mdb-icon icon="check-circle" />At least 1 Lowercase Character</a>
						<br>
						<a class='' style="color: #ff4444" v-if="!passwordDigit"><mdb-icon icon="exclamation-circle" />At least 1 Numeric Character</a>
						<a class='' style="color: #00C851" v-if="passwordDigit"><mdb-icon icon="check-circle" />At least 1 Numeric Character</a>
						
						<!-- Confirm New Password -->
						<mdb-input v-model="validationValues.confirmNewPass" label="Confirm Password" icon="lock" type="password"
							:customValidation="validation.confirmNewPass.validated"
							:isValid="validation.confirmNewPass.valid"
							invalidFeedback="Passwords must match" validFeedback="Looks Good!"
						/>
					</div>
					<div class="text-center">
						<router-link to='/My-Account' class='QuadrusButton btn'>Go Back</router-link>
						<mdb-btn type="submit" class='QuadrusButton'>Reset Password</mdb-btn>
					</div>
				</form>
				<!-- Material form login -->
					</mdb-card-body>
		</mdb-card>
		</mdb-col>
	</mdb-row>
</div>	
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody, mdbCol, mdbRow, mdbContainer, mdbAlert, mdbIcon } from 'mdbvue';
import axios from 'axios';
import { getEndpoint } from '@/assets/js/DatabaseLib.js'

export default {
	name: "ResetPassword",
	components: {
		mdbInput,
		mdbBtn,
		mdbCard,
		mdbCardBody,
		mdbCol,
		mdbRow,
		mdbContainer,
		mdbIcon,
		mdbAlert
	},
	data() {
		return {
			validationValues: {
				currentPass: '',
				newPass: '',
				confirmNewPass: ''
			},

			passwordLength: false,
			passwordSpecial: false,
			passwordUpper: false,
			passwordLower: false,
			passwordDigit: false,

			validation: {
				currentPass: {
					valid: false,
					validated: false
				},
				newPass: {
					valid: false,
					validated: false
				},
				confirmNewPass: {
					valid: false,
					validated: false
				}
			},

			feedback: '',
			p1: false,
			p2: false
		}
	},
	methods: {

    //===========================================================================================
	//	Method Name	:	checkForm
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Validates the form requirements are met, such as the fields are not empty, the password
	//  complexity is met, and the new passwords match, allowing to be submitted.
	//===========================================================================================
		checkForm: async function()
		{
			let isValid = true;

			// Sanitize each key
			await Object.keys(this.validationValues).forEach(key => {
				this.validationValues[key] = this.sanitizeInput(this.validationValues[key]);
			})

			// Validate each key
			await Object.keys(this.validationValues).forEach(async key => {
				this.validate(key);
				if (!this.validation[key].valid)
				{
					isValid = false;
				}
			})

			// If each key passes validation, reset password
			if (isValid)
			{
				this.resetPassword();
			}
		},

    //===========================================================================================
	//	Method Name	:	checkPassword
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Fires when the 'New Password' field is changed. Checks that the new password fits the 
	//  complexity requirements.
	//===========================================================================================
		checkPassword: function()
		{
				let lowerCase = /[a-z]/;
				let upperCase = /[A-Z]/;
				let number = /\d/;
				let special = /[\\!@#\\$%\\^\\&\\*\\(\\)\\-\\_\\=\\+\\|;:'",.\\/\\?`~]+/

        // Length
				if (this.validationValues.newPass.length < 10 || this.validationValues.newPass.length > 30)
				{
					this.passwordLength = false;
				}
				else
				{
					this.passwordLength = true;
				}

        // Lower Case
				if (!lowerCase.test(this.validationValues.newPass))
				{
					this.passwordLower = false;
				}
				else
				{
					this.passwordLower = true;
				}

        // Upper case
				if (!upperCase.test(this.validationValues.newPass))
				{
					this.passwordUpper = false;
				}
				else
				{
					this.passwordUpper = true;
				}

        // Numeric Digit
				if (!number.test(this.validationValues.newPass))
				{
					this.passwordDigit = false;
				}
				else
				{
					this.passwordDigit = true;
				}

        // Special Character
				if (!special.test(this.validationValues.newPass))
				{
					this.passwordSpecial = false;
				}
				else
				{
					this.passwordSpecial = true;
				}

		},

    //===========================================================================================
	//	Method Name	:	resetPassword
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            void            n/a         n/a
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Once the form is validated, this function is called to make a custom call to the 
	//  database to store the new password.
	//===========================================================================================
		resetPassword: async function() 
		{
			let token = this.$store.getters.getToken;
			let data = {
				currentPassword: this.validationValues.currentPass,
				password: this.validationValues.newPass,
				passwordConfirmation: this.validationValues.confirmNewPass
			};
			
			let response = await axios.post(getEndpoint('strapiDatabase') + 'api/auth/change-password', data, { headers: { Authorization: 'Bearer '+ token }})
			.then(response => {
				return response;
			})
			.catch(function (error) {
				return error.response.data.message;
			})
						
			if (typeof response == 'string')
			{
				this.feedback = response;
				this.p2 = true;
			}
			else
			{
				this.p2 = false;
				this.feedback = 'Password change successful!';
				this.p1 = true;
			}
		},

    //===========================================================================================
	//	Method Name	:	sanitizeInput
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            Any             Out         Returns the sanitized input data
	//  Content                 Any             In          A value, normally from a form, to
	//                                                      sanitize
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Takes the content, normally called when looping through a data object, to sanitize
	//  for safe database entry.
	//===========================================================================================
		sanitizeInput: function(content) 
		{
			return this.$sanitize(content)
		},

    //===========================================================================================
	//	Method Name	:	validate
	//	
	//  Parameters
	//  Name					Type			Direction	Description
	//  ----------------------	--------------	---------	-------------------------------------
	//  Return Value            Void            n/a         n/a
	//  Key                     String          In          Part of a key:value pair in the
	//                                                      custom validation to help validate
	//                                                      the form values
	//
	//	Description
	//	-----------------------------------------------------------------------------------------
	//	Checks the form to validate te requirements for submission, such as the fields are not
	//  empty, the password complexities meet the requirements, and the new passwords match.
	//===========================================================================================
		validate: function(key) 
		{
			if(key === 'currentPass') 
			{
				if (this.validationValues['currentPass'] == '') // Current Password must not be empty
				{
					this.validation['currentPass'].valid = false;
				} 
				else 
				{
					this.validation['currentPass'].valid = true;
				}
				this.validation['currentPass'].validated = true;
			}

			if (key === 'newPass') 
			{
				if (this.passwordLength && this.passwordLower && this.passwordUpper && this.passwordDigit && this.passwordSpecial) // Password must meet reqs
				{
					this.validation['newPass'].valid = true;
				} 
				else 
				{
					this.validation['newPass'].valid = false;
				}
				this.validation['newPass'].validated = true;
			}

			if (key === 'confirmNewPass') 
			{
				if (this.validationValues['confirmNewPass'] === this.validationValues['newPass']) // Passwords must match
				{
					this.validation['confirmNewPass'].valid = true;
				} 
				else 
				{
					this.validation['confirmNewPass'].valid = false;
				}
				this.validation['confirmNewPass'].validated = true;
			}
		}

	}
}
</script>

<style scoped>
.logo {
	padding-top: 20px;
	padding-bottom: 20px;
}
.login {
	background-color: #1b365d;
}

</style>