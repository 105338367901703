const docx = require("docx");
const FileSaver = require("file-saver");
const { logoString } =  require('/src/assets/js/logo.js');

module.exports = function(activities, paramNames, paramDivision, paramProjectTask, paramLineItem, paramMonth) {

	let activitiesArray = [];
	let filename = paramProjectTask + " - " + paramLineItem + " " + paramMonth + ".docx";

	const image = new docx.ImageRun({
    data: logoString,
    transformation: {
        width: 600,
        height: 100,
    }
	});

	const table = new docx.Table({
		columnWidths: [4505, 4505],
		rows: [
			new docx.TableRow({
				children: [
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: "Employee Names",
										font: "Calibri",
										size: 22,
										bold: true
									})
								],
							})
						],
					}),
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: paramNames,
										font: "Calibri",
										size: 22
									})
								],
							})
						],
					}),
				]
			}),
			new docx.TableRow({
				children: [
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: "Charge Line / PC Number (if applicable)",
										font: "Calibri",
										size: 22,
										bold: true
									})
								],
							})
						],
					}),
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: paramProjectTask + ' - ' + paramLineItem,
										font: "Calibri",
										size: 22
									})
								],
							})
						],
					}),
				]
			}),
			new docx.TableRow({
				children: [
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: "Division",
										font: "Calibri",
										size: 22,
										bold: true
									})
								],
							})
						],
					}),
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: paramDivision,
										font: "Calibri",
										size: 22
									})
								],
							})
						],
					}),
				]
			}),
			new docx.TableRow({
				children: [
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: "Month of Report",
										font: "Calibri",
										size: 22,
										bold: true
									})
								],
							})
						],
					}),
					new docx.TableCell({
						width: {
							size: 4505,
							type: docx.WidthType.DXA,
						},
            children: [
							new docx.Paragraph({
								children: [
									new docx.TextRun({
										text: paramMonth,
										font: "Calibri",
										size: 22
									})
								],
							})
						],
					}),
				]
			}),
		]
	});
	
	activitiesArray.push(
		new docx.Paragraph({
			children: [
				new docx.TextRun({
					text: "200 Clinton Ave Ste 600 | Huntsville, AL 35801",
					font: "Calibri",
					size: 22
				})
			],
			alignment: docx.AlignmentType.CENTER,
		})
	);

	activitiesArray.push(
		new docx.Paragraph({
			children: [
				new docx.TextRun({
					text: "256.327.3410 Office | QuadrusCorp.com",
					font: "Calibri",
					size: 22
				})
			],
			alignment: docx.AlignmentType.CENTER,
			spacing: {
					after: 200,
			},
		})
	);

	activitiesArray.push(
		new docx.Paragraph({
			children: [
				new docx.TextRun({
					text: "Monthly Activity Report",
					font: "Calibri",
					size: 28,
					bold: true
				})
			],
			alignment: docx.AlignmentType.CENTER,
			spacing: {
				after: 200,
			},
		})
	);

	activitiesArray.push(
		table
	);

  let namesArray = paramNames.split(",");

	let activitiesList = activities.split("\n");                               // split up current report activities list by newline characters
  let nameCount = 0;

  for (let j = 0; j < activitiesList.length; j = j + 1) {                    // loop through list of lines in report

    if(activitiesList[j] == "Name") {
      activitiesArray.push(new docx.Paragraph({
                            underline: {type: "single", color: null},
                            children: [
                              new docx.TextRun({
                                text: namesArray[nameCount],
                                font: "Calibri",
                                size: 22,
                                bold: true
                              })
                            ]
                          })
                        );
      nameCount = nameCount + 1;
    }

    else if (activitiesList[j] == "‎ ‎ No Activities Reported.") {  // if current line was auto generated
      activitiesList[j] = "• " + activitiesList[j];                         // inject a bulletpoint
    }

    else {
      activitiesArray.push(                                                    // for each line in report, add a new 'paragraph'
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: activitiesList[j],                                         // add the lines individually to final output
              font: "Calibri",
              size: 22
            })
          ],
        })
      );
    }
  }
	
	const doc = new docx.Document({
		creator: "Quadrus Corporation",
		description: "Monthly Report",
		title: "El Documento",
		sections: [
			{
				properties: {},
				headers: {
					default: new docx.Header({
						children: [
							new docx.Paragraph({
								children: [
									image
								],
							})
						]
					})
				},
				children: activitiesArray
			}
		],
	});

	// Used to export the file into a .docx file
	docx.Packer.toBlob(doc).then((blob) => {
		let document = new File([blob], filename);
		FileSaver.saveAs(document);
	});

}