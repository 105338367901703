const docx = require("docx");
const { saveAs } = require("file-saver");
const { logoString } =  require('/src/assets/js/logo.js');
const { Packer } = require("docx");

//const { TextRun } = require("docx");
//const { file } = require("jszip");
//const JSZip = require("jszip");
//const FileSaver = require("file-saver");


//buildMonthlyPerson(this.activityList, this.projects, division, name, month, 'Preview');	
//buildMonthlyMulti(this.activityList, this.names, division, this.multiParameters, month, 'Preview');
module.exports = function(activities, division, name, month) {
		let activityList = [];	//Holds the charge lines, employees, and their associated activities

    let seperatedActivites = [];  // activity logs seperated by tasks
    let taskList = [];  // list of tasks to build files for

    //var zip = new JSZip();

    // break down activites string into its task components
    //    since task lines are denoted by # on either side, this can be done
    //    with basic string manipulation
    let i = 0;
    let count = 0;
    while(activities.length > 0 || i == -1) {                         // while there is still data to parse

      // first find the name of the next task block
      activities = activities.substring(1, activities.length);        // remove first character
      i = activities.indexOf("#");                                    // find end of task name
      taskList[count] = activities.substring(0, i);                   // store found task name into array 

      activities = activities.substring(i+1, activities.length);      // remove task name from data

      // then load activity data into array
      i = activities.indexOf("#");                                    // find index of next activity
      if (i != -1) {                                                  // if there is still more
        seperatedActivites[count] = activities.substring(0, i-1);     // load only the current activities list
        activities = activities.substring(i, activities.length);      // prep data for next cycle
      }
      else {                                                          // if no more data, dump remainder into array
        seperatedActivites[count] = activities;                       // dump remainder of data into array
        break;                                                        // exit loop
      }
      
      count++;                                                        // increment count after every item is added
    }

    let taskArrayTextRun = [];
    for(let x = 0; x < taskList.length; x = x + 1) {
      taskArrayTextRun[x] = new docx.Paragraph({
                              children: [
                                new docx.TextRun({
                                  text: taskList[x],  // charge lines
                                  font: "Calibri",
                                  size: 22,
                                  bold: true
                                })
                              ],
                              alignment: docx.AlignmentType.LEFT,
                            })
    }

      activityList = [];	                                              //Holds the charge lines, employees, and their associated activities

      //build logo image
      const image = new docx.ImageRun({                                 // load logo image into docx format
        data: logoString,
        transformation: {
            width: 600,
            height: 100,
        }
      });

      // build the table 
      const table = new docx.Table({                                     // construct the header table
        columnWidths: [4505, 4505],
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({                                   // add name label
                    children: [
                      new docx.TextRun({
                        text: "Employee Name",
                        font: "Calibri",
                        size: 22,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "  " + name,                             // add name of employees who worked current task
                        font: "Calibri",
                        size: 22
                      })
                    ],
                  })
                ],
              }),
            ]
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "Charge Line / PC Number (if applicable)",  // charge line header
                        font: "Calibri",
                        size: 22,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: taskArrayTextRun,
              }),
            ]
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "Division",                                     // division header
                        font: "Calibri",
                        size: 22,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "  " + division,                          // division of current report
                        font: "Calibri",
                        size: 22
                      })
                    ],
                    alignment: docx.AlignmentType.LEFT,
                  })
                ],
              }),
            ]
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "Month of Report",                              // month/year header
                        font: "Calibri",
                        size: 22,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "  " + month,                              // month/year of current report
                        font: "Calibri",
                        size: 22
                      })
                    ],
                    alignment: docx.AlignmentType.LEFT,
                  })
                ],
              }),
            ]
          }),
        ]
      });

      //push content to the file
      activityList.push(                                                // push info block part 1 into output array
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "200 Clinton Ave Ste 600 | Huntsville, AL 35801",
              font: "Calibri",
              size: 22
            })
          ],
          alignment: docx.AlignmentType.CENTER,
        })
      );
    
      activityList.push(                                                // push info block part 2 into output array
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "256.327.3410 Office | QuadrusCorp.com",
              font: "Calibri",
              size: 22
            })
          ],
          alignment: docx.AlignmentType.CENTER,
          spacing: {
              after: 200,
          },
        })
      );
    
      activityList.push(                                                  // push title line into output array
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "Monthly Activity Report",
              font: "Calibri",
              size: 28,
              bold: true
            })
          ],
          alignment: docx.AlignmentType.CENTER,
          spacing: {
            after: 200,
          },
        })
      );
    
      activityList.push(                                                  // push header table into output array
        table
      );

      for(let y = 0; y < taskArrayTextRun.length; y = y + 1) {
        seperatedActivites[y] = seperatedActivites[y].split("\n");        // split up current report activities list by newline characters
        activityList.push(                                                // for each line in report, add a new 'paragraph'
          taskArrayTextRun[y],
        );
        for (let j = 0; j < seperatedActivites[y].length; j = j + 1)      // loop through list of lines in report
        {
          if (seperatedActivites[y][j] == "No Activities Reported") {     // if current line was auto generated
            seperatedActivites[y][j] = "• " + seperatedActivites[y][j];   // inject a bulletpoint
          }
          activityList.push(                                              // for each line in report, add a new 'paragraph'
            new docx.Paragraph({
              children: [
                new docx.TextRun({
                  text: seperatedActivites[y][j],                         // add the lines individually to final output
                  font: "Calibri",
                  size: 22,
                })
              ],
            })
          );
      }

      }

      let doc = new docx.Document({                                            // construct document object
        creator: "Quadrus Corporation",                                        // creator of file
        description: "Monthly Report",                                         // what the file is
        title: "El Documento",                                                 // internal title of file
        sections: [
          {
            properties: {},
            headers: {
              default: new docx.Header({                                        // in the header block
                children: [
                  new docx.Paragraph({
                    children: [
                      image                                                     // add the logo image
                    ],
                  })
                ]
              })
            },
            children: activityList                                              // then add the content block
          }
        ],
      });

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, name + '_' + month + "_Monthly-Report.docx");
      })

    /*
    doc = docx.Packer.toBlob(doc);                                            // pack the doc into a blob
    zip.file(fileName, doc);                                                  // and add to zip file
    zip.generateAsync({type:"blob"}).then(function (bloob) {                    // when all files are packed
      FileSaver.saveAs(bloob, month + " - " + name + ".zip");                   // save zip file to drive
    })
    */
}