<template>
<div class='login'>
	<mdb-container>
		<mdb-row class="justify-content-center">
		<mdb-col col="10">
		<img src="@/assets/Quadrus Logo color hz.png" class="img-fluid logo" alt="Quadrus Corporation logo">
		</mdb-col>
		</mdb-row>
	</mdb-container>
		
	<mdb-row class="justify-content-center">
		<mdb-col col="6">
		<mdb-card>
			<mdb-card-body>
				<!-- Material form login -->
				<form @submit.prevent="checkEmail" novalidate>
					<p class="h4 text-center mb-4">Forgotten Password</p>
					<div class="grey-text" style="text-align: justify">
						<mdb-input v-model="email" label="Enter Email Address" icon="email" />
					</div>
					<div class="text-center">
						<router-link to='/Login' class='QuadrusButton btn'>Go Back</router-link>
						<mdb-btn type='submit' class='QuadrusButton'>Reset Password</mdb-btn>
					</div>
				</form>
				<!-- Material form login -->
			</mdb-card-body>
		</mdb-card>
		</mdb-col>
	</mdb-row>
</div>	
</template>

<script>
import { mdbInput, mdbBtn, mdbCard, mdbCardBody, mdbCol, mdbRow, mdbContainer} from 'mdbvue';
//import axios from 'axios';

export default {
	name: "ForgotPassword",
	components: {
		mdbInput,
		mdbBtn,
		mdbCard,
		mdbCardBody,
		mdbCol,
		mdbRow,
		mdbContainer
	},
	data() {
		return {
			email: ''
		}
	},
	methods: {
		checkEmail: function()
		{
			console.log(this.email);
			this.sendEmail();
		},
		sendEmail: function()
		{
			console.log('This Will Work Someday');
		}
	}
}
</script>

<style scoped>
.logo {
	padding-top: 20px;
	padding-bottom: 20px;
}
.login {
	background-color: #1b365d;
}

</style>