<template>
  <div class="page-start">
    <mdb-row class="justify-content-center">
      <mdb-col col="8">
    <mdb-jumbotron class="mt-5 blue-grey lighten-5">
      <mdb-avatar class="mx-auto mb-4" style="width: 15em;">
        <img id="avatar" :src="$store.getters.getAvatar" class="rounded-circle img-fluid"/>
      </mdb-avatar>
      <mdb-list-group flush>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Name</mdb-col>
              <mdb-col>{{ user.firstName + ' ' + user.lastName }}</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>DOB</mdb-col>
              <mdb-col>{{ this.user.dob }}</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Username</mdb-col>
              <mdb-col>{{ user.username }}</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Email</mdb-col>
              <mdb-col>{{ user.email }} <!--<br> {{ user.personalEmail }} (Personal)--></mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Title</mdb-col>
              <mdb-col>{{ user.title }} <!--<br> {{ user.personalEmail }} (Personal)--></mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Division</mdb-col>
              <mdb-col>{{ user.division }} <!--<br> {{ user.personalEmail }} (Personal)--></mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Work Phone</mdb-col>
              <mdb-col>{{ user.workPhone }} <!--<br> {{ user.personalEmail }} (Personal)--></mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Work Cell Phone</mdb-col>
              <mdb-col>{{ user.workCellPhone }} <!--<br> {{ user.personalEmail }} (Personal)--></mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <!--
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Phone</mdb-col>
              <mdb-col>
								<p v-if="user.homePhone !== 'NA'" class="m-0">{{ user.homePhone }} (Home Phone)</p>
								<p v-if="user.personalPhone !== 'NA'" class="m-0">{{ user.personalPhone }} (Personal Cell)</p>
								<p v-if="user.workCellPhone !== 'NA'" class="m-0">{{ user.workCellPhone }} (Work Cell)</p>
								<p v-if="user.workPhone !== 'NA'" class="m-0">{{ user.workPhone }} (Office Phone)</p>
							</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Country</mdb-col>
              <mdb-col>{{ user.country }}</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Address</mdb-col>
              <mdb-col>{{ user.street + ', ' + user.city + ', ' + user.state + ', ' + user.zip}}</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Title</mdb-col>
              <mdb-col>{{ user.title }}</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Division</mdb-col>
              <mdb-col>{{ user.division }}</mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item v-if="user.emerNameOne != '' && user.emerNameOne != 'NA'" class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Emergency Contacts</mdb-col>
              <mdb-col>
                {{ user.emerNameOne + ': ' + user.emerPhoneOne }}
                <div v-if="user.emerNameTwo != '' && user.emerNameOne != 'NA'">
                  {{ user.emerNameTwo + ': ' + user.emerPhoneTwo }}
                </div>
                <div v-if="user.emerNameThree != '' && user.emerNameOne != 'NA'">
                  {{ user.emerNameThree + ': ' + user.emerPhoneThree }}
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        <mdb-list-group-item v-else class="blue-grey lighten-5">
          <mdb-container class="text-left">
            <mdb-row>
              <mdb-col>Emergency Contacts</mdb-col>
              <mdb-col>
                None Given
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </mdb-list-group-item>
        -->
      </mdb-list-group>
      <!--
      <br>
      <mdb-btn color="primary" disabled>Request Information Update (Coming Soon)</mdb-btn>
      <br>
      -->
      <router-link to="/ResetPassword"><mdb-btn>Reset Password</mdb-btn></router-link>
    </mdb-jumbotron>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import {mdbCol, mdbRow, mdbListGroup, mdbListGroupItem, mdbContainer, mdbJumbotron, mdbBtn, mdbAvatar} from 'mdbvue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
  name: 'My-Account',
  components: {
    mdbCol,
    mdbRow,
    mdbListGroup,
    mdbListGroupItem,
    mdbContainer,
    mdbJumbotron,
    mdbBtn,
    mdbAvatar
  },
  data() {
    return {
      user: {
        id: '',
				username: '',
				email: '',
        /*
        personalEmail: '',
        */
				firstName: '',
				lastName: '',
				workPhone: '',
				workCellPhone: '',
        /*
        personalPhone: '',
				homePhone: '',
				country: '',
				street: '',
				city: '',
				state: '',
				zip: '',
        */
				title: '',
				division: '',
        profilePic: '',
        /*
				emerNameOne: '',
				emerNameTwo: '',
				emerNameThree: '',
				emerPhoneOne: '',
				emerPhoneTwo: '',
				emerPhoneThree: '',
        */
				dob: ''
      }
    }
  },
  methods: {

    //===========================================================================================
		//	Method Name	:	getUserFields
		//	
		//  Parameters
		//  Name										Type						Direction		Description
		//  ----------------------	--------------	---------		-------------------------------------
		//  Return Value            void            n/a         n/a
		//
		//	Description
		//	-----------------------------------------------------------------------------------------
		//	Fetches the user's profile information from the database and populates the data() object
    //  with the values.
		//===========================================================================================
    getUserFields: async function()
		{
			try {
				let token = this.$store.getters.getToken;

				let response = await DatabaseLib.getRequest('strapiDatabase','users/me',token);
        this.user.id = response.data.id;
        this.user.firstName = response.data.firstName;
        this.user.lastName = response.data.lastName;
        this.user.username = response.data.username;
        this.user.email = response.data.email;
        //this.user.personalEmail = response.data.personalEmail;
        this.user.dob = response.data.dob;
        this.user.workPhone = response.data.workPhone;
				this.user.workCellPhone = response.data.workCellPhone;
        /*
        this.user.personalPhone = response.data.personalPhone;
				this.user.homePhone = response.data.homePhone;
        this.user.country = response.data.country;
        this.user.street = response.data.street;
        this.user.city = response.data.city;
        this.user.state = response.data.state;
        this.user.zip = response.data.zip;
        */
        this.user.title = response.data.title;
        this.user.division = response.data.division;
        this.profilePic = response.data.profilePic.url;
        /*
        this.user.emerNameOne = response.data.emerNameOne;
        this.user.emerNameTwo = response.data.emerNameTwo;
        this.user.emerNameThree = response.data.emerNameThree;
        this.user.emerPhoneOne = response.data.emerPhoneOne;
        this.user.emerPhoneTwo = response.data.emerPhoneTwo;
        this.user.emerPhoneThree = response.data.emerPhoneThree;
        */

			} catch (error) {
				window.scroll({top: 0, behavior: 'smooth'});
				this.p1 = true;	
        return;	
			}
			return 0;
		},
  },

  mounted() {
    this.getUserFields();
  }
};
</script>
