const docx = require("docx");
const FileSaver = require("file-saver");
const { logoString } =  require('/src/assets/js/logo.js');
const JSZip = require("jszip");
//const { file } = require("jszip");



//buildMonthlyMulti(this.activityList, this.names, division, this.multiParameters, month, 'Preview');
module.exports = function(paramDivision, paramMonth, projNameInfo) {
		let activityList = [];	//Holds the charge lines, employees, and their associated activities

    var zip = new JSZip();

    for (let fileNum = 0; fileNum < projNameInfo.length; fileNum++) {     // loop through number of tasks, creating a new file for each
      let fileName = projNameInfo[fileNum].project + " - " + projNameInfo[fileNum].task + ".docx";  // create filename
      activityList = [];	                                              //Holds the charge lines, employees, and their associated activities
      let names = "";

      for (let i = 0; i < projNameInfo[fileNum].names.length; i = i + 1) 
      {
        names = names + projNameInfo[fileNum].names[i] + ", ";
      }
      names = names.substring(0, names.length-2);
      //build logo image
      const image = new docx.ImageRun({                                 // load logo image into docx format
        data: logoString,
        transformation: {
            width: 600,
            height: 100,
        }
      });

      // build the table 
      const table = new docx.Table({                                     // construct the header table
        columnWidths: [4505, 4505],
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({                                   // add names label
                    children: [
                      new docx.TextRun({
                        text: "Employee Names",
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "  " + names,                             // add names of employees who worked current task
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
            ]
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "Charge Line / PC Number (if applicable)",  // charge line header
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "  " + projNameInfo[fileNum].project + " - " + projNameInfo[fileNum].task,                     // charge line of current file
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                    alignment: docx.AlignmentType.LEFT,
                  })
                ],
              }),
            ]
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "Division",                                     // division header
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "  " + paramDivision,                          // division of current report
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                    alignment: docx.AlignmentType.LEFT,
                  })
                ],
              }),
            ]
          }),
          new docx.TableRow({
            children: [
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "Month of Report",                              // month/year header
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                  })
                ],
              }),
              new docx.TableCell({
                width: {
                  size: 4505,
                  type: docx.WidthType.DXA,
                },
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: "  " + paramMonth,                              // month/year of current report
                        font: "Calibri",
                        size: 24,
                        bold: true
                      })
                    ],
                    alignment: docx.AlignmentType.LEFT,
                  })
                ],
              }),
            ]
          }),
        ]
      });

      //push content to the file
      activityList.push(                                                // push info block part 1 into output array
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "200 Clinton Ave Ste 600 | Huntsville, AL 35801",
              font: "Calibri",
              size: 22
            })
          ],
          alignment: docx.AlignmentType.CENTER,
        })
      );
    
      activityList.push(                                                // push info block part 2 into output array
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "256.327.3410 Office | QuadrusCorp.com",
              font: "Calibri",
              size: 22
            })
          ],
          alignment: docx.AlignmentType.CENTER,
          spacing: {
              after: 200,
          },
        })
      );
    
      activityList.push(                                                  // push title line into output array
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "Monthly Activity Report",
              font: "Calibri",
              size: 28,
              bold: true
            })
          ],
          alignment: docx.AlignmentType.CENTER,
          spacing: {
              after: 200,
          }
        })
      );
    
      activityList.push(                                                  // push header table into output array
        table
      );

      activityList.push( // add an extra newline
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "",
              font: "Calibri",
              size: 22,
              bold: false
            })
          ],
        })
      );
      activityList.push( // add an extra newline
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: "",
              font: "Calibri",
              size: 22,
              bold: false
            })
          ],
        })
      );

      for (let j = 0; j < projNameInfo[fileNum].activities.length; j = j + 1)      // loop through list of lines in report
      {
        let noActivity = false;
        let temp = projNameInfo[fileNum].activities[j].activity.split("\n");
        if (temp[0] == "" )
        {
          temp[0] = " ‎ • No Activity"
          noActivity = true;
        }
        activityList.push(                                                    // for each line in report, add a new 'paragraph'
          new docx.Paragraph({
            children: [
              new docx.TextRun({
                text: projNameInfo[fileNum].activities[j].person,                         // add the lines individually to final output
                font: "Calibri",
                size: 22,
                bold: true,
                underline: {
                  type: "single",
                  color: "FF0000"
                }
              })
            ],
          })
        );
        activityList.push( // add an extra newline
          new docx.Paragraph({
            children: [
              new docx.TextRun({
                text: "",
                font: "Calibri",
                size: 22,
                bold: false
              })
            ],
          })
        );
        for (let k = 0; k < temp.length; k = k + 1)
        {
          activityList.push(                    // for each line in report, add a new 'paragraph'
            new docx.Paragraph({
              children: [
                new docx.TextRun({
                  text: temp[k],                // add the lines individually to final output
                  font: "Calibri",
                  size: 24,
                  bold: false
                })
              ],
            })
          );
        }
        if (noActivity)
        {
          activityList.push( // add an extra newline
              new docx.Paragraph({
                children: [
                  new docx.TextRun({
                    text: "",
                    font: "Calibri",
                    size: 22,
                    bold: false
                  })
                ],
              })
            );
        }
      }


      let doc = new docx.Document({                                            // construct document object
        creator: "Quadrus Corporation",                                        // creator of file
        description: "Monthly Report",                                         // what the file is
        title: "El Documento",                                                 // internal title of file
        sections: [
          {
            properties: {},
            headers: {
              default: new docx.Header({                                        // in the header block
                children: [
                  new docx.Paragraph({
                    children: [
                      image                                                     // add the logo image
                    ],
                  })
                ]
              })
            },
            children: activityList                                              // then add the content block
          }
        ],
      });
      doc = docx.Packer.toBlob(doc);                                            // pack the doc into a blob
      zip.file(fileName, doc);                                                  // and add to zip file
    }   
    zip.generateAsync({type:"blob"}).then(function (bloob) {                    // when all files are packed
      FileSaver.saveAs(bloob, paramMonth + " Monthly Reports.zip");             // save zip file to drive
    })
}