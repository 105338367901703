<template>
<div class='page-start'>
	<mdb-container class="pb-3" fluid>

        <mdb-alert id="feedback-success" class="mt-3" color="success" v-if="p1" @closeAlert="p1=false" dismiss>
            Success! Submitted Support Ticket!
        </mdb-alert>
        <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p2" @closeAlert="p2=false" dismiss>
            There was an error submitting Support Ticket. Please try again! Error Code P2
        </mdb-alert>

        <!-- User Feedback and Error Code Displays -->

        <!-- END User Feedback and Error Code Displays -->

        <!-- Create Ticket Table -->
        <mdb-card class="mb-3 d-flex">
        <h3 class="card-header stylish-color white-text text-center font-weight-bold text-uppercase py-4">Submit Support Ticket</h3>
        <mdb-card-body>
            <form class="was-validated">
                <mdb-row>
                    <mdb-col>
                        <mdb-input label="Description" v-model="description" required invalid-feedback="Please Input Ticket Description" valid-feedback="Looks Good!" maxlength="500" />
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col>
                        <mdb-input v-model="dateOpened" label='Date Opened' id="dateOpened" title='Date Opened' required disabled />
                    </mdb-col>
                </mdb-row>
                    <mdb-row>
                    <mdb-col>
                        <mdb-input label="Name of Submitter" v-model="nameSubmit" required disabled />
                    </mdb-col>
                    <mdb-col>
                        <mdb-input label="Email of Submitter" v-model="emailSubmit" required disabled/>
                    </mdb-col>
                </mdb-row>
                <!--<mdb-btn id="submitAddTicket" class='QuadrusButton' size="lg" v-on:click="submitTicket" :disabled="description == '' ">Submit</mdb-btn>-->
                <mdb-btn size="block" class="mt-4" color="primary" type="submit" v-on:click="submitTicket" v-if="!submitProcessing">Submit</mdb-btn>
                <mdb-btn size="block" class="mt-4" color="primary" v-if="submitProcessing">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Processing...
                </mdb-btn>
                <br>
            </form>
        </mdb-card-body>
        </mdb-card>
    </mdb-container>
</div>
</template>

<script>
import { mdbInput, mdbCard, mdbCardBody, mdbContainer, mdbBtn, mdbRow, mdbCol, mdbAlert } from 'mdbvue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
	name: 'Submit-IT-Tickets',
	components: {
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbContainer,
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbAlert
  },
    
  data() {
    return {

      p1: false,
      p2: false,

      submitProcessing: false,

      description: '',
      dateOpened: '',
      nameSubmit: '',
      emailSubmit: '',

    }
  },

  methods: {

		sanitizeInput(content) 
		{
      let input = this.$sanitize(content);
      input = input.replace(/&amp;/g, "&");
			return input;
    },

    submitTicket: async function() {
      this.submitProcessing = true;
      let token = this.$store.getters.getToken;

      this.description = this.sanitizeInput(this.description);
      this.nameSubmit = this.sanitizeInput(this.nameSubmit);
      this.emailSubmit = this.sanitizeInput(this.emailSubmit);

      let data = {
        data:{
          description: this.description,
          openDate: new Date(this.dateOpened),
          nameSubmit: this.nameSubmit,
          emailSubmit: this.emailSubmit,
          queued: false,
          resolved: false,
        }
      }
      
      // Resets description form
      this.description = '';

      try {
        await DatabaseLib.postRequest('strapiDatabase','it-tickets',token,data);

        window.scroll({top: 0, behavior: 'smooth'});
        this.p1 = true;

      } catch(error) {
        window.scroll({top: 0, behavior: 'smooth'});
        this.p2 = true;
        this.submitProcessing = false;
        return;
      }
      this.submitProcessing = false;
    },

    setFields: async function() {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1
      let date = dateObj.getUTCDate()
      this.dateOpened = dateObj.getFullYear() + "-" + ("0" + month).slice(-2) + "-" + ("0" + date).slice(-2);

      let token = this.$store.getters.getToken;
      let query = "filters[id][$eq][0]="+this.$store.getters.getId;
      let response = await DatabaseLib.getRequest('strapiDatabase','users',token, query);
      this.nameSubmit = response.data[0].firstName + " " + response.data[0].lastName;

      this.emailSubmit = response.data[0].email;
    }

  },

  mounted() {
    this.setFields();
  }


}
</script>