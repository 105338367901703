<template>
    <div class="page-start">
        <mdb-row class="justify-content-center">
        <mdb-col col="10">
            <mdb-alert id="feedback-failure" class="mt-3" color="danger" v-if="p1" @closeAlert="p1=false" dismiss>
                There was an error retrieving saved wars! Please try again later.
            </mdb-alert>
            
            <QDataTable  id='contractTable' :rowData="this.data.rows" :filterWords="this.data.filterWords" :headers="this.data.columns" 
                :btnName="'EDIT'" :hasBtn="true" @use-method="editRouter">
            </QDataTable>
        </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import { mdbCol, mdbRow, mdbAlert } from 'mdbvue';
import QDataTable from '@/components/QDataTable.vue';
import * as DatabaseLib from '@/assets/js/DatabaseLib.js';

export default {
    name: 'EditSavedWar',
    components: {
        mdbCol,
        mdbRow,
        mdbAlert,
        QDataTable,
    },
    data() {
        return {
            data: {
                columns: [
                    {
                        colTitle: 'Date Range',
                        sortBy: 'dateSaved'
                    },
                    {
                        colTitle: 'Project / Task',
                        sortBy: 'projectTask'
                    },
                    {
                        colTitle: 'Line Item',
                        sortBy: 'lineItem'
                    }
                ],
                rows: [],
                filterWords: [
                    'dateSaved',
                    'projectTask',
                    'lineItem'
                ]
            },

            p1: false
        }
    },
    methods: {
        editSavedWar: function(idParam, projectTaskParam, lineItemParam, activityParam) {
            this.$router.push(
                { name: 'New-War', params: { id: idParam, projectTask: projectTaskParam, lineItem: lineItemParam, activity: activityParam }}
            );
        },

        editRouter: function(data) {
            let propData = {
                id: data.id,
                projectTask: data.projectTask,
                lineItem: data.lineItem,
                activity: data.activity,
                startDate: data.startDate,
                endDate: data.endDate,
                editing: true
            }
            this.$router.push({
                name: 'New-War',
                params: {
                    editWAR: propData
                }
            })
        },

        fillTable: async function() {
            try {
                let token = this.$store.getters.getToken;
                let query = 'filters[submitted][$eq][0]='+false+'&filters[username][$eq][1]='+this.$store.getters.getUser;

                let response = await DatabaseLib.getRequest('strapiDatabase','war-reports',token,query);
                response.data.data.forEach(tableRow => {
                    let startDate = new Date(tableRow.attributes.startDate)
                    let endDate =  new Date(tableRow.attributes.endDate)
                    
                    this.data.rows.push({
                        id: tableRow.id,
                        projectTask: tableRow.attributes.projectTask,
                        lineItem: tableRow.attributes.lineItem,
                        activity: JSON.stringify(tableRow.attributes.activity),
                        startDate: tableRow.attributes.startDate,
                        endDate: tableRow.attributes.endDate,
                        dateSaved: (startDate.getUTCMonth() + 1) + "/" + startDate.getUTCDate() + "/" + startDate.getFullYear().toString().substr(-2) + " - " + (endDate.getUTCMonth() + 1) + "/" + endDate.getUTCDate() + "/" + endDate.getFullYear().toString().substr(-2)
                    })
                    
                });
			} catch(error) {
                window.scroll({top: 0, behavior: 'smooth'});
				this.p1 = true;
                return;
            }
            return 0;
        }
    },

    mounted() {
        this.fillTable();
	}
}
</script>